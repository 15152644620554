import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import CallToAction from "../../../components/CallToAction";
import { FaCalendarCheck, FaLanguage, FaMoneyBillWave } from "react-icons/fa";
import MonthlyImage from "../../../../src/assets/images/vectors/monthly.png";
import OnsiteImage1 from "../../../../src/assets/images/adobe/handshake.webp";
import OnsiteImage2 from "../../../../src/assets/images/adobe/request1.png";
import OnsiteImage3 from "../../../../src/assets/images/adobe/people-img.webp";
import Helmet from "react-helmet";

const MonthlyRecurrent: React.FC = () => {
  const monthlyBenefits = [
    {
      icon: FaMoneyBillWave,
      title: "Cost-Effective",
      text: "Predictable monthly billing with no surprise fees.",
    },
    {
      icon: FaCalendarCheck,
      title: "Consistent Access",
      text: "Dedicated interpreters available at any time, ensuring reliability for ongoing needs.",
    },
    {
      icon: FaLanguage,
      title: "Wide Language Coverage",
      text: "Over 200 languages and dialects to meet diverse communication demands.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Monthly Recurrent Phone Interpretation US</title>
        <meta
          name="description"
          content="Trusted Interpreters offers consistent monthly phone interpretation services."
        />
      </Helmet>
      <PageHeader
        title="Monthly Recurrent Phone Interpretation Plans Nationwide"
        description="Trusted Interpreters provides flexible monthly recurrent phone interpretation plans designed for businesses and organizations with ongoing language support needs. Our plans ensure seamless communication with expert interpreters available 24/7."
        imageUrl={MonthlyImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Simplified Language Solutions for Your Business"
          content="Our monthly plans are tailored to support:"
          contentList={[
            {
              text: "Healthcare providers managing multilingual patient interactions.",
              links: [
                { linkText: "Healthcare", link: "/expertise/medical-interpretation" },
              ],
            },
            {
              text: "Legal professionals handling diverse client consultations.",
              links: [
                {
                  linkText: "Legal",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Corporate businesses conducting international operations and meetings.",
              links: [
                {
                  linkText: "Corporate businesses",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Educational institutions requiring consistent language support for students and families.",
              links: [
                {
                  linkText: "Educational",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
          ]}
          additionalContent="With a subscription-based approach, you’ll have guaranteed access to interpreters when you need them most."
          imageUrl={OnsiteImage1}
          direction="right"
        />

        {/* Workflow */}
<ProcessWorkflow process="virtual" />


        <ContentSection
          title="Tailored for Your Unique Requirements"
          content="Our monthly recurrent service can be customized to:"
          contentList={[
            {
              text: "Fit the specific languages your organization requires.",
            },
            {
              text: "Align with your industry’s standards and terminology.",
            },
            {
              text: "Offer unlimited or usage-based options depending on your needs.",
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="Consecutive" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>
        
        <ContentSection
          title="Professionalism You Can Rely On"
          content="Trusted Interpreters upholds the highest standards of professionalism and confidentiality. Our interpreters are highly skilled, experienced, and adhere to strict codes of ethics to ensure accuracy and privacy."
          imageUrl={OnsiteImage3}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Our Monthly Plans?">
          <KeyBenefits benefits={monthlyBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Start Your Plan Today"
          description="Enhance your organization’s communication with our reliable monthly recurrent phone interpretation service. Contact Trusted Interpreters today to explore subscription options tailored to your needs."
          buttonText="Contact Us"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default MonthlyRecurrent;