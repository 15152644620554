import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import OnsiteImage2 from "../../../src/assets/images/adobe/ESL.webp";
import { FaCertificate } from "react-icons/fa6";
import { FaGlobeAmericas, FaMapMarkedAlt } from "react-icons/fa";
// images
import EnglishImage from "../../../src/assets/images/vectors/english.png";
import PeopleImage from "../../../src/assets/images/adobe/Chicago1.webp";
import PeopleImage1 from "../../../src/assets/images/adobe/fort worth2.webp";
import Helmet from "react-helmet";


const EnglishExpertise: React.FC = () => {
  const englishBenefits = [
    {
      icon: FaCertificate,
      title: "Certified Professionals",
      text: "Our interpreters are highly trained and certified, offering reliable and accurate services.",
    },
    {
      icon: FaGlobeAmericas,
      title: "Cultural Understanding",
      text: "We pair you with interpreters who understand the cultural context of the target language, ensuring effective communication.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Availability",
      text: "With interpreters located across the U.S., we are ready to support your language needs anywhere.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>English Interpretation Services US</title>
        <meta
          name="description"
          content="Trusted Interpreters provides professional English interpretation services nationwide."
        />
      </Helmet>
      <PageHeader
        title="Expert English Interpretation for Your Business or Event"
        description="Trusted Interpreters provides professional English interpretation services to support clear and effective communication for diverse audiences. Whether you're hosting a multilingual event, engaging in international business, or serving communities with varying language needs, our expert interpreters ensure your message is understood."
        imageUrl={EnglishImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why English Interpretation Matters"
          content="English interpretation plays a vital role in bridging communication gaps for non-native speakers in settings such as conferences, legal proceedings, and medical consultations. Our interpreters are skilled in delivering accurate and culturally sensitive interpretations, ensuring inclusivity and mutual understanding."
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Scenarios Where We Make a Difference"
          content="Our English interpretation services are designed to meet a wide variety of needs, including:"
          contentList={[
            {
              text: "Supporting international participants at conferences and business meetings with seamless real-time interpretation.",
              links: [
                {
                  linkText: "business meetings",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Helping community service organizations reach diverse populations effectively.",
              links: [
                {
                  linkText: "organizations",
                  link: "/expertise/government-interpretation",
                },
              ],
            },
            {
              text: "Enhancing educational experiences for non-English-speaking students and families during academic discussions and events.",
              links: [
                {
                  linkText: "academic discussions and events",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
            {
              text: "Assisting non-native English speakers in medical appointments, ensuring they understand diagnoses and treatment plans.",
              links: [
                {
                  linkText: "medical appointments",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Facilitating clear communication in legal proceedings, ensuring all parties understand their rights and responsibilities.",
              links: [
                {
                  linkText: "legal proceedings",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks currentLanguage="English"/>

        <ContentSection
          title="Key Features of Our English Interpretation Services:"
          contentList={[
            {
              text: "Accurate and Precise: Faithfully conveying messages while preserving tone and intent.",
            },
            {
              text: "Culturally Aware: Interpreters with deep cultural understanding for effective communication.",
            },
            {
              text: "Flexible Formats: Available for on-site, over-the-phone, and video remote interpretation.",
            },
          ]}
          additionalContent="Trusted Interpreters provides English interpretation services across the U.S. and beyond. With a vast network of certified professionals, we deliver reliable, high-quality interpretation wherever and whenever you need it."
          imageUrl={PeopleImage1}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={englishBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Let Trusted Interpreters Bridge the Gap"
          description="Ensure your message is heard and understood with Trusted Interpreters' English interpretation services. Contact us today to discuss your requirements and schedule a professional interpreter for your next project."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default EnglishExpertise;