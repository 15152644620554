import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import OnsiteImage2 from "../../../src/assets/images/adobe/adobe11.webp";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
// images
import OklahomaImage from "../../../src/assets/images/adobe/Oklahoma.webp";
import PeopleImage from "../../../src/assets/images/adobe/adobe10.webp";
import PeopleImage1 from "../../../src/assets/images/adobe/Chicago1.webp";

const OklahomaCity: React.FC = () => {
  const oklahomaBenefits = [
    {
      icon: FaAward,
      title: "Experienced Professionals",
      text: "We ensure accurate communication while respecting cultural nuances.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Understanding",
      text: "Our interpreters are certified, trained, and fluent in over 200 languages.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in Oklahoma City, delivering consistent support wherever needed.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <PageHeader
        title="Expert Interpretation Services in Oklahoma City"
        description="Clear and accurate communication is essential in Oklahoma City’s thriving industries and diverse communities. Trusted Interpreters provides professional interpretation services tailored to meet the unique needs of businesses, healthcare providers, legal professionals, and educational institutions across the region."
        imageUrl={OklahomaImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Oklahoma City Relies on Trusted Interpreters"
          contentList={[
            {
              text: "Certified Expertise: Our interpreters are fluent in over 200 languages and have specialized knowledge across various industries.",
            },
            {
              text: "Flexible Solutions: Whether you need on-site, over-the-phone, or virtual services, we customize our offerings to suit your requirements.",
              links: [
                {
                  linkText: "on-site",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "over-the-phone",
                  link: "/interpretation-types/phone-interpretation",
                },
                {
                  linkText: "virtual services",
                  link: "/interpretation-types/vri",
                },
              ],
            },
            {
              text: "Local Understanding: We recognize the unique cultural dynamics of Oklahoma City, ensuring accurate and culturally sensitive communication.",
            },
          ]}
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />

        
        <ContentSection
          title="Comprehensive Interpretation Services"
          contentList={[
            {
              text: "On-Site Precision for Critical Needs: For in-person meetings, court hearings, medical appointments, or conferences, our interpreters provide accurate, real-time communication tailored to your audience.",
              links: [
                {
                  linkText: "On-Site Precision for Critical Needs",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "conferences",
                  link: "/interpretation-types/on-site/simultaneous",
                },
                {
                  linkText: "court hearings",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "medical appointments",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Phone Interpretation On-Demand: Access instant language support with our 24/7 phone interpretation services, perfect for urgent or remote communication needs.",
              links: [
                {
                  linkText: "Phone Interpretation On-Demand",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Virtual Solutions for Modern Communication: Our video remote interpretation (VRI) services ensure seamless multilingual interactions for virtual meetings, webinars, and hybrid events.",
              links: [
                {
                  linkText: "Virtual Solutions for Modern Communication",
                  link: "/interpretation-types/vri",
                },
                {
                  linkText: "video remote interpretation (VRI)",
                  link: "/interpretation-types/vri",
                },
                {
                  linkText: "virtual meetings",
                  link: "/interpretation-types/vri/skype-interpretation-services",
                },
              ],
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks/>

        <ContentSection
          title="Industries We Support in Oklahoma City"
          contentList={[
            {
              text: "Healthcare: Helping patients and providers communicate effectively in hospitals, clinics, and medical offices.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal Services: Providing court-certified interpreters for depositions, trials, and legal consultations.",
              links: [
                {
                  linkText: "Legal Services",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Business: Supporting corporate negotiations, training sessions, and international collaborations.",
              links: [
                {
                  linkText: "Business",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Education: Bridging language gaps in schools, universities, and training programs.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
          ]}
          additionalContent="From Downtown Oklahoma City to neighborhoods like Bricktown, Edmond, and Norman, Trusted Interpreters is your partner for professional language solutions across the metro area and beyond."
          imageUrl={PeopleImage1}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={oklahomaBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Us Today"
          description="Overcome language barriers with confidence. Contact Trusted Interpreters today to learn more about our expert interpretation services in Oklahoma City and how we can support your success."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default OklahomaCity;
