import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import OnsiteImage2 from "../../../src/assets/images/adobe/onsite-img-2.webp";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import Helmet from "react-helmet";
// images
import PortugueseImage from "../../../src/assets/images/vectors/portuguese.png";
import PeopleImage from "../../../src/assets/images/adobe/adobe2.webp";
import PeopleImage1 from "../../../src/assets/images/adobe/adobe16.webp";
import PeopleImage2 from "../../../src/assets/images/adobe/adobe11.webp";



const PortugueseExpertise: React.FC = () => {
  const portugueseBenefits = [
    {
      icon: FaAward,
      title: "Certified Professionals",
      text: "Our interpreters are highly trained and certified, ensuring reliable and precise interpretation.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Awareness",
      text: "We prioritize cultural nuances to ensure your message resonates effectively.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers Italian interpretation services across the U.S., delivering consistent support wherever needed.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Professional Portuguese Interpretation US</title>
        <meta
          name="description"
          content="Get seamless Portuguese interpretation services from Trusted Interpreters."
        />
      </Helmet>
      <PageHeader
        title="Professional Portuguese Interpretation Services Nationwide"
        description="At Trusted Interpreters, we provide expert Portuguese interpretation services designed to meet the unique needs of businesses, legal proceedings, healthcare, and more. Whether you require on-site, over-the-phone, or video remote interpretation, our professional interpreters ensure effective communication in any setting."
        imageUrl={PortugueseImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Choose Portuguese Interpretation?"
          content="Portuguese is one of the world’s most widely spoken languages, with over 250 million speakers globally. As the official language of countries like Brazil and Portugal, it plays a critical role in international business, diplomacy, and cultural exchange. Professional interpretation ensures that your message is accurately conveyed to Portuguese-speaking audiences while respecting cultural nuances."
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Applications of Our Services"
          content="We offer Portuguese interpretation services for:"
          contentList={[
            {
              text: "Business Meetings and Negotiations: Facilitating smooth communication between international partners.",
              links: [
                {
                  linkText: "Business Meetings and Negotiations",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Conferences and Events: Providing real-time interpretation for multilingual audiences.",
              links: [
                {
                  linkText: "Conferences and Events",
                  link: "/interpretation-types/on-site/simultaneous",
                },
              ],
            },
            {
              text: "Healthcare Appointments: Ensuring patients and providers communicate effectively.",
              links: [
                {
                  linkText: "Healthcare Appointments",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal Proceedings: Including depositions, court hearings, and immigration cases.",
              links: [
                {
                  linkText: "Legal Proceedings",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
          ]}
          imageUrl={PeopleImage1}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks currentLanguage="Italian"/>

        <ContentSection
          title="Expertise You Can Rely On"
          content="Our Portuguese interpreters are:"
          contentList={[
            {
              text: "Fluent in Brazilian and European Portuguese: Tailored to your audience’s dialect and preferences.",
            },
            {
              text: "Industry Specialists: With expertise in legal, medical, educational, and technical fields.",
              links: [
                {
                  linkText: "legal",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "medical",
                  link: "/expertise/medical-interpretation",
                },
                {
                  linkText: "educational",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
            {
              text: "Certified Professionals: Trained to deliver accurate, culturally sensitive interpretations.",
            },
          ]}
          additionalContent="With a vast network of interpreters across the U.S., we’re equipped to provide Portuguese interpretation services wherever and whenever you need them."
          imageUrl={PeopleImage2}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={portugueseBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Trusted Interpreters Today"
          description="Break down language barriers and ensure your message is heard. Contact us today to learn more about our Portuguese interpretation services and discover how we can support your communication needs."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default PortugueseExpertise;