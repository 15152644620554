import React from "react";
import Footer from "../components/Footer";
import Helmet from "react-helmet";

const PrivacyPolicy: React.FC = () => {
  const sections = [
    {
      title: "Effective Date",
      description: `Effective date: January 16, 2025`,
    },
    {
      title: "Introduction",
      description: `Trusted Interpreters (“we”, “us”, or “our”) operates the https://www.trustedinterpreters.com website (the “Service”). This Privacy Policy explains our practices regarding the collection, use, and disclosure of personal data when you use our Service and outlines your rights related to that data. By using the Service, you consent to the collection and use of information as described in this policy.`,
    },
    {
      title: "Information Collection and Use",
      description: `We collect various types of information to improve and provide our Service.`,
    },
    {
      title: "Types of Data Collected",
      description: `Personal Data: While using our Service, we may ask you to provide certain personally identifiable information (“Personal Data”). This information may include: Email address, First and last name, Phone number, Address including city, state, and ZIP/postal code.`,
    },
    {
      title: "Usage Data and Cookies",
      description: `We may collect information about how you access and use the Service (“Usage Data”). This may include details such as IP address, Browser type and version, Pages visited and time spent on them, Device identifiers, and other diagnostic data.`,
    },
    {
      title: "Tracking and Cookies",
      description: `We use cookies and similar technologies to track your activity on our Service. Cookies are small data files stored on your device. You can adjust your browser settings to reject cookies, but some features of the Service may not function as intended if cookies are disabled.`,
    },
    {
      title: "Use of Data",
      description: `Trusted Interpreters uses collected data for the following purposes: To provide and maintain the Service, To notify you of updates and changes to the Service, To allow participation in interactive features, To provide customer support, To analyze and improve the Service’s performance, To detect and address technical issues.`,
    },
    {
      title: "Data Transfer",
      description: `Your information, including Personal Data, may be transferred to and maintained on computers located outside your state, province, or country where data protection laws may differ. By providing your information, you consent to such transfers. We take all reasonable steps to ensure your data is treated securely and in compliance with this Privacy Policy.`,
    },
    {
      title: "Data Disclosure",
      description: `We may disclose your Personal Data in the following cases: To comply with legal obligations, To protect and defend the rights or property of Trusted Interpreters, To investigate possible misconduct related to the Service, To protect the safety of Service users and the public, To prevent legal liability.`,
    },
    {
      title: "Security of Data",
      description: `We prioritize the security of your data and use commercially reasonable methods to protect it. However, no method of transmission or storage is 100% secure, and we cannot guarantee absolute security.`,
    },
    {
      title: "Third-Party Service Providers",
      description: `We may employ third-party companies to facilitate the Service, provide the Service on our behalf, perform Service-related tasks, and analyze how our Service is used. These providers have access to your Personal Data solely for performing assigned tasks and are obligated to protect it.`,
    },
    {
      title: "Children’s Privacy",
      description: `Our Service is not intended for individuals under 18 (“Children”). We do not knowingly collect data from Children. If we become aware that we have inadvertently gathered such information, we will delete it promptly.`,
    },
    {
      title: "Changes to This Privacy Policy",
      description: `We may update this Privacy Policy occasionally. Updates will be posted on this page, and we will notify you of significant changes via email or a prominent notice before they take effect. Please review this policy periodically for updates.`,
    },
    {
        title: "Contact Us",
        description: (
          <>
            If you have questions about this Privacy Policy, please contact us:  
            <a
              href="mailto:sales@trustedinterpreters.com"
              className="text-[#024772] underline"
            >
              sales@trustedinterpreters.com
            </a>
          </>
        ),
      }
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Privacy Policy | Trusted Interpreters</title>
        <meta
          name="description"
          content="Privacy policy outlining data collection, use, and rights for Trusted Interpreters users."
        />
      </Helmet>
      <div className="container mx-auto px-4 py-4">
        <div className="sm:flex justify-center max-w-screen-xl mx-auto">
          <div className="p-5">
            <div className="text animate-slide-in">
              <h2 className="my-4 font-bold text-3xl sm:text-4xl text-center">
                Privacy Policy
              </h2>
            </div>
          </div>
        </div>
        <div className="mt-4 space-y-10 xl:space-y-8 text-center xl:text-start">
          {sections.map((section, index) => (
            <div key={index}>
              <h3 className="text-xl font-bold">{section.title}</h3>
              <p className="mt-2 text-gray-700">{section.description}</p>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;