import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import OnsiteImage2 from "../../../src/assets/images/adobe/Phoenix1.webp";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import Helmet from "react-helmet";
// images
import WashingtonImage from "../../../src/assets/images/adobe/Washington.webp";
import PeopleImage from "../../../src/assets/images/adobe/adobe12.webp";
import PeopleImage1 from "../../../src/assets/images/adobe/adobe13.webp";

const Washington: React.FC = () => {
  const washingtonBenefits = [
    {
      icon: FaAward,
      title: "Experienced Professionals",
      text: "We ensure accurate communication while respecting cultural nuances.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Understanding",
      text: "Our interpreters are certified, trained, and fluent in over 200 languages.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in Washington, D.C., delivering consistent support wherever needed.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services Washington</title>
        <meta
          name="description"
          content="Trusted Interpreters provides accurate interpretation services in Washington."
        />
      </Helmet>
      <PageHeader
        title="Expert Interpretation Services in Washington, D.C."
        description="Trusted Interpreters delivers expert language solutions tailored to meet the demands of Washington, D.C.’s dynamic industries and multilingual communities. From government agencies to healthcare providers, our services ensure precise and effective communication in the nation’s capital."
        imageUrl={WashingtonImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Choose Trusted Interpreters in Washington, D.C.?"
          contentList={[
            {
              text: "Certified Interpreters: Fluent in over 200 languages, our professionals bring specialized expertise in legal, medical, corporate, and governmental fields.",
              links: [
                {
                  linkText: "legal",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "medical",
                  link: "/expertise/medical-interpretation",
                },
                {
                  linkText: "corporate",
                  link: "/expertise/business-interpretation",
                },
                {
                  linkText: "governmental",
                  link: "/expertise/government-interpretation",
                },
              ],
            },
            {
              text: "Flexible Options: Whether on-site, over-the-phone, or virtual, our services are customized to meet your unique requirements.",
              links: [
                {
                  linkText: "on-site",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "over-the-phone",
                  link: "/interpretation-types/phone-interpretation",
                },
                {
                  linkText: "virtual",
                  link: "/interpretation-types/vri",
                },
              ],
            },
            {
              text: "Local Knowledge: We understand the fast-paced, multilingual environment of Washington, D.C., ensuring accurate and culturally sensitive communication.",
            },
          ]}
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />

        
        <ContentSection
          title="Interpretation Services We Offer"
          contentList={[
            {
              text: "Tailored On-Site Interpretation: Our interpreters provide real-time support for conferences, legal proceedings, business meetings, and healthcare appointments, ensuring precise and effective communication.",
              links: [
                {
                  linkText: "Tailored On-Site Interpretation",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "business meetings",
                  link: "/expertise/business-interpretation",
                },
                {
                  linkText: "legal proceedings",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "healthcare appointments",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Rapid Phone Interpretation: For urgent or remote needs, our 24/7 phone interpretation services connect you instantly with certified linguists, offering immediate language support.",
              links: [
                {
                  linkText: "Rapid Phone Interpretation",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Advanced Virtual Solutions: Seamlessly connect with multilingual audiences through our video remote interpretation (VRI) services, ideal for hybrid events, webinars, and virtual meetings.",
              links: [
                {
                  linkText: "Advanced Virtual Solutions",
                  link: "/interpretation-types/vri",
                },
                {
                  linkText: "video remote interpretation (VRI)",
                  link: "/interpretation-types/vri",
                },
                {
                  linkText: "virtual meetings",
                  link: "/interpretation-types/vri/microsoft-teams-interpretation-services",
                },
              ],
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks/>

        <ContentSection
          title="Industries We Support in Washington, D.C."
          contentList={[
            {
              text: "Government and Diplomacy: Facilitating communication for global events, diplomatic meetings, and international collaborations.",
              links: [
                {
                  linkText: "Government and Diplomacy",
                  link: "/expertise/government-interpretation",
                },
              ],
            },
            {
              text: "Healthcare: Bridging language gaps between providers and patients in hospitals, clinics, and medical offices.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal Services: Providing court-certified interpreters for hearings, trials, and legal consultations.",
              links: [
                {
                  linkText: "Legal Services",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Corporate: Supporting international negotiations, training sessions, and conferences.",
              links: [
                {
                  linkText: "Corporate",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Education: Enhancing communication in schools, universities, and training programs.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
          ]}
          additionalContent="From Capitol Hill to Georgetown, and across the D.C. metro area, Trusted Interpreters provides reliable interpretation services wherever and whenever you need them."
          imageUrl={PeopleImage1}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={washingtonBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Trusted Interpreters Today"
          description="Ensure success in your communication efforts. Reach out to Trusted Interpreters today to learn more about our expert interpretation services in Washington, D.C., and how we can support your goals."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Washington;
