import React from 'react';
import { FaGlobe, FaCheckCircle, FaClock } from 'react-icons/fa';

const ProcessBenefits: React.FC = () => {
  return (
    <div className="relative flex flex-col items-center justify-center h-auto md:h-96 w-full mx-auto px-8 pb-8">
      <div className="absolute bg-orange-600 h-28 w-28 sm:h-36 sm:w-36 md:h-48 md:w-48 rounded-full opacity-30 z-0"></div>
      <img 
        src="https://www.cerquetech.com/assets/images/shapes/shape_circle_dashed_1.svg" 
        alt="dashed circle" 
        className="absolute h-56 w-56 sm:h-64 sm:w-64 md:h-80 md:w-80 animate-spin-slow z-0" 
        style={{ animationDuration: '30s' }}
      />

      <div className="relative flex flex-col items-center z-10">
        {/* Elementos superiores */}
        <div className="flex space-x-8 sm:space-x-16 md:space-x-20 mb-4">
          <BenefitCircle icon={<FaGlobe />} color="bg-orange-500" text="Global Experts" />
          <BenefitCircle icon={<FaCheckCircle />} color="bg-pink-500" text="Guaranteed Quality" />
        </div>
        {/* Elemento inferior */}
        <div className="mt-16">
          <BenefitCircle icon={<FaClock />} color="bg-teal-500" text="Quick Response" />
        </div>
      </div>
    </div>
  );
};

const BenefitCircle: React.FC<{ icon: React.ReactNode; color: string; text: string }> = ({ icon, color, text }) => {
  return (
    <div className="flex flex-col items-center bg-gray-100 h-24 w-24 sm:h-28 sm:w-28 md:h-32 md:w-32 rounded-full shadow-lg p-3 text-center">
      <div className={`h-8 w-8 sm:h-12 sm:w-12 md:h-12 md:w-12 rounded-full flex items-center justify-center ${color} text-white mb-2`}>
        {icon}
      </div>
      <p className="text-gray-800 font-semibold text-xs sm:text-sm md:text-base">{text}</p>
    </div>
  );
};

export default ProcessBenefits;