import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import CallToAction from "../../../components/CallToAction";
import ESLImage from "../../../../src/assets/images/vectors/esl.png";
import ESLImage1 from "../../../../src/assets/images/adobe/ESL1.webp";
import ESLImage2 from "../../../../src/assets/images/adobe/handshake.webp";
import ESLImage3 from "../../../../src/assets/images/adobe/ASL.webp";
import { FaBriefcase, FaShield, FaUserGraduate } from "react-icons/fa6";
import Helmet from "react-helmet";

const ESL: React.FC = () => {
  const ESLBenefits = [
    {
      icon: FaUserGraduate,
      title: "Experienced Professionals",
      text: "Our interpreters are skilled in ESL and understand its linguistic intricacies.",
    },
    {
      icon: FaBriefcase,
      title: "Diverse Expertise",
      text: "We cater to various industries, including education, healthcare, and business.",
    },
    {
      icon: FaShield,
      title: "Ethical Standards",
      text: "Our interpreters are bound by strict confidentiality agreements and follow ethical guidelines to ensure professionalism.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Reliable English Sign Language US</title>
        <meta
          name="description"
          content="Trusted Interpreters provides expert English sign language interpretation nationwide."
        />
      </Helmet>
      <PageHeader
        title="Reliable English Sign Language Services for All Needs"
        description="Trusted Interpreters offers professional English Sign Language (ESL) interpretation services, connecting you with experts who ensure effective communication for the hearing-impaired community. We understand the unique nuances of ESL and provide interpreters tailored to your specific needs."
        imageUrl={ESLImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="What is English Sign Language (ESL)?"
          content="English Sign Language is distinct from American Sign Language (ASL), with significant differences in grammar, word order, and structure. ESL is often used in English-speaking regions outside the U.S., making it essential for communication in specific international contexts."
          imageUrl={ESLImage1}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Key Features of ESL Interpretation:"
          contentList={[
            {
              text: "Accurate translation of spoken English into English Sign Language.",
            },
            {
              text: "Adaptation to cultural and regional nuances in communication.",
            },
            {
              text: "Specialized support for settings such as business meetings, educational institutions, and public events.",
            },
          ]}
          additionalContent="Whether it’s a corporate event, a public seminar, or a personal meeting, Trusted Interpreters ensures that your ESL interpretation needs are met with precision and care, anywhere in the United States."
          imageUrl={ESLImage2}
          direction="left"
        />

        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <ContentSection
          title="Supporting Accessibility Through Pidgin Signed English (PSE)"
          content="In addition to ESL, we provide interpreters trained in Pidgin Signed English (PSE), a blend of ASL and English that incorporates elements from both languages. This is particularly helpful for individuals who have learned sign language later in life or use English as their primary language."
          imageUrl={ESLImage3}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Our ESL Interpretation Services?">
          <KeyBenefits benefits={ESLBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Us Today"
          description="Enhance communication in your educational institution with professional interpretation services. Contact Trusted Interpreters now to learn more or to book an interpreter for your next event."
          buttonText="Contact Us"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default ESL;