import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import { Helmet } from "react-helmet";
// images
import LAImage from "../../../src/assets/images/adobe/LA.webp";
import LAImage1 from "../../../src/assets/images/adobe/LA1.webp";
import LAImage2 from "../../../src/assets/images/adobe/LA2.webp";
import PeopleImage from "../../../src/assets/images/adobe/Legal1.webp";

const LosAngeles: React.FC = () => {
  const LABenefits = [
    {
      icon: FaAward,
      title: "Certified Professionals",
      text: "Our interpreters are highly trained and certified, ensuring reliable and precise interpretation.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Awareness",
      text: "We prioritize cultural nuances to ensure your message resonates effectively.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in Los Angeles, delivering consistent support wherever needed.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services LA</title>
        <meta
          name="description"
          content="Trusted Interpreters provides expert interpretation services in Los Angeles."
        />
      </Helmet>

      <PageHeader
        title="Professional Interpretation Services in Los Angeles"
        description="Trusted Interpreters provides expert interpretation services designed to meet the needs of Los Angeles’ vibrant and diverse communities. Whether for legal hearings, entertainment events, healthcare appointments, or corporate meetings, our certified interpreters ensure clear and accurate communication in over 200 languages."
        imageUrl={LAImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Choose Trusted Interpreters in Los Angeles?"
          contentList={[
            {
              text: "Extensive Expertise: Our interpreters are fluent in over 200 languages and specialize in industries like entertainment, healthcare, legal, and business.",
              links: [
                {
                  linkText: "healthcare",
                  link: "/expertise/medical-interpretation",
                },
                {
                  linkText: "legal",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "business",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Customized Solutions: We tailor our services to meet LA’s unique industries and multilingual audiences.",
            },
            {
              text: "Flexibility: Our interpreters are available throughout Los Angeles County, from downtown LA to surrounding neighborhoods.",
            },
          ]}
          imageUrl={LAImage1}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Comprehensive Interpretation Services"
          contentList={[
            {
              text: "On-Site Interpretation: Ideal for legal hearings, business meetings, and live events. Our interpreters will travel to your venue anywhere in Los Angeles.",
              links: [
                {
                  linkText: "On-Site Interpretation",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "legal hearings",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "business meetings",
                  link: "/expertise/business-interpretation",
                },
                {
                  linkText: "live events",
                  link: "/interpretation-types/on-site/simultaneous",
                },
              ],
            },
            {
              text: "Over-the-Phone Interpretation: Quick and reliable language support for urgent needs.",
              links: [
                {
                  linkText: "Over-the-Phone Interpretation",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Video Remote Interpretation (VRI): Perfect for virtual meetings and hybrid events, ensuring seamless communication in real-time.",
              links: [
                {
                  linkText: "Video Remote Interpretation (VRI)",
                  link: "/interpretation-types/vri",
                },
              ],
            },
          ]}
          imageUrl={PeopleImage}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks />

        <ContentSection
          title="Industries We Support in Los Angeles"
          content="With LA’s diverse economy, our interpreters bring expertise across numerous sectors:"
          contentList={[
            {
              text: "Entertainment and Media: Supporting multilingual production meetings, press conferences, and live events.",
              links: [
                {
                  linkText: "Entertainment and Media",
                  link: "/interpretation-types/on-site/simultaneous",
                },
              ],
            },
            {
              text: "Healthcare: Facilitating communication in hospitals, clinics, and medical offices.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal Services: Providing court-certified interpreters for trials, depositions, and legal consultations.",
              links: [
                {
                  linkText: "Legal Services",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Corporate and Business: Enabling smooth international negotiations and team collaboration.",
              links: [
                {
                  linkText: "Corporate and Business",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
          ]}
          additionalContent="From Hollywood to Santa Monica, Downtown LA to Pasadena, Trusted Interpreters delivers professional services wherever you need them. We ensure you receive expert interpretation tailored to your location and requirements."
          imageUrl={LAImage2}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={LABenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Trusted Interpreters Today"
          description="Enhance your communication and overcome language barriers in Los Angeles. Contact Trusted Interpreters for professional interpretation services that support your success in this vibrant city."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default LosAngeles;
