import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
// images
import PeopleImage from "../../../src/assets/images/adobe/adobe5.webp";
import fortWorth from "../../../src/assets/images/adobe/fort worth.webp";
import fortWorth1 from "../../../src/assets/images/adobe/fort worth1.webp";
import fortWorth2 from "../../../src/assets/images/adobe/fort worth2.webp";

const FortWorth: React.FC = () => {
  const fortWorthBenefits = [
    {
      icon: FaAward,
      title: "Experienced Professionals",
      text: "We ensure accurate communication while respecting cultural nuances.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Understanding",
      text: "Our interpreters are certified, trained, and fluent in over 200 languages.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in Fort Worth, delivering consistent support wherever needed.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <PageHeader
        title="Professional Interpretation Services in Fort Worth for Every Occasion"
        description="Trusted Interpreters delivers expert language solutions tailored to meet the diverse communication needs of Fort Worth’s businesses, healthcare providers, legal professionals, and educational institutions. With certified interpreters fluent in over 200 languages, we ensure seamless communication in any setting."
        imageUrl={fortWorth}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Choose Trusted Interpreters in Fort Worth?"
          contentList={[
            {
              text: "Certified Professionals: Our interpreters are highly skilled and fluent in over 200 languages, with expertise across multiple industries.",
            },
            {
              text: "Customized Solutions: We adapt our services to meet your unique needs, whether it’s a one-time event or ongoing support.",
              links: [
                {
                  linkText: "one-time event",
                  link: "/interpretation-types/phone-interpretation/one-time-service",
                },
                {
                  linkText: "ongoing support",
                  link: "/interpretation-types/phone-interpretation/monthly-recurrent-service",
                },
              ],
            },
            {
              text: "Reliable Coverage: From downtown Fort Worth to surrounding neighborhoods, we provide language solutions wherever you need them.",
            },
          ]}
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />

        
        <ContentSection
          title="Tailored Interpretation Services"
          contentList={[
            {
              text: "In-Person Expertise: For live events, meetings, or legal proceedings, our on-site interpreters ensure accurate, real-time communication tailored to your specific audience.",
              links: [
                {
                  linkText: "In-Person Expertise",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "meetings",
                  link: "/interpretation-types/on-site/consecutive",
                },
                {
                  linkText: "legal proceedings",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Immediate Phone Support: When time is critical, our over-the-phone interpretation services provide instant access to professional linguists, available 24/7 for your convenience.",
              links: [
                {
                  linkText: "Immediate Phone Support",
                  link: "/interpretation-types/phone-interpretation",
                },
                {
                  linkText: "over-the-phone",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Seamless Virtual Communication: For virtual meetings, hybrid events, and webinars, our video remote interpretation (VRI) services deliver flawless multilingual support in real time.",
              links: [
                {
                  linkText: "Seamless Virtual Communication",
                  link: "/interpretation-types/vri",
                },
                {
                  linkText: "virtual meetings",
                  link: "/interpretation-types/vri/skype-interpretation-services",
                },
              ],
            },
          ]}
          imageUrl={fortWorth1}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks/>

        <ContentSection
          title="Industries We Serve in Fort Worth"
          contentList={[
            {
              text: "Healthcare: Helping providers and patients communicate effectively in hospitals, clinics, and private practices.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal: Offering court-certified interpreters for trials, depositions, and legal consultations.",
              links: [
                {
                  linkText: "Legal",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Business: Supporting international negotiations, corporate training sessions, and conferences.",
              links: [
                {
                  linkText: "Business",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Education: Bridging language gaps in schools, universities, and training programs.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
          ]}
          additionalContent="From the Stockyards to Sundance Square, and throughout surrounding areas like Arlington and Weatherford, Trusted Interpreters is your reliable partner for professional interpretation services."
          imageUrl={fortWorth2}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={fortWorthBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Trusted Interpreters Today"
          description="Don’t let language barriers hold you back. Contact Trusted Interpreters today to learn more about our expert interpretation services in Fort Worth and how we can support your communication needs."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default FortWorth;
