import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import OnsiteImage2 from "../../../src/assets/images/adobe/adobe6.webp";
import { FaCertificate } from "react-icons/fa6";
import { FaGlobeAmericas, FaMapMarkedAlt } from "react-icons/fa";
// images
import SpanishImage from "../../../src/assets/images/vectors/spanish.png";
import PeopleImage from "../../../src/assets/images/adobe/NYC1.webp";
import PeopleImage1 from "../../../src/assets/images/adobe/adobe19.webp";
import Helmet from "react-helmet";

const SpanishExpertise: React.FC = () => {
  const spanishBenefits = [
    {
      icon: FaCertificate,
      title: "Certified Professionals",
      text: "Our interpreters are highly trained and certified, offering reliable and accurate services.",
    },
    {
      icon: FaGlobeAmericas,
      title: "Cultural Understanding",
      text: "We pair you with interpreters who understand the cultural context of the target language, ensuring effective communication.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Availability",
      text: "With interpreters located across the U.S., we are ready to support your language needs anywhere.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Spanish Interpretation Services US</title>
        <meta
          name="description"
          content="Trusted Interpreters offers professional Spanish interpretation services nationwide."
        />
      </Helmet>
      <PageHeader
        title="Professional Spanish Interpretation Services Across the U.S."
        description="Trusted Interpreters offers expert Spanish interpretation services to bridge communication gaps and ensure seamless interactions in professional and personal settings. With Spanish being one of the most widely spoken languages in the U.S., our interpreters are ready to assist you with precision and cultural understanding."
        imageUrl={SpanishImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Choose Spanish Interpretation?"
          content="Spanish interpretation is essential for effective communication in various settings, including medical appointments, legal proceedings, business meetings, and educational environments. Our certified interpreters ensure that your message is accurately conveyed to Spanish-speaking audiences, fostering understanding and inclusivity."
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Scenarios Where We Excel"
          content="Our Spanish interpretation services are tailored to make a difference in real-world scenarios:"
          contentList={[
            {
              text: "Empowering businesses to reach Spanish-speaking customers, partners, or employees during meetings and events.",
              links: [
                {
                  linkText: "businesses",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Facilitating government programs, community outreach initiatives, and social service appointments to improve accessibility.",
              links: [
                {
                  linkText: "government",
                  link: "/expertise/government-interpretation",
                },
              ],
            },
            {
              text: "Enhancing education by supporting parent-teacher meetings, student assessments, and academic discussions.",
              links: [
                {
                  linkText: "education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
            {
              text: "Helping healthcare providers deliver compassionate and effective care to Spanish-speaking patients.",
              links: [
                {
                  linkText: "healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Assisting legal teams in courtrooms, depositions, or client consultations where accurate language translation is crucial.",
              links: [
                {
                  linkText: "legal",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks currentLanguage="Spanish"/>

        <ContentSection
          title="Key Features of Our Spanish Interpretation Services:"
          contentList={[
            {
              text: "Real-Time Communication: Support for on-site, over-the-phone, and video remote interpretation.",
            },
            {
              text: "Cultural Sensitivity: Interpreters trained in regional dialects and cultural nuances.",
            },
            {
              text: "Certified Professionals: Ensuring accuracy and professionalism in every interaction.",
            },
          ]}
          additionalContent="With interpreters located across the U.S., Trusted Interpreters ensures that Spanish interpretation services are available wherever you need them. Whether for a local meeting or a nationwide event, we provide reliable solutions to suit your needs."
          imageUrl={PeopleImage1}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={spanishBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Get Started with Trusted Interpreters"
          description="Break language barriers and connect effectively with Spanish-speaking audiences. Contact Trusted Interpreters today to learn more about our Spanish interpretation services and schedule a professional interpreter for your next project."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default SpanishExpertise;
