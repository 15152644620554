import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import CallToAction from "../../../components/CallToAction";
import RIDImage from "../../../../src/assets/images/vectors/rid.png";
import OnsiteImage1 from "../../../../src/assets/images/adobe/Phoenix1.webp";
import OnsiteImage2 from "../../../../src/assets/images/adobe/ASL1.webp";
import OnsiteImage3 from "../../../../src/assets/images/adobe/adobe15.webp";
import { FaAward, FaBriefcase, FaScaleBalanced } from "react-icons/fa6";
import Helmet from "react-helmet";

const RID: React.FC = () => {
  const RIDBenefits = [
    {
      icon: FaAward,
      title: "Professional Excellence",
      text: "RID certification ensures interpreters meet rigorous standards for accuracy, ethics, and professionalism.",
    },
    {
      icon: FaBriefcase,
      title: "Specialized Expertise",
      text: "Certified interpreters are experienced in various fields, including education, healthcare, legal, and corporate sectors.",
    },
    {
      icon: FaScaleBalanced,
      title: "Ethical Integrity",
      text: "RID-certified interpreters adhere to strict ethical guidelines, maintaining confidentiality and respect for all parties.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>RID-Certified ASL Interpreters US</title>
        <meta
          name="description"
          content="Get professional RID-certified ASL interpreters for seamless communication nationwide."
        />
      </Helmet>
      <PageHeader
        title="Professional RID-Certified ASL Interpreters Nationwide"
        description="At Trusted Interpreters, we provide RID-certified American Sign Language (ASL) interpreters to ensure the highest standard of communication for the Deaf and hard-of-hearing community. Our certified professionals deliver accurate and reliable ASL interpretation services across the United States."
        imageUrl={RIDImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="What is RID Certification?"
          content="The Registry of Interpreters for the Deaf (RID) sets the gold standard for ASL interpreter certification in the U.S. RID certification ensures interpreters possess the skills, ethical decision-making abilities, and linguistic expertise required for professional ASL interpretation."
          imageUrl={OnsiteImage1}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Nationwide Availability and Support"
          content="Trusted Interpreters connects you with RID-certified ASL interpreters wherever you are in the U.S. Our network of professionals ensures that you receive top-quality services tailored to your specific needs. These are the RID Certification Levels:"
          contentList={[
            {
              text: "NIC (National Interpreter Certification): Demonstrates proficiency in interpreting and ethical decision-making.",
            },
            {
              text: "NIC Advanced: For interpreters with high-level performance in live interpretation.",
            },
            {
              text: "NIC Master: The highest certification level, indicating exceptional skills in ASL interpretation and professional expertise.",
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <ContentSection
          title="Versatile Services for Every Setting"
          content="Our RID-certified interpreters are skilled in facilitating communication for:"
          contentList={[
            {
              text: "Educational institutions, including classrooms and conferences.",
              links: [
                {
                  linkText: "Educational institutions",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
            {
              text: "Medical settings, ensuring clear communication between patients and providers.",
              links: [
                {
                  linkText: "Medical settings",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal proceedings, offering precise and reliable interpretation.",
              links: [
                {
                  linkText: "Legal proceedings",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Corporate meetings and events, promoting accessibility and inclusion.",
              links: [
                {
                  linkText: "Corporate meetings and events",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
          ]}
          imageUrl={OnsiteImage3}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Our RID-Certified Interpreters?">
          <KeyBenefits benefits={RIDBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Us Today"
          description="Enhance communication and inclusivity at your next event or meeting with RID-certified ASL interpreters. Contact us today to schedule expert interpreters and experience seamless communication."
          buttonText="Contact Us"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default RID;