import React from 'react';

interface CallToActionProps {
  title: string;
  description: string;
  buttonText?: string;
  buttonLink?: string;
}

const CallToAction: React.FC<CallToActionProps> = ({
  title,
  description,
  buttonText = "Contact Us",
  buttonLink = "#contact",
}) => {
  return (
    <section className="px-6 py-6">
      <div className="container mx-auto">
        <div className="p-10 lg:py-14 lg:pl-20 lg:pr-10 rounded-lg text-white flex items-center justify-between flex-col lg:flex-row bg-[#024772] lg:bg-gradient-to-r lg:from-[#024772] lg:via-[#3e6f8f] lg:to-[#e5e9ec]">
          <div className="lg:w-3/4 text-center lg:text-left">
            <h2 className="font-manrope text-4xl font-semibold mb-5 lg:mb-2">
              {title}
            </h2>
            <p className="hidden lg:block text-lg lg:text-xl leading-relaxed">
              {description}
            </p>
          </div>
          <a
            href={buttonLink}
            className="flex items-center gap-2 bg-white hover:bg-gray-200 rounded-full shadow-md text-lg text-blue-700 font-semibold py-4 px-8 transition-all duration-500"
          >
            {buttonText}
            <svg
              width="19"
              height="14"
              viewBox="0 0 19 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.75 7L16.4167 7M11.8333 12.5L16.6852 7.64818C16.9907 7.34263 17.1435 7.18985 17.1435 7C17.1435 6.81015 16.9907 6.65737 16.6852 6.35182L11.8333 1.5"
                stroke="#024772"
                strokeWidth="2.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </a>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
