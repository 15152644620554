import type React from "react"

const AboutUsSchemaMarkup: React.FC = () => {
  const websiteSchema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: "Trusted Interpreters - Professional Interpretation Services",
    url: window.location.origin,
  }

  const breadcrumbsSchema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: `${window.location.origin}/`,
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "About Us",
        item: `${window.location.origin}/about-us`,
      },
    ],
  }

  const aboutUsSchema = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    name: "Trusted Interpreters LLC",
    url: `${window.location.origin}/about-us`,
    logo: "https://www.trustedinterpreters.com/static/media/logo_ti.093360928a109ef4474b.jpg",
    description:
      "Learn more about Trusted Interpreters, our mission, values, and professional interpretation services.",
  }

  const schemaDataArray = [websiteSchema, breadcrumbsSchema, aboutUsSchema]

  return (
    <>
      {schemaDataArray.map((schema, index) => (
        <script key={index} type="application/ld+json">
          {JSON.stringify(schema)}
        </script>
      ))}
    </>
  )
}

export default AboutUsSchemaMarkup