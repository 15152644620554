import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import OnsiteImage2 from "../../../src/assets/images/adobe/SanAntonio2.webp";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import Helmet from "react-helmet";
// images
import GermanImage from "../../../src/assets/images/vectors/german.png";
import PeopleImage from "../../../src/assets/images/adobe/adobe21.webp";
import PeopleImage1 from "../../../src/assets/images/adobe/sanjose1.webp";

const GermanExpertise: React.FC = () => {
  const germanBenefits = [
    {
      icon: FaAward,
      title: "Certified Experts",
      text: "Our interpreters are trained, certified, and experienced in German language interpretation across various sectors.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Understanding",
      text: "We ensure linguistic and cultural accuracy for effective communication.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Availability",
      text: "Trusted Interpreters offers German interpretation services across the U.S., providing accessibility wherever you need it.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Professional German Interpretation US</title>
        <meta
          name="description"
          content="Trusted Interpreters specializes in reliable German interpretation services."
        />
      </Helmet>
      <PageHeader
        title="Professional German Interpretation Tailored to Your Needs"
        description="Trusted Interpreters provides expert German interpretation services, offering precise and culturally aware communication for German-speaking audiences. Whether for corporate events, legal matters, or medical consultations, our certified interpreters ensure your message is delivered clearly and effectively."
        imageUrl={GermanImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why German Interpretation is Essential"
          content="German is a key language for international business, diplomacy, and education. Our interpreters bridge language and cultural gaps, enabling seamless communication with German-speaking clients, partners, or communities."
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Real-World Scenarios We Support"
          content="Our German interpretation services are designed to meet your unique needs, including:"
          contentList={[
            {
              text: "Corporate Excellence: Enhance business relationships during negotiations, presentations, and global meetings with German-speaking stakeholders.",
              links: [
                {
                  linkText: "Corporate Excellence",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Cultural Integration: Assist organizations in connecting with German-speaking communities for outreach and cultural events.",
              links: [
                {
                  linkText: "Cultural Integration",
                  link: "/expertise/immigration-interpretation",
                },
              ],
            },
            {
              text: "Educational Collaboration: Support academic discussions, international student engagement, and parent-teacher meetings.",
              links: [
                {
                  linkText: "Educational Collaboration",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
            {
              text: "Healthcare Clarity: Ensure precise communication between medical professionals and German-speaking patients for better care outcomes.",
              links: [
                {
                  linkText: "Healthcare Clarity",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal Precision: Provide accurate interpretation for legal proceedings, contracts, and consultations involving German speakers.",
              links: [
                {
                  linkText: "Legal Precision",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks currentLanguage="German"/>

        <ContentSection
          title="Highlights of Our German Interpretation Services:"
          contentList={[
            {
              text: "Cultural Sensitivity: Interpreters skilled in both language and cultural nuances for effective communication.",
            },
            {
              text: "Tailored Expertise: Specialized knowledge for industries like healthcare, law, technology, and business.",
            },
            {
              text: "Flexible Formats: Available for on-site, over-the-phone, and virtual meetings.",
            },
          ]}
          additionalContent="With interpreters located across the U.S., Trusted Interpreters ensures that German interpretation services are available wherever you need them. Whether for a local meeting or a nationwide event, we provide reliable solutions to suit your needs."
          imageUrl={PeopleImage1}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={germanBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Let Trusted Interpreters Support Your German Communication Needs"
          description="Whether you’re hosting a business meeting, planning a legal consultation, or organizing an event, Trusted Interpreters ensures seamless communication with German-speaking audiences. Contact us today to schedule an interpreter and experience professional, reliable service."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default GermanExpertise;