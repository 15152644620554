import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import CallToAction from "../../../components/CallToAction";
import { FaCogs, FaHeadphonesAlt, FaUserTie } from "react-icons/fa";
import EquipmentImg from "../../../../src/assets/images/vectors/equipment.png";
import OnsiteImage1 from "../../../../src/assets/images/adobe/Phoenix2.webp";
import OnsiteImage2 from "../../../../src/assets/images/adobe/seattle1.webp";
import Helmet from "react-helmet";

const Equipment: React.FC = () => {
  const equipmentBenefits = [
    {
      icon: FaUserTie,
      title: "Highly Skilled Interpreters",
      text: "Our team includes experts trained to handle the complexities of simultaneous interpretation, ensuring flawless delivery even under high-pressure situations.",
    },
    {
      icon: FaHeadphonesAlt,
      title: "Cutting-Edge Equipment",
      text: "We provide advanced interpretation technology, including headsets, microphones, and soundproof booths, tailored to your event’s requirements.",
    },
    {
      icon: FaCogs,
      title: "Custom Solutions",
      text: "Whether it’s a small corporate meeting or a large international conference, we design our services to meet your specific needs.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Advanced Interpretation Equipment US</title>
        <meta
          name="description"
          content="Ensure flawless communication with Trusted Interpreters' equipment available nationwide."
        />
      </Helmet>
      <PageHeader
        title="Advanced Interpretation Equipment for Conferences and Events"
        description="At Trusted Interpreters, we provide state-of-the-art interpretation equipment to ensure clear and effective communication at events of any size. Whether you're hosting a small meeting or a large international conference, our equipment is designed to support seamless multilingual interactions."
        imageUrl={EquipmentImg}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Essential Equipment for Interpretation Success"
          content="Our range of specialized equipment includes:"
          contentList={[
            {
              text: "Wireless Headphones and Headsets: Enable attendees to hear real-time interpretations without distractions.",
            },
            {
              text: "Soundproof Booths: Available in full-size and tabletop options, ideal for maintaining clarity and minimizing interference.",
            },
            {
              text: "Interpreter Consoles: Connect interpreters with speakers and audiences for flawless communication.",
            },
            {
              text: "Tour-Guide Systems: Perfect for guided tours or smaller, mobile events.",
            },
            {
              text: "Transmitters and Receivers: Ensure reliable signal transmission, even in crowded venues.",
            },
          ]}
          imageUrl={OnsiteImage1}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Industries We Serve"
          content="Our simultaneous interpretation services are trusted by clients across various industries, including:"
          contentList={[
            {
              text: "Legal and governmental organizations",
              links: [
                { linkText: "Legal", link: "/expertise/legal-interpretation" },
                {
                  linkText: "governmental",
                  link: "/expertise/government-interpretation",
                },
              ],
            },
            {
              text: "Healthcare and medical conferences",
              links: [
                {
                  linkText: "Healthcare and medical",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Business and corporate events",
              links: [
                {
                  linkText: "Business",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Educational institutions",
              links: [
                {
                  linkText: "Educational",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
          ]}
          additionalContent="With a network of interpreters spanning the U.S., Trusted Interpreters ensures you have access to professional simultaneous interpretation services wherever and whenever you need them."
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="Consecutive" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Our Simultaneous Interpretation?">
          <KeyBenefits benefits={equipmentBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Us for Your Interpretation Needs"
          description="Prepare for success at your next event with Trusted Interpreters’ simultaneous interpretation services. Contact us today to learn more and secure expert interpreters for your occasion."
          buttonText="Contact Us"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Equipment;
