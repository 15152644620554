import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import OnsiteImage2 from "../../../src/assets/images/adobe/meeting.webp";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import Helmet from "react-helmet";
// images
import AustinImage from "../../../src/assets/images/adobe/Austin.webp";
import PeopleImage from "../../../src/assets/images/adobe/SanAntonio1.webp";
import PeopleImage1 from "../../../src/assets/images/adobe/interpreter1.webp";

const Austin: React.FC = () => {
  const austinBenefits = [
    {
      icon: FaAward,
      title: "Experienced Professionals",
      text: "We ensure accurate communication while respecting cultural nuances.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Understanding",
      text: "Our interpreters are certified, trained, and fluent in over 200 languages.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in Austin, delivering consistent support wherever needed.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services Austin</title>
        <meta
          name="description"
          content="Trusted Interpreters provides professional interpretation in Austin for all needs."
        />
      </Helmet>
      <PageHeader
        title="Professional Interpretation Services in Austin for Every Need"
        description="Trusted Interpreters delivers expert interpretation services in Austin, helping businesses, healthcare providers, legal professionals, and educators communicate seamlessly. With certified interpreters fluent in over 200 languages, we ensure precise and culturally aware communication tailored to your specific needs."
        imageUrl={AustinImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Trusted Interpreters is the Right Choice in Austin"
          contentList={[
            {
              text: "Certified Professionals: Our interpreters are fluent in over 200 languages and trained in industry-specific terminology.",
            },
            {
              text: "Tailored Solutions: From on-site support to remote interpretation services, we adapt to meet your unique needs.",
              links: [
                {
                  linkText: "on-site support",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "remote interpretation",
                  link: "/interpretation-types/vri",
                },
              ],
            },
            {
              text: "Local Expertise: We understand Austin’s diverse community and the industries that make the city thrive.",
            },
          ]}
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />

        
        <ContentSection
          title="Interpretation Services Offered in Austin"
          contentList={[
            {
              text: "On-Site Interpretation: Our interpreters provide face-to-face support for legal proceedings, business meetings, conferences, and medical appointments, ensuring accurate and culturally sensitive communication.",
              links: [
                {
                  linkText: "On-Site Interpretation",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "legal proceedings",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "business meetings",
                  link: "/expertise/business-interpretation",
                },
                {
                  linkText: "medical appointments",
                  link: "/expertise/medical-interpretation",
                },
                {
                  linkText: "conferences",
                  link: "/interpretation-types/on-site/simultaneous",
                },
              ],
            },
            {
              text: "Over-the-Phone Interpretation: For immediate language needs, our phone interpretation service offers quick, reliable support, available 24/7.",
              links: [
                {
                  linkText: "Over-the-Phone Interpretation",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Video Remote Interpretation (VRI): Perfect for virtual events, webinars, or hybrid meetings, VRI ensures seamless multilingual communication in real time.",
              links: [
                {
                  linkText: "Video Remote Interpretation (VRI)",
                  link: "/interpretation-types/vri",
                },
              ],
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks/>

        <ContentSection
          title="Industries We Support in Austin"
          contentList={[
            {
              text: "Technology and Startups: Facilitating global collaborations and technical discussions.",
              links: [
                {
                  linkText: "Technology and Startups",
                  link: "/expertise/technology-interpretation",
                },
              ],
            },
            {
              text: "Healthcare: Supporting effective communication between patients and providers in clinics, hospitals, and private practices.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal: Providing court-certified interpreters for trials, depositions, and legal consultations.",
              links: [
                {
                  linkText: "Legal",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Education: Bridging language gaps in schools, universities, and training programs.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
          ]}
          additionalContent="From Downtown Austin to South Congress, Round Rock to Cedar Park, Trusted Interpreters delivers professional interpretation services wherever you need them."
          imageUrl={PeopleImage1}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={austinBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Trusted Interpreters Today"
          description="Don’t let language barriers hold you back. Partner with Trusted Interpreters for expert interpretation services in Austin. Contact us today to learn how we can support your communication needs."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Austin;
