import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import { FaGlobe, FaUserGraduate } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
// images
import ChineseImage from "../../../src/assets/images/vectors/chinese.png";
import PeopleImage from "../../../src/assets/images/adobe/chinese1.jpeg";
import PeopleImage1 from "../../../src/assets/images/adobe/chinese2.jpeg";
import PeopleImage2 from "../../../src/assets/images/adobe/adobe3.webp";
import Helmet from "react-helmet";

const ChineseExpertise: React.FC = () => {
  const chineseBenefits = [
    {
      icon: FaGlobe,
      title: "Cultural Expertise",
      text: "Our interpreters are not just fluent in the language—they also understand the cultural subtleties that can impact communication.",
    },
    {
      icon: FaUserGraduate,
      title: "Experienced Professionals",
      text: "Each interpreter is rigorously trained and certified to deliver high-quality services.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Reach",
      text: "We provide services across the U.S., ensuring accessibility no matter where you are.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Chinese Interpretation Services US</title>
        <meta
          name="description"
          content="Trusted Interpreters offers precise Chinese interpretation services nationwide."
        />
      </Helmet>
      <PageHeader
        title="Professional Chinese Interpretation Services Across the U.S."
        description="Trusted Interpreters offers expert Chinese interpretation services, connecting you with certified interpreters fluent in Mandarin, Cantonese, and other regional dialects. With our professional services, you can effectively communicate with Chinese-speaking audiences in business, legal, healthcare, and educational settings."
        imageUrl={ChineseImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="The Importance of Chinese Interpretation"
          content="As one of the most widely spoken languages globally, Chinese is essential for international communication. Whether you’re expanding your business, hosting international delegates, or serving Chinese-speaking communities, our interpretation services ensure your message is conveyed accurately and respectfully."
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Tailored Applications of Our Chinese Interpretation Services"
          content="Our Chinese interpretation services are designed to meet a wide variety of needs, including:"
          contentList={[
            {
              text: "Corporate Expansion: Facilitate negotiations, presentations, and partnerships with Chinese-speaking clients and stakeholders.",
              links: [
                {
                  linkText: "Corporate Expansion",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Community Outreach: Assist organizations in engaging with Chinese-speaking populations for social and public service programs.",
              links: [
                {
                  linkText: "Community Outreach",
                  link: "/expertise/government-interpretation",
                },
              ],
            },
            {
              text: "Academic Collaboration: Enhance learning experiences in schools and universities with multilingual support.",
              links: [
                {
                  linkText: "Academic Collaboration",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
            {
              text: "Healthcare Access: Support Chinese-speaking patients during medical consultations and treatment discussions.",
              links: [
                {
                  linkText: "Healthcare Access",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal Assistance: Provide accurate interpretation for depositions, hearings, or client consultations.",
              links: [
                {
                  linkText: "Legal Assistance",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
          ]}
          imageUrl={PeopleImage1}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks currentLanguage="Chinese"/>

        <ContentSection
          title="Our Approach to Chinese Interpretation:"
          contentList={[
            {
              text: "Precision: Our interpreters ensure every word and cultural nuance is accurately communicated.",
            },
            {
              text: "Dialects Covered: We specialize in Mandarin, Cantonese, and other regional Chinese dialects.",
            },
            {
              text: "Tailored Solutions: Services customized to fit the unique needs of your event, meeting, or project.",
            },
          ]}
          additionalContent="Trusted Interpreters provides Chinese interpretation services across the U.S. and beyond. With a vast network of certified professionals, we deliver reliable, high-quality interpretation wherever and whenever you need it."
          imageUrl={PeopleImage2}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={chineseBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Empower Your Communication with Trusted Interpreters"
          description="From small meetings to large-scale events, our Chinese interpretation services help you connect with your audience effectively and confidently. Contact Trusted Interpreters today to discuss your project and schedule a professional interpreter."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default ChineseExpertise;