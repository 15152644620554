import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import CallToAction from "../../../components/CallToAction";
import TeamsImage from "../../../../src/assets/images/vectors/teams.png";
import OnsiteImage1 from "../../../../src/assets/images/adobe/seattle2.webp";
import OnsiteImage2 from "../../../../src/assets/images/adobe/Philadelphia1.webp";
import OnsiteImage3 from "../../../../src/assets/images/adobe/sanjose1.webp";
import { FaLaptopCode, FaUserGraduate, FaWrench } from "react-icons/fa6";
import Helmet from "react-helmet";

const Teams: React.FC = () => {
  const teamsBenefits = [
    {
      icon: FaUserGraduate,
      title: "Experienced Interpreters",
      text: "Our team includes certified professionals fluent in over 200 languages and equipped to handle industry-specific terminology.",
    },
    {
      icon: FaLaptopCode,
      title: "Expert Technical Support",
      text: "We assist with configuring Microsoft Teams' interpretation features, ensuring a seamless experience for your event.",
    },
    {
      icon: FaWrench,
      title: "Tailored Services",
      text: "We provide customized solutions to meet the specific requirements of your audience and event type.",
    },
  ];
  

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Microsoft Teams Interpretation US</title>
        <meta
          name="description"
          content="Trusted Interpreters provides expert Microsoft Teams interpretation services."
        />
      </Helmet>
      <PageHeader
        title="Seamless Microsoft Teams Interpretation Services Nationwide"
        description="Trusted Interpreters specializes in delivering top-quality Microsoft Teams interpretation services for virtual meetings, webinars, and events. With our certified interpreters, we ensure precise communication across languages, making your sessions more inclusive and engaging."
        imageUrl={TeamsImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Microsoft Teams for Multilingual Collaboration?"
          content="Microsoft Teams is widely used by organizations for professional collaboration. Its interpretation capabilities support real-time multilingual communication, allowing businesses to reach global audiences effectively."
          imageUrl={OnsiteImage1}
          direction="right"
        />

        {/* Workflow */}
<ProcessWorkflow process="virtual" />


        <ContentSection
          title="Industries Benefiting from Microsoft Teams Interpretation"
          content="Trusted Interpreters supports diverse industries through Microsoft Teams, including:"
          contentList={[
            {
              text: "Corporate Events: Facilitate global communication for board meetings, training sessions, and webinars.",
              links: [
                {
                  linkText: "Corporate Events",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Educational Settings: Support international student engagement in virtual classes and academic conferences.",
              links: [
                {
                  linkText: "Educational Settings",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
            {
              text: "Healthcare Services: Enable multilingual patient-provider communication during telemedicine appointments.",
              links: [
                {
                  linkText: "Healthcare Services",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal Consultations: Provide accurate interpretation for virtual hearings or depositions.",
              links: [
                {
                  linkText: "Legal Consultations",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>
        <ContentSection
          title="Unique Benefits of Microsoft Teams Interpretation:"
          contentList={[
            {
              text: "Enterprise-Level Integration: Perfect for businesses already using Microsoft 365 tools.",
            },
            {
              text: "Streamlined Workflows: Enables smooth collaboration across internal teams and external partners.",
            },
            {
              text: "Scalable Options: Ideal for anything from small team discussions to large corporate summits.",
            },
          ]}
          additionalContent="Trusted Interpreters offers Microsoft Teams interpretation services across the U.S. and internationally. Our interpreters ensure accurate and professional communication, wherever your participants are located."
          imageUrl={OnsiteImage3}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Trusted Interpreters: Your Partner for Microsoft Teams">
          <KeyBenefits benefits={teamsBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Ready to Elevate Your Microsoft Teams Events?"
          description="Enhance the inclusivity and effectiveness of your virtual events with our expert interpretation services for Microsoft Teams. Contact Trusted Interpreters today to schedule your interpreter and ensure flawless multilingual communication."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Teams;