import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import { Helmet } from "react-helmet";
// images
import DallasImage from "../../../src/assets/images/adobe/Dallas.webp";
import DallasImage1 from "../../../src/assets/images/adobe/meeting.webp";
import DallasImage2 from "../../../src/assets/images/adobe/Speaker.webp";
import DallasImage3 from "../../../src/assets/images/adobe/medical2.webp";

const Dallas: React.FC = () => {
  const dallasBenefits = [
    {
      icon: FaAward,
      title: "Experienced Professionals",
      text: "We ensure accurate communication while respecting cultural nuances.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Understanding",
      text: "Our interpreters are certified, trained, and fluent in over 200 languages.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in Dallas, delivering consistent support wherever needed.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services Dallas</title>
        <meta
          name="description"
          content="Trusted Interpreters provides expert interpretation services in Dallas."
        />
      </Helmet>

      <PageHeader
        title="Interpretation Services in Dallas for Every Need"
        description="Trusted Interpreters offers expert interpretation services designed to meet the diverse linguistic needs of Dallas’s dynamic industries and communities. From legal proceedings and healthcare appointments to corporate meetings and educational events, our certified interpreters ensure precise and culturally sensitive communication in over 200 languages."
        imageUrl={DallasImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="What Sets Us Apart in Dallas?"
          contentList={[
            {
              text: "Industry-Specific Expertise: Our interpreters specialize in legal, medical, corporate, and educational interpretation.",
              links: [
                {
                  linkText: "legal",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "medical",
                  link: "/expertise/medical-interpretation",
                },
                {
                  linkText: "corporate",
                  link: "/expertise/business-interpretation",
                },
                {
                  linkText: "educational",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
            {
              text: "Flexible Solutions: Choose from on-site, over-the-phone, or video remote interpretation (VRI) to suit your preferences.",
              links: [
                {
                  linkText: "on-site",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "over-the-phone",
                  link: "/interpretation-types/phone-interpretation",
                },
                {
                  linkText: "video remote interpretation (VRI)",
                  link: "/interpretation-types/vri",
                },
              ],
            },
            {
              text: "Certified Professionals: Our team of interpreters is fluent in over 200 languages and trained to handle complex communication needs.",
            },
          ]}
          imageUrl={DallasImage1}
          direction="right"
        />

        {/* Workflow */}
        <ProcessWorkflow process="presencial" />

        <ContentSection
          title="Tailored Interpretation Services"
          contentList={[
            {
              text: "On-Site Interpretation: Our interpreters travel to your location, whether it’s a legal hearing, corporate meeting, or community event, ensuring clear communication in person.",
              links: [
                {
                  linkText: "On-Site Interpretation",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "legal hearing",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "community event",
                  link: "/expertise/immigration-interpretation",
                },
              ],
            },
            {
              text: "Phone Interpretation: For urgent or remote language needs, connect instantly with our experienced interpreters available 24/7.",
              links: [
                {
                  linkText: "Phone Interpretation",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Video Remote Interpretation (VRI): Ideal for virtual meetings, hybrid events, and webinars, our VRI services provide real-time multilingual communication without the need for travel.",
              links: [
                {
                  linkText: "Video Remote Interpretation (VRI)",
                  link: "/interpretation-types/vri",
                },
              ],
            },
          ]}
          imageUrl={DallasImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks />

        <ContentSection
          title="Industries We Serve in Dallas"
          contentList={[
            {
              text: "Healthcare: Facilitating effective communication between providers and patients in hospitals, clinics, and private practices.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal: Offering court-certified interpreters for depositions, trials, and consultations.",
              links: [
                {
                  linkText: "Legal",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Corporate: Supporting international negotiations, training sessions, and conferences.",
              links: [
                {
                  linkText: "Corporate",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Education: Bridging language gaps in schools, universities, and training programs.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
          ]}
          additionalContent="From Downtown Dallas to surrounding areas like Plano, Arlington, and Fort Worth, Trusted Interpreters delivers reliable interpretation services wherever you need them."
          imageUrl={DallasImage3}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={dallasBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Ready to Elevate Your Communication?"
          description="Break language barriers with confidence. Contact Trusted Interpreters today to learn more about our professional interpretation services in Dallas and discover how we can support your goals."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Dallas;
