import React from "react";
import ATA from "../assets/images/ATA2-1.webp";
import CCHI from "../assets/images/CCHI.png";
import NBCMI from "../assets/images/NBCMI.png";
import RID from "../assets/images/RID.png";
import Court from "../assets/images/Court.png";
import compliant from "../assets/images/18841_compliant.png";
import compliant1 from "../assets/images/20228_compliant.png";

const OurCertifications: React.FC = () => {
  const certifications = [
    {
      name: "Registry of Interpreters for the Deaf (RID)",
      description:
        "For certified ASL interpreters trained in advanced sign language interpretation.",
      image: RID,
    },
    {
      name: "National Board of Certification for Medical Interpreters (NBCMI)",
      description: "Ensuring excellence in healthcare-related interpretation.",
      image: NBCMI,
    },
    {
      name: "Certification Commission for Healthcare Interpreters (CCHI)",
      description:
        "Recognizing interpreters specialized in medical and healthcare communication.",
      image: CCHI,
    },
    {
      name: "Court Certification",
      description:
        "Many of our interpreters are court-certified in various states, providing expert legal interpretation services.",
      image: Court,
    },
    {
      name: "American Translators Association (ATA)",
      description: "Ensuring linguistic expertise across multiple languages.",
      image: ATA,
    },
    {
      name: "ISO 18841 Interpreting Services",
      description: "Delivering interpreting services that meet the highest standards of professionalism and reliability.",
      image: compliant,
    },
    {
      name: "ISO 20228 Legal Interpreting Services",
      description: "Providing reliable and professional legal interpreting solutions that meet the needs of our clients in legal and judicial contexts.",
      image: compliant1,
    },
  ];

  const topRow = certifications.slice(0, 3);
  const bottomRow = certifications.slice(3);

  return (
    <section className="py-6">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-gray-900">
            Our Certifications
          </h2>
          <p className="text-lg text-gray-600 mt-4">
            Industry-Recognized Credentials
          </p>
        </div>

        {/* Top row - 3 cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
          {topRow.map((cert, index) => (
            <div
              key={index}
              className="bg-gray-100 p-6 rounded-lg shadow-lg flex flex-col items-center text-center transition transform hover:-translate-y-2 hover:shadow-xl"
            >
              <img
                src={cert.image}
                alt={cert.name}
                className="w-24 h-24 object-contain mb-4"
              />
              <h4 className="text-xl font-semibold text-gray-900 mb-2">
                {cert.name}
              </h4>
              <p className="text-gray-600">{cert.description}</p>
            </div>
          ))}
        </div>

        {/* Bottom row - 2 centered cards */}
        <div className="flex justify-center gap-8">
          {bottomRow.map((cert, index) => (
            <div
              key={index}
              className="bg-gray-100 p-6 rounded-lg shadow-lg flex flex-col items-center text-center transition transform hover:-translate-y-2 hover:shadow-xl w-full md:w-[30%]"
            >
              {/* Cambiamos para usar <img> correctamente */}
              <img
                src={cert.image}
                alt={cert.name}
                className="w-24 h-24 object-contain mb-4"
              />
              <h4 className="text-xl font-semibold text-gray-900 mb-2">
                {cert.name}
              </h4>
              <p className="text-gray-600">{cert.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurCertifications;
