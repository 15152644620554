import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import OnsiteImage2 from "../../../src/assets/images/adobe/onsite-img-1.webp";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
// images
import PortlandImage from "../../../src/assets/images/adobe/Portland.webp";
import PeopleImage from "../../../src/assets/images/adobe/adobe14.webp";
import PeopleImage1 from "../../../src/assets/images/adobe/government.webp";

const Portland: React.FC = () => {
  const portlandBenefits = [
    {
      icon: FaAward,
      title: "Experienced Professionals",
      text: "We ensure accurate communication while respecting cultural nuances.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Understanding",
      text: "Our interpreters are certified, trained, and fluent in over 200 languages.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in Portland, delivering consistent support wherever needed.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <PageHeader
        title="Professional Interpretation Services in Portland"
        description="Portland’s vibrant industries and culturally diverse population require seamless communication to foster collaboration and success. At Trusted Interpreters, we provide expert interpretation services tailored to meet the needs of businesses, healthcare providers, legal professionals, and educational institutions throughout Portland and the surrounding areas."
        imageUrl={PortlandImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Choose Trusted Interpreters in Portland?"
          contentList={[
            {
              text: "Experienced Professionals: Our certified interpreters are fluent in over 200 languages and bring industry-specific expertise to every project.",
            },
            {
              text: "Custom Solutions: From one-time events to ongoing support, we design our services to fit your unique requirements.",
              links: [
                {
                  linkText: "one-time events",
                  link: "/interpretation-types/phone-interpretation/one-time-service",
                },
                {
                  linkText: "ongoing support",
                  link: "/interpretation-types/phone-interpretation/monthly-recurrent-service",
                },
              ],
            },
            {
              text: "Local Focus: With an understanding of Portland’s diverse communities, we deliver culturally sensitive and accurate communication.",
            },
          ]}
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />

        
        <ContentSection
          title="Comprehensive Language Services"
          contentList={[
            {
              text: "On-Site Support for In-Person Needs: Our interpreters provide face-to-face assistance for legal proceedings, medical consultations, corporate meetings, and conferences, ensuring precise communication on-site.",
              links: [
                {
                  linkText: "On-Site Support for In-Person Needs",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "corporate meetings",
                  link: "/expertise/business-interpretation",
                },
                {
                  linkText: "legal proceedings",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "medical consultations",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Instant Phone Interpretation: For urgent communication, our phone interpretation services offer 24/7 access to professional linguists, providing real-time solutions when you need them.",
              links: [
                {
                  linkText: "Instant Phone Interpretation",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Virtual Interpretation Made Easy: Our video remote interpretation (VRI) services are perfect for virtual events, webinars, and hybrid meetings, enabling seamless multilingual communication in real time.",
              links: [
                {
                  linkText: "Virtual Interpretation Made Easy",
                  link: "/interpretation-types/vri",
                },
                {
                  linkText: "video remote interpretation (VRI)",
                  link: "/interpretation-types/vri",
                },
                {
                  linkText: "virtual events",
                  link: "/interpretation-types/vri/zoom-interpretation-services",
                },
              ],
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks/>

        <ContentSection
          title="Industries We Serve in Portland"
          contentList={[
            {
              text: "Healthcare: Assisting patients and providers in hospitals, clinics, and private practices with clear, effective communication.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal: Providing court-certified interpreters for depositions, trials, and legal consultations.",
              links: [
                {
                  linkText: "Legal",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Corporate: Supporting businesses with international negotiations, training sessions, and conferences.",
              links: [
                {
                  linkText: "Corporate",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Education: Bridging language gaps in schools, universities, and training programs.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
          ]}
          additionalContent="From Downtown Portland to neighborhoods like Pearl District, Hawthorne, and Beaverton, Trusted Interpreters offers reliable interpretation services wherever you need them in the Portland area."
          imageUrl={PeopleImage1}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={portlandBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Let’s Break Language Barriers Together"
          description="Ensure effective communication in any setting. Contact Trusted Interpreters today to learn more about our professional interpretation services in Portland and how we can support your success."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Portland;
