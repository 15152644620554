import React from "react";

interface LanguageLinksProps {
  currentLanguage?: string;
}

const LanguageLinks: React.FC<LanguageLinksProps> = ({ currentLanguage }) => {
  const languages = [
    {
      name: "English",
      code: "US",
      link: "/language-expertise/english-interpretation",
    },
    {
      name: "Spanish",
      code: "ES",
      link: "/language-expertise/spanish-interpretation",
    },
    {
      name: "French",
      code: "FR",
      link: "/language-expertise/french-interpretation",
    },
    {
      name: "German",
      code: "DE",
      link: "/language-expertise/german-interpretation",
    },
    {
      name: "Italian",
      code: "IT",
      link: "/language-expertise/italian-interpretation",
    },
    {
      name: "Portuguese",
      code: "PT",
      link: "/language-expertise/portuguese-interpretation",
    },
    {
      name: "Japanese",
      code: "JP",
      link: "/language-expertise/japanese-interpretation",
    },
    {
      name: "Chinese",
      code: "CN",
      link: "/language-expertise/chinese-interpretation",
    },
    {
      name: "Arabic",
      code: "SA",
      link: "/language-expertise/arabic-interpretation",
    },
    {
      name: "Haitian",
      code: "HT",
      link: "/language-expertise/haitian-creole-interpretation",
    },
  ];

  // const filteredLanguages = languages.filter(
  //   (language) => language.name.toLowerCase() !== currentLanguage?.toLowerCase()
  // );

  return (
    <div className="flex flex-col items-center space-y-4">
    <h2 className="text-2xl font-semibold text-gray-800">
      Our Languages of Expertise
    </h2>
    <ul className="grid grid-cols-2 gap-4 text-gray-700 mt-4 md:flex md:flex-wrap md:justify-center md:gap-8">
      {languages.map((language, index) => (
        <li key={index} className="flex items-center space-x-2 text-lg">
          {/* Checkmark Icon */}
          <svg
            className="w-5 h-5 text-green-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
          {/* Language Name */}
          <a
            href={language.link}
            className="hover:text-blue-600 transition-colors duration-200"
          >
            {language.name}
          </a>
        </li>
      ))}
    </ul>
  </div>
  );
};

export default LanguageLinks;
