import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import OnsiteImage2 from "../../../src/assets/images/adobe/handshake.webp";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
// images
import SFImage from "../../../src/assets/images/adobe/SanFrancisco.webp";
import PeopleImage from "../../../src/assets/images/adobe/adobe16.webp";
import PeopleImage1 from "../../../src/assets/images/adobe/adobe4.webp";
import { Helmet } from "react-helmet";

const SanFrancisco: React.FC = () => {
  const SFBenefits = [
    {
      icon: FaAward,
      title: "Experienced Professionals",
      text: "We ensure accurate communication while respecting cultural nuances.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Understanding",
      text: "Our interpreters are certified, trained, and fluent in over 200 languages.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in San Francisco, delivering consistent support wherever needed.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services San Francisco</title>
        <meta
          name="description"
          content="Trusted Interpreters delivers professional interpretation services in San Francisco."
        />
      </Helmet>
      <PageHeader
        title="Expert Interpretation Services for San Francisco’s Multilingual Community"
        description="Trusted Interpreters provides professional interpretation services to meet the diverse communication needs of San Francisco’s businesses, healthcare providers, legal professionals, and educational institutions. With certified interpreters fluent in over 200 languages, we ensure seamless and culturally aware communication across the Bay Area."
        imageUrl={SFImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Choose Trusted Interpreters in San Francisco?"
          contentList={[
            {
              text: "Experienced Interpreters: Our certified linguists are fluent in over 200 languages and specialize in industry-specific terminology.",
            },
            {
              text: "Flexible Services: Whether in-person, over the phone, or virtual, we provide interpretation solutions customized to your needs.",
              links: [
                {
                  linkText: "in-person",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "over the phone",
                  link: "/interpretation-types/phone-interpretation",
                },
                {
                  linkText: "virtual",
                  link: "/interpretation-types/vri",
                },
              ],
            },
            {
              text: "Local Understanding: We understand the unique cultural dynamics of San Francisco’s diverse communities, making us the ideal partner for accurate communication.",
            },
          ]}
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />

        
        <ContentSection
          title="Comprehensive Language Solutions"
          contentList={[
            {
              text: "Personalized On-Site Support: For live events, meetings, or legal proceedings, our on-site interpreters ensure accurate, real-time communication tailored to your specific audience.",
              links: [
                {
                  linkText: "Personalized On-Site Support",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "meetings",
                  link: "/interpretation-types/on-site/consecutive",
                },
                {
                  linkText: "legal proceedings",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "On-Demand Phone Interpretation: For urgent situations, connect instantly with our professional interpreters over the phone. Available 24/7, this service is perfect for quick, remote communication.",
              links: [
                {
                  linkText: "On-Demand Phone Interpretation",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Virtual Interpretation for Modern Needs: Seamlessly connect with multilingual audiences through our video remote interpretation (VRI) services, designed for virtual meetings, webinars, and hybrid events.",
              links: [
                {
                  linkText: "Virtual Interpretation for Modern Needs",
                  link: "/interpretation-types/vri",
                },
                {
                  linkText: "virtual meetings",
                  link: "/interpretation-types/vri/google-meet-interpretation-services",
                },
              ],
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks/>

        <ContentSection
          title="Industries We Serve in San Francisco"
          contentList={[
            {
              text: "Technology and Innovation: Facilitating communication for tech companies, startups, and global collaborations.",
              links: [
                {
                  linkText: "Technology and Innovation",
                  link: "/expertise/technology-interpretation",
                },
              ],
            },
            {
              text: "Healthcare: Ensuring effective interactions between providers and patients in hospitals, clinics, and private practices.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal Services: Offering court-certified interpreters for legal consultations, trials, and depositions.",
              links: [
                {
                  linkText: "Legal Services",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Education: Supporting schools, universities, and training programs with language solutions tailored to diverse audiences.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
          ]}
          additionalContent="From the heart of San Francisco to the surrounding Bay Area communities like Oakland, Berkeley, and Palo Alto, Trusted Interpreters delivers reliable interpretation services wherever you need them."
          imageUrl={PeopleImage1}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={SFBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Let’s Connect"
          description="Make language barriers a thing of the past. Contact Trusted Interpreters today to learn more about our expert interpretation services in San Francisco and how we can help you achieve clear and effective communication."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default SanFrancisco;
