import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import { Helmet } from "react-helmet";
// images
import SAImage from "../../../src/assets/images/adobe/SanAntonio.webp";
import SAImage1 from "../../../src/assets/images/adobe/SanAntonio1.webp";
import SAImage2 from "../../../src/assets/images/adobe/SanAntonio2.webp";
import SAImage3 from "../../../src/assets/images/adobe/government.webp";

const SanAntonio: React.FC = () => {
  const sanAntonioBenefits = [
    {
      icon: FaAward,
      title: "Certified Professionals",
      text: "Our interpreters are highly trained and certified, ensuring reliable and precise interpretation.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Awareness",
      text: "We prioritize cultural nuances to ensure your message resonates effectively.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in San Antonio, delivering consistent support wherever needed.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services San Antonio</title>
        <meta
          name="description"
          content="Professional interpretation services tailored to businesses and events in San Antonio."
        />
      </Helmet>

      <PageHeader
        title="Professional Interpretation Services in San Antonio"
        description="Trusted Interpreters offers expert interpretation services tailored to support the diverse linguistic needs of San Antonio’s growing industries and communities. Whether for healthcare, legal, corporate, or educational settings, our certified interpreters ensure clear and accurate communication in over 200 languages."
        imageUrl={SAImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Trusted Interpreters Stands Out in San Antonio"
          contentList={[
            {
              text: "Skilled Professionals: Our interpreters are fluent in over 200 languages and specialize in a variety of industries, including legal, medical, and business fields.",
              links: [
                {
                  linkText: "legal",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "medical",
                  link: "/expertise/medical-interpretation",
                },
                {
                  linkText: "business",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Customized Services: We tailor our interpretation solutions to meet the specific needs of your event or organization.",
            },
            {
              text: "Reliable Coverage: From downtown San Antonio to surrounding areas, we provide services wherever and whenever you need them.",
            },
          ]}
          imageUrl={SAImage1}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Comprehensive Interpretation Services"
          contentList={[
            {
              text: "On-Site Interpretation: Perfect for face-to-face meetings, legal proceedings, and conferences. Our interpreters travel to your location to deliver accurate, real-time language support.",
              links: [
                {
                  linkText: "On-Site Interpretation",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "legal proceedings",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "conferences",
                  link: "/interpretation-types/on-site/simultaneous",
                },
              ],
            },
            {
              text: "Phone Interpretation: Connect instantly with an interpreter for urgent or remote language needs. Available 24/7 with no scheduling required.",
              links: [
                {
                  linkText: "Phone Interpretation",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Video Remote Interpretation (VRI): Ideal for virtual meetings, webinars, and hybrid events. Ensures smooth communication across digital platforms.",
              links: [
                {
                  linkText: "Video Remote Interpretation (VRI)",
                  link: "/interpretation-types/vri",
                },
              ],
            },
          ]}
          imageUrl={SAImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks />

        <ContentSection
          title="Industries We Serve in San Antonio"
          content="Our interpreters are experienced in San Antonio’s key industries, including:"
          contentList={[
            {
              text: "Healthcare: Facilitating effective communication between patients and providers in hospitals, clinics, and private practices.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal: Providing court-certified interpreters for depositions, trials, and legal consultations.",
              links: [
                {
                  linkText: "Legal",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Government: Facilitating communication for global events, diplomatic meetings, and international collaborations.",
              links: [
                {
                  linkText: "Government",
                  link: "/expertise/government-interpretation",
                },
              ],
            },
            {
              text: "Corporate: Supporting international business negotiations, team collaborations, and conferences.",
              links: [
                {
                  linkText: "Corporate",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
          ]}
          additionalContent="From the Alamo Heights to Stone Oak and all across the San Antonio metro area, Trusted Interpreters is your reliable partner for professional interpretation services."
          imageUrl={SAImage3}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={sanAntonioBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Trusted Interpreters Today"
          description="Overcome language barriers with ease. Contact Trusted Interpreters to learn more about our expert interpretation services in San Antonio and how we can help support your goals."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default SanAntonio;
