import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import CallToAction from "../../../components/CallToAction";
import { FaArrowsAlt, FaMapMarkedAlt } from "react-icons/fa";
import BusinessImage from "../../../../src/assets/images/vectors/business.png";
import BusinessImage1 from "../../../../src/assets/images/adobe/SanAntonio1.webp";
import OnsiteImage1 from "../../../../src/assets/images/adobe/adobe26.webp";
import OnsiteImage2 from "../../../../src/assets/images/adobe/LA2.webp";
import { FaUserGraduate } from "react-icons/fa6";

const FinancialInterpretation: React.FC = () => {
  const businessBenefits = [
    {
      icon: FaUserGraduate,
      title: "Certified Interpreters",
      text: "Professionals with expertise in financial language and industry-specific terminology.",
    },
    {
      icon: FaArrowsAlt,
      title: "Confidentiality Assured",
      text: "Strict adherence to non-disclosure agreements and data security protocols.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Tailored Solutions",
      text: "Customized services designed to meet the unique needs of your financial projects.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <PageHeader
        title="Professional Financial Interpretation Services"
        description="Trusted Interpreters provides expert financial interpretation services, ensuring accurate and culturally appropriate communication in the complex world of finance. From boardroom discussions to global investor meetings, our certified interpreters bridge language gaps to support successful collaborations and informed decision-making."
        imageUrl={BusinessImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Our Expertise in Financial Interpretation"
          content="Trusted Interpreters delivers unmatched value with:"
          contentList={[
            {
              text: "Specialized Knowledge: Interpreters experienced in financial terminology, market trends, and regulations.",
            },
            {
              text: "Accurate Communication: Ensuring clarity in technical jargon, figures, and culturally sensitive concepts.",
            },
            {
              text: "Flexible Services: Available for in-person, over-the-phone, and video remote interpretation to fit your needs.",
            },
          ]}
          imageUrl={OnsiteImage1}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Applications of Financial Interpretation Services"
          content="We support financial institutions, corporate clients, and individual stakeholders in a variety of settings, including:"
          contentList={[
            {
              text: "Investor Relations: Facilitating clear communication during earnings calls, shareholder meetings, and investor pitches.",
            },
            {
              text: "Banking and Finance: Supporting international transactions, loan negotiations, and compliance discussions.",
            },
            {
              text: "Insurance: Ensuring precise communication in policy reviews, claims processing, and customer consultations.",
            },
            {
              text: "Accounting and Auditing: Bridging language gaps in financial audits, tax discussions, and compliance checks.",
            },
            {
              text: "Wealth Management: Helping financial advisors connect with clients from diverse cultural and linguistic backgrounds.",
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <ContentSection
          title="Nationwide and International Availability"
          content="With a network of certified interpreters fluent in over 200 languages, Trusted Interpreters offers financial interpretation services across the U.S. and globally. Whether you’re hosting a conference, conducting virtual meetings, or managing one-on-one consultations, we’re here to support you."
          imageUrl={BusinessImage1}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Our Financial Interpretation Services?">
          <KeyBenefits benefits={businessBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Us Today"
          description="Elevate your business interactions with professional interpretation services. Contact Trusted Interpreters to learn more about how we can support your financial communication needs."
          buttonText="Contact Us"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default FinancialInterpretation;