import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import OnsiteImage2 from "../../../src/assets/images/adobe/onsite-img-2.webp";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import Helmet from "react-helmet";
// images
import SpanishImage from "../../../src/assets/images/adobe/spanish-img.webp";
import PeopleImage from "../../../src/assets/images/adobe/adobe7.webp";
import seattle from "../../../src/assets/images/adobe/seattle.webp";
import seattle1 from "../../../src/assets/images/adobe/seattle1.webp";
import seattle2 from "../../../src/assets/images/adobe/seattle2.webp";

const Seattle: React.FC = () => {
  const seattleBenefits = [
    {
      icon: FaAward,
      title: "Experienced Professionals",
      text: "We ensure accurate communication while respecting cultural nuances.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Understanding",
      text: "Our interpreters are certified, trained, and fluent in over 200 languages.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in Seattle, delivering consistent support wherever needed.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services Seattle</title>
        <meta
          name="description"
          content="Accurate interpretation services tailored to businesses in Seattle."
        />
      </Helmet>
      <PageHeader
        title="Professional Interpretation Services in Seattle for Every Industry"
        description="Trusted Interpreters provides expert language solutions to support Seattle’s dynamic industries and culturally diverse communities. From healthcare providers to corporate teams, our tailored interpretation services ensure clear and effective communication across all settings."
        imageUrl={seattle}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Choose Trusted Interpreters in Seattle?"
          contentList={[
            {
              text: "Experienced Professionals: Our interpreters are fluent in over 200 languages and specialize in industry-specific terminology to ensure accurate communication.",
            },
            {
              text: "Customized Solutions: We adapt our services to fit your specific needs, whether for one-time events or ongoing support.",
              links: [
                {
                  linkText: "one-time events",
                  link: "/interpretation-types/phone-interpretation/one-time-service",
                },
                {
                  linkText: "ongoing support",
                  link: "/interpretation-types/phone-interpretation/monthly-recurrent-service",
                },
              ],
            },
            {
              text: "Local Expertise: With a deep understanding of Seattle’s diverse communities and industries, we deliver culturally sensitive and precise communication.",
            },
          ]}
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />

        
        <ContentSection
          title="Comprehensive Interpretation Services"
          contentList={[
            {
              text: "On-Site Support for Face-to-Face Communication: Our interpreters provide in-person language assistance for legal hearings, corporate meetings, medical consultations, and conferences, ensuring effective communication in real-time.",
              links: [
                {
                  linkText: "On-Site Support for Face-to-Face Communication",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "corporate meetings",
                  link: "/expertise/business-interpretation",
                },
                {
                  linkText: "legal hearings",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "medical consultations",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "On-Demand Phone Interpretation: For urgent language needs, our phone interpretation services offer 24/7 access to certified linguists, enabling quick and reliable communication.",
              links: [
                {
                  linkText: "On-Demand Phone Interpretation",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Seamless Virtual Solutions: Our video remote interpretation (VRI) services are perfect for webinars, virtual meetings, and hybrid events, providing real-time multilingual support for digital environments.",
              links: [
                {
                  linkText: "Seamless Virtual Solutions",
                  link: "/interpretation-types/vri",
                },
                {
                  linkText: "video remote interpretation (VRI)",
                  link: "/interpretation-types/vri",
                },
                {
                  linkText: "virtual meetings",
                  link: "/interpretation-types/vri/skype-interpretation-services",
                },
              ],
            },
          ]}
          imageUrl={seattle1}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks/>

        <ContentSection
          title="Industries We Serve in Seattle"
          contentList={[
            {
              text: "Technology: Supporting global collaborations in Seattle’s booming tech sector.",
              links: [
                {
                  linkText: "Technology",
                  link: "/expertise/technology-interpretation",
                },
              ],
            },
            {
              text: "Healthcare: Bridging communication gaps between patients and providers in hospitals, clinics, and private practices.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal Services: Offering court-certified interpreters for depositions, trials, and legal consultations.",
              links: [
                {
                  linkText: "Legal Services",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Education: Enhancing communication in schools, universities, and training programs.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
          ]}
          additionalContent="From Downtown Seattle to neighborhoods like Capitol Hill, Ballard, and Fremont, Trusted Interpreters delivers professional language solutions across the city and the surrounding Puget Sound area."
          imageUrl={seattle2}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={seattleBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Trusted Interpreters Today"
          description="Don’t let language barriers hinder your success. Contact Trusted Interpreters today to learn more about our expert interpretation services in Seattle and how we can support your communication needs."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Seattle;
