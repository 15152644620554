import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import OnsiteImage2 from "../../../src/assets/images/adobe/LA1.webp";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import Helmet from "react-helmet";
// images
import MiamiImage from "../../../src/assets/images/adobe/miami2.webp";
import PeopleImage from "../../../src/assets/images/adobe/SanAntonio1.webp";
import PeopleImage1 from "../../../src/assets/images/adobe/miami.webp";

const Miami: React.FC = () => {
  const miamiBenefits = [
    {
      icon: FaAward,
      title: "Experienced Professionals",
      text: "We ensure accurate communication while respecting cultural nuances.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Understanding",
      text: "Our interpreters are certified, trained, and fluent in over 200 languages.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in Miami, delivering consistent support wherever needed.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services Miami</title>
        <meta
          name="description"
          content="Trusted Interpreters offers certified interpretation services in Miami for all industries. Call us today!"
        />
      </Helmet>
      <PageHeader
        title="Interpretation Services in Miami"
        description="Trusted Interpreters proudly offers expert interpretation services throughout Miami, providing reliable communication solutions tailored to your needs. Whether you’re in the healthcare, legal, business, or education sector, our certified interpreters ensure accurate and culturally sensitive communication in over 200 languages."
        imageUrl={MiamiImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Comprehensive Interpretation Solutions"
          content="Our services are designed to meet the unique demands of Miami’s vibrant and multilingual community:"
          contentList={[
            {
              text: "On-Site Interpretation: Ideal for in-person meetings, legal proceedings, and corporate events in Miami.",
              links: [
                {
                  linkText: "On-Site Interpretation",
                  link: "/interpretation-types/on-site",
                },
              ],
            },
            {
              text: "Video Remote Interpretation (VRI): Real-time interpretation for virtual meetings, telehealth, and webinars.",
              links: [
                {
                  linkText: "Video Remote Interpretation (VRI)",
                  link: "/interpretation-types/vri",
                },
              ],
            },
            {
              text: "Over-the-Phone Interpretation (OPI): Flexible and instant language support accessible anytime, anywhere.",
              links: [
                {
                  linkText: "Over-the-Phone Interpretation (OPI)",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
          ]}
          additionalContent="With our extensive experience, we provide customized solutions that fit the diverse needs of Miami businesses and individuals."
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
        <ProcessWorkflow process="presencial" />

        <ContentSection
          title="Industries We Serve in Miami"
          content="Trusted Interpreters supports a wide range of industries, offering specialized expertise in:"
          contentList={[
            {
              text: "Legal: Interpretation for court hearings, depositions, and attorney-client consultations.",
              links: [
                {
                  linkText: "Legal",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Healthcare: Bridging communication gaps between patients and providers during appointments and treatments.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Business: Facilitating international negotiations, conferences, and team meetings.",
              links: [
                {
                  linkText: "Business",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Education: Supporting schools, universities, and parent-teacher interactions.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
            {
              text: "Government: Providing interpretation for public services, community programs, and policy discussions.",
              links: [
                {
                  linkText: "Government",
                  link: "/expertise/government-interpretation",
                },
              ],
            },
          ]}
          imageUrl={PeopleImage1}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks />

        <ContentSection
          title="Connect with Miami’s Leading Interpretation Services"
          content="Miami is a global hub for business, healthcare, and education, making effective communication essential. Trusted Interpreters is committed to serving the city’s diverse population with unparalleled interpretation services. Whether you’re hosting a conference, attending a court hearing, or managing a medical appointment, we are here to help."
          imageUrl={OnsiteImage2}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={miamiBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Let’s Bridge the Language Gap"
          description="Discover how Trusted Interpreters can enhance communication in Miami. Contact us today to schedule an interpreter and ensure seamless interactions in any language."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Miami;
