import React from "react";
import TestimonialsSection from "../../../components/TestimonialsSection";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import PricingPlans from "../../../components/PricingPlans";
import QuoteForm from "../../../components/QuoteForm";
import { FaComments, FaHeadphones, FaMicroscope, FaVideo } from "react-icons/fa6";
import { FaGlobe, FaCheck, FaCertificate, FaIndustry } from "react-icons/fa";
import Helmet from "react-helmet";
// images
import PeopleImage from "../../../../src/assets/images/adobe/adobe17.webp";
import WashingtonImage from "../../../../src/assets/images/adobe/Washington.webp";
import LandingBanner from "../../../components/LandingBanner";
import ClientsCarousel from "../../../components/ClientsCarousel";
import SubFooter from "../../../components/SubFooter";

const LandingOSIWashington: React.FC = () => {
  const pricingPlans = [
    {
      icon: FaComments,
      title: "Consecutive",
      price: 99,
      description: "Professional consecutive interpretation for formal events.",
      benefits: [
        "Clear and precise communication",
        "Smooth small-group communication",
        "Strict confidentiality agreements",
      ],
      url: "#quote-form",
    },
    {
      icon: FaMicroscope,
      title: "Simultaneous",
      price: 99,
      description: "Real-time interpretation for live conferences.",
      benefits: [
        "Clear interpretation for large events",
        "Instant interpretation for live events",
        "Real-time accuracy for global meetings",
      ],
      url: "#quote-form",
    },
    {
      icon: FaHeadphones,
      title: "Equipment",
      price: 99,
      description: "Rental equipment for interpretation needs.",
      benefits: [
        "Wireless Headphones and Headsets",
        "Soundproof Booths",
        "Interpreter Consoles",
        "Tour-Guide Systems",
        "Transmitters and Receivers",
      ],
      url: "#quote-form",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services Washington DC</title>
        <meta
          name="description"
          content="Trusted Interpreters offers certified interpretation services in Washington for all industries. Call us today!"
        />
      </Helmet>
      <PageHeader
        title="On-Site Interpretation Services in Washington"
        description="Trusted Interpreters provides professional on-site language interpretation services in Washington, specializing in both consecutive and simultaneous interpretation. Our experienced interpreters utilize cutting-edge technology to ensure clear and accurate communication in any setting, from corporate meetings to government conferences. No matter your interpretation needs in Washington, we deliver customized solutions for seamless multilingual communication."
        imageUrl={WashingtonImage}
      />
      <div className="container mx-auto px-8">
        <ClientsCarousel/>
      </div>
      <h2 className="text-3xl font-bold text-center text-gray-800 mb-4 mt-2 px-2">Interpretation Services Starting From $56</h2>
      <div className="container mx-auto px-4 pb-16">
      <div className="flex flex-col lg:flex-row w-full">
          <div className="w-80 py-8 lg:py-12 mx-auto order-1 lg:order-none">
          <h2 className="text-2xl font-bold mb-4 mt-2">Leading Interpretation Services Company</h2>
          <ul className="pl-0 text-gray-600 gap-3 flex flex-col">
            <li className="flex items-start gap-3">
              <FaGlobe className="text-[#025F99] mt-1 w-[40px]" />
              <span>Trusted by over 10,000 businesses worldwide</span>
            </li>
            <li className="flex items-start gap-3">
              <FaCheck className="text-[#025F99] mt-1 w-[40px]" />
              <span>Backed by 20+ years of industry experience</span>
            </li>
            <li className="flex items-start gap-3">
              <FaVideo className="text-[#025F99] mt-1 w-[40px]" />
              <span>Professional Video Remote Interpretation (VRI) services</span>
            </li>
            <li className="flex items-start gap-3">
              <FaCertificate className="text-[#025F99] mt-1 w-[40px]" />
              <span>Certified interpreters for accurate and reliable communication</span>
            </li>
            <li className="flex items-start gap-3">
              <FaIndustry className="text-[#025F99] mt-1 w-[40px]" />
              <span>Available on-demand across multiple industries and settings</span>
            </li>
          </ul>
          
          </div>
          <div id="quote-form" className="w-full">
            <QuoteForm />
          </div>
        </div>
        
        <PricingPlans
          title=""
          plans={pricingPlans}
          subtitleClassName="text-3xl font-bold text-center text-gray-800 mb-4 mt-2"
        />

        <ContentSection
          title="On-Site Interpreters in Washington"
          content="Our on-site interpreters in Washington undergo rigorous training to meet the highest industry standards. Fluent in over 200 languages and dialects, they bring expertise across diverse industries, including legal, medical, and corporate sectors. Trusted Interpreters guarantees the right language expert for any subject matter, ensuring precision and professionalism in every interaction."
          contentLinks={[
            { linkText: "200 languages and dialects", link: "/language-expertise/" },
            { linkText: "diverse industries", link: "/expertise/" },
            { linkText: "legal", link: "/expertise/legal-interpretation" },
            { linkText: "medical", link: "/expertise/medical-interpretation" },
            { linkText: "corporate sectors", link: "/expertise/business-interpretation" },
          ]}                    
          imageUrl={PeopleImage}
          direction="right"
        />

      </div>

      <LandingBanner />

      {/* testimonials */}
      <TestimonialsSection />

      <SubFooter />
    </div>
  );
};

export default LandingOSIWashington;
