import React from "react";
import TestimonialsSection from "../../../components/TestimonialsSection";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import PricingPlans from "../../../components/PricingPlans";
import QuoteForm from "../../../components/QuoteForm";
import { Video, Monitor, MessageSquare } from "lucide-react";
import { FaVideo } from "react-icons/fa6";
import { FaGlobe, FaCheck, FaCertificate, FaIndustry } from "react-icons/fa";
import Helmet from "react-helmet";
// images
import PeopleImage from "../../../../src/assets/images/adobe/adobe25.webp";
import MiamiImage1 from "../../../../src/assets/images/adobe/meeting.webp";
import LandingBanner from "../../../components/LandingBanner";
import ClientsCarousel from "../../../components/ClientsCarousel";
import SubFooter from "../../../components/SubFooter";

const LandingVRI: React.FC = () => {
  const pricingPlans = [
    {
      icon: Video,
      title: "Zoom",
      price: 56,
      description: "Zoom’s interpretation feature enables real-time translation during virtual meetings.",
      benefits: [
        "Real-Time Accuracy",
        "Global Reach",
        "Seamless Integration",
      ],
      url: "#quote-form",
    },
    {
      icon: Monitor,
      title: "Google Meet",
      price: 56,
      description: "Real-time interpretation for live conferences.",
      benefits: [
        "Global Accessibility",
        "User-Friendly Integration",
        "Real-Time Communication",
      ],
      url: "#quote-form",
    },
    {
      icon: MessageSquare,
      title: "Microsoft Teams",
      price: 56,
      description: "Trusted Interpreters offers Microsoft Teams interpretation services across the U.S. and internationally.",
      benefits: [
        "Enterprise-Level Integration",
        "Streamlined Workflows",
        "Scalable Options",
      ],
      url: "#quote-form",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services Miami</title>
        <meta
          name="description"
          content="Trusted Interpreters offers certified interpretation services in Miami for all industries. Call us today!"
        />
      </Helmet>
      <PageHeader
        title="From $56 Per Hour"
        description="Bridge language barriers instantly with our high-quality Video Remote Interpretation (VRI) services. Connect with certified interpreters in real time, whether for spoken languages or American Sign Language (ASL). Accessible, secure, and efficient—our VRI solutions ensure seamless communication anytime, anywhere."
        imageUrl={MiamiImage1}
      />
      <div className="container mx-auto px-8">
        <ClientsCarousel/>
      </div>
      <h2 className="text-3xl font-bold text-center text-gray-800 mb-4 mt-2 px-2">Interpretation Services Starting From $56</h2>
      <div className="container mx-auto px-4 pb-16">
      <div className="flex flex-col lg:flex-row w-full">
          <div className="w-80 py-8 lg:py-12 mx-auto order-1 lg:order-none">
          <h2 className="text-2xl font-bold mb-4 mt-2">Leading Interpretation Services Company</h2>
          <ul className="pl-0 text-gray-600 gap-3 flex flex-col">
            <li className="flex items-start gap-3">
              <FaGlobe className="text-[#025F99] mt-1 w-[40px]" />
              <span>Trusted by over 10,000 businesses worldwide</span>
            </li>
            <li className="flex items-start gap-3">
              <FaCheck className="text-[#025F99] mt-1 w-[40px]" />
              <span>Backed by 20+ years of industry experience</span>
            </li>
            <li className="flex items-start gap-3">
              <FaVideo className="text-[#025F99] mt-1 w-[40px]" />
              <span>Professional Video Remote Interpretation (VRI) services</span>
            </li>
            <li className="flex items-start gap-3">
              <FaCertificate className="text-[#025F99] mt-1 w-[40px]" />
              <span>Certified interpreters for accurate and reliable communication</span>
            </li>
            <li className="flex items-start gap-3">
              <FaIndustry className="text-[#025F99] mt-1 w-[40px]" />
              <span>Available on-demand across multiple industries and settings</span>
            </li>
          </ul>
          
          </div>
          <div id="quote-form" className="w-full">
            <QuoteForm />
          </div>
        </div>

        <PricingPlans
          title=""
          plans={pricingPlans}
          subtitleClassName="text-3xl font-bold text-center text-gray-800 mb-4 mt-2"
        />

        <ContentSection
          title="We work with the most popular platforms"
          content="Our team of video remote interpreters meets the highest industry standards through rigorous training and extensive hands-on experience. At Trusted Interpreters, we collaborate with experts in over 200 languages, ensuring precision and cultural accuracy in every interpretation. Whether in legal, government, or business meetings, we provide the right linguist to meet your specific needs.
                    We work with the most popular platforms, including Zoom, Google Meet, Microsoft Teams, Skype, and others, ensuring seamless communication for all your remote interpretation requirements."
          contentLinks={[
            { linkText: "200 languages", link: "/language-expertise/" },
            { linkText: "legal", link: "/expertise/legal-interpretation" },
            { linkText: "government", link: "/expertise/government-interpretation" },
            { linkText: "Zoom", link: "/interpretation-types/vri/zoom-interpretation-services" },
            { linkText: "Google Meet", link: "/interpretation-types/vri/google-meet-interpretation-services" },
            { linkText: "Microsoft Teams", link: "/interpretation-types/vri/microsoft-teams-interpretation-services" },
            { linkText: "Skype", link: "/interpretation-types/vri/skype-interpretation-services" },
            { linkText: "others", link: "/interpretation-types/vri/other-interpretation-services" },
          ]}
          imageUrl={PeopleImage}
          direction="right"
        />


      </div>

      <LandingBanner />

      {/* testimonials */}
      <TestimonialsSection />

      <SubFooter />
    </div>
  );
};

export default LandingVRI;
