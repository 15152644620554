import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Link } from "react-router-dom";

import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import markerRetina from "leaflet/dist/images/marker-icon-2x.png";

const DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconRetinaUrl: markerRetina,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const cities: { name: string; coordinates: [number, number] }[] = [
  { name: "New York", coordinates: [40.7128, -74.006] },
  { name: "Los Angeles", coordinates: [34.0522, -118.2437] },
  { name: "Chicago", coordinates: [41.8781, -87.6298] },
  { name: "Houston", coordinates: [29.7604, -95.3698] },
  { name: "Phoenix", coordinates: [33.4484, -112.074] },
  { name: "San Antonio", coordinates: [29.4241, -98.4936] },
  { name: "Philadelphia", coordinates: [39.9526, -75.1652] },
  { name: "San Diego", coordinates: [32.7157, -117.1611] },
  { name: "Dallas", coordinates: [32.7767, -96.797] },
  { name: "San Jose", coordinates: [37.3382, -121.8863] },
  { name: "Austin", coordinates: [30.2672, -97.7431] },
  { name: "Charlotte", coordinates: [35.2271, -80.8431] },
  { name: "Fort Worth", coordinates: [32.7555, -97.3308] },
  { name: "San Francisco", coordinates: [37.7749, -122.4194] },
  { name: "Columbus", coordinates: [39.9612, -82.9988] },
  { name: "Jacksonville", coordinates: [30.3322, -81.6557] },
  { name: "Indianapolis", coordinates: [39.7684, -86.1581] },
  { name: "Boston", coordinates: [42.3601, -71.0589] },
  { name: "Nashville", coordinates: [36.1627, -86.7816] },
  { name: "Oklahoma City", coordinates: [35.4676, -97.5164] },
  { name: "Portland", coordinates: [45.5051, -122.675] },
  { name: "Denver", coordinates: [39.7392, -104.9903] },
  { name: "Seattle", coordinates: [47.6062, -122.3321] },
  { name: "Washington", coordinates: [38.9072, -77.0369] },
  { name: "El Paso", coordinates: [31.7619, -106.485] },
  { name: "Miami", coordinates: [25.7740, -80.1950] },
];

const InteractiveMap: React.FC = () => {
  const bounds: [[number, number], [number, number]] = [
    [26.396308, -125.0],
    [49.384358, -66.93457],
  ];

  const cityToUrl = (name: string) => {
    return name
      .toLowerCase()
      .replace(/[\s.,]+/g, "-")
      .replace(/-+$/, "");
  };

  const getEmailHref = (city: string) => {
    const subject = encodeURIComponent(`${city} - Request`);
    return `mailto:sales@trustedinterpreters.com?subject=${subject}`;
  };

  const [zoom, setZoom] = useState<number>(window.innerWidth < 1024 ? 3 : 4);

  useEffect(() => {
    const updateZoom = () => {
      setZoom(window.innerWidth < 1024 ? 3 : 4);
    };

    updateZoom();

    window.addEventListener("resize", updateZoom);
    return () => window.removeEventListener("resize", updateZoom);
  }, []);


  return (
    <div className="px-2">
      <div className="relative w-full h-[400px] mt-2 mb-10">
        <h2 className="text-center text-3xl font-bold mb-4 text-gray-800">
          Cities We Serve
        </h2>
        <MapContainer
          center={[37.8, -96]}
          zoom={zoom}
          scrollWheelZoom={true}
          dragging={true}
          touchZoom={true}
          doubleClickZoom={false}
          // maxBounds={bounds}
          maxBoundsViscosity={1.0}
          attributionControl={false}
          className="h-full w-full rounded-lg shadow-lg"
          style={{ zIndex: 1 }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {cities.map(({ name, coordinates }) => (
            <Marker key={name} position={coordinates}>
              <Popup>
                <h3 className="text-lg font-bold">{name}</h3>
                <p className="text-sm mb-2">
                  <Link
                    to={`/interpretation-services/${cityToUrl(name)}`}
                    className="text-blue-600 underline hover:text-blue-800"
                  >
                    Interpretation Services in {name}
                  </Link>
                </p>
                <p className="text-sm">
                  <strong>Phone:</strong>{" "}
                  <a href="tel:+18884442554" className="text-blue-600 underline">
                    +1 (888) 444-2554
                  </a>
                </p>
                <p className="text-sm">
                  <strong>Email:</strong>{" "}
                  <a
                    href={getEmailHref(name)}
                    className="text-blue-600 underline"
                  >
                    sales@trustedinterpreters.com
                  </a>
                </p>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
    </div>
  );
};

export default InteractiveMap;
