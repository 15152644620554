"use client"

import React from "react"
import { useLocation } from "react-router-dom"

const postalCodes: { [key: string]: string } = {
  "new-york": "10001",
  "los-angeles": "90001",
  chicago: "60601",
  houston: "77001",
  phoenix: "85001",
  "san-antonio": "78201",
  philadelphia: "19101",
  "san-diego": "92101",
  dallas: "75201",
  "san-jose": "95101",
  austin: "73301",
  charlotte: "28201",
  "fort-worth": "76101",
  "san-francisco": "94101",
  columbus: "43201",
  jacksonville: "32201",
  indianapolis: "46201",
  boston: "02101",
  nashville: "37201",
  "oklahoma-city": "73101",
  portland: "97201",
  denver: "80201",
  seattle: "98101",
  washington: "20001",
  "el-paso": "79901",
}

const SchemaMarkup: React.FC = () => {
  const location = useLocation()
  const pathname = location.pathname.replace(/\/$/, "")

  // Debug log to see the actual pathname
  console.log("Current pathname:", pathname)

  const websiteSchema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: "Trusted Interpreters - Professional Interpretation Services",
    url: window.location.origin,
  }
  
  const breadcrumbsSchema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: `${window.location.origin}/`,
      },
      ...pathname
        .split("/")
        .filter(Boolean)
        .map((segment, index, arr) => ({
          "@type": "ListItem",
          position: index + 2,
          name: segment.replace(/-/g, " ").replace(/\b\w/g, (l) => l.toUpperCase()),
          item: `${window.location.origin}/${arr.slice(0, index + 1).join("/")}`,
        })),
    ],
  }
  
  let pageSchema: Record<string, any> | null = null
  
    if (pathname.startsWith("/interpretation-services/") || pathname.includes("/interpretation-services/")) {
    const segments = pathname.split("/")
    const cityIndex = segments.findIndex((segment) => segment === "interpretation-services") + 1
    const city = cityIndex < segments.length ? segments[cityIndex] : ""
    const postalCode = postalCodes[city] || "00000"

    pageSchema = {
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      name: `Trusted Interpreters - ${city.replace(/-/g, " ").replace(/\b\w/g, (l) => l.toUpperCase())}`,
      image: "https://www.trustedinterpreters.com/static/media/logo_ti.093360928a109ef4474b.jpg",
      address: {
        "@type": "PostalAddress",
        streetAddress: "123 Main St",
        addressLocality: city.replace(/-/g, " ").replace(/\b\w/g, (l) => l.toUpperCase()),
        addressRegion: "US",
        postalCode: postalCode,
        addressCountry: "US",
      },
      telephone: "+1-888-444-2554",
    }
  } else if (pathname.startsWith("/interpretation-types/") || pathname.includes("/interpretation-types/")) {
    pageSchema = {
      "@context": "https://schema.org",
      "@type": "Product",
      name: "Professional Interpretation Services",
      image: "https://www.trustedinterpreters.com/static/media/logo_ti.093360928a109ef4474b.jpg",
      description: "We offer high-quality on-site, over-the-phone, and video remote interpretation services.",
      brand: "Trusted Interpreters",
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: "4.8",
        reviewCount: "256",
      },
    }
  } else if (pathname.startsWith("/blog/") || pathname.includes("/blog/")) {
    const pageTitle = document.title || "Blog Post"

    pageSchema = {
      "@context": "https://schema.org",
      "@type": "Article",
      headline: pageTitle,
      author: {
        "@type": "Person",
        name: "Craig Bennet",
      },
      publisher: {
        "@type": "Organization",
        name: "Trusted Interpreters",
        logo: {
          "@type": "ImageObject",
          url: "https://www.trustedinterpreters.com/static/media/logo_ti.093360928a109ef4474b.jpg",
        },
      },
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": window.location.href,
      },
    }
  }

  const schemaDataArray = React.useMemo(() => {
    return [websiteSchema, breadcrumbsSchema, pageSchema].filter(Boolean)
  }, [pathname])

  return (
    <>
      {schemaDataArray.map((schema, index) => (
        <script key={index} type="application/ld+json">
          {JSON.stringify(schema)}
        </script>
      ))}
    </>
  )
}

export default SchemaMarkup

