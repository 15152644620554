import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import client from '../../services/contentfulClient';
import Helmet from "react-helmet";
import Footer from '../../components/Footer';

// Helper function to format dates
const formatDate = (dateString: string): string => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', { 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric' 
  }).format(date);
};

const Blog: React.FC = () => {
  const [posts, setPosts] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [featuredPost, setFeaturedPost] = useState<any>(null);

  useEffect(() => {
    setIsLoading(true);
    client
      .getEntries({ 
        content_type: 'blogPost',
        order: '-sys.createdAt' as any, // Order by creation date (newest first)
        limit: 100 
      })
      .then((response) => {
        if (response.items.length > 0) {
          // Set the most recent post as the featured post
          setFeaturedPost(response.items[0]);
          // Set the rest of the posts
          setPosts(response.items.slice(1));
        } else {
          setPosts([]);
        }
        setIsLoading(false);
      })
      .catch(error => {
        console.error(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 py-12">
        <div className="container mx-auto px-4">
          <div className="animate-pulse flex flex-col items-center">
            <div className="h-10 w-64 bg-gray-200 rounded mb-12"></div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full">
              {[...Array(6)].map((_, index) => (
                <div key={index} className="bg-white rounded-lg shadow-sm overflow-hidden">
                  <div className="h-48 bg-gray-200"></div>
                  <div className="p-6">
                    <div className="h-6 bg-gray-200 rounded mb-4"></div>
                    <div className="h-4 bg-gray-200 rounded mb-2"></div>
                    <div className="h-4 bg-gray-200 rounded mb-2"></div>
                    <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>Trusted Interpreters Blog</title>
        <meta
          name="description"
          content="Discover industry updates and professional tips about interpretation services."
        />
      </Helmet>
      
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-5xl font-bold text-center mb-8 text-gray-800">Our Blog</h1>

        {/* Featured Post */}
        {featuredPost && (
          <div className="mb-12">
            <h2 className="text-2xl font-bold mb-4 text-gray-800 border-b pb-2">Latest Article</h2>
            <div className="bg-white rounded-xl shadow-sm overflow-hidden transition-all duration-300 hover:shadow-md">
              <div className="md:flex">
                <div className="md:w-1/2">
                  {featuredPost.fields.media?.fields?.file?.url ? (
                    <img
                      src={featuredPost.fields.media.fields.file.url}
                      alt={featuredPost.fields.title}
                      className="w-full h-full object-cover max-h-[400px]"
                    />
                  ) : (
                    <div className="bg-gradient-to-r from-[#025f99] to-[#014a73] h-full flex items-center justify-center p-12">
                      <span className="text-white text-2xl font-bold">Trusted Interpreters</span>
                    </div>
                  )}
                </div>
                <div className="md:w-1/2 p-6 md:p-8 flex flex-col">
                  <div className="flex items-center text-sm text-gray-500 mb-3">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                    <span>{formatDate(featuredPost.sys.createdAt)}</span>
                  </div>
                  <h3 className="text-2xl md:text-3xl font-bold mb-3 hover:text-[#025f99] transition-colors">
                    {featuredPost.fields.title}
                  </h3>
                  <p className="text-gray-600 mb-4 flex-grow">
                    {(() => {
                      // Get a longer summary for the featured post
                      const content = featuredPost.fields.body?.content || [];
                      let summary = '';
                      let wordCount = 0;
                      
                      // Loop through paragraphs to get more content
                      for (let i = 0; i < content.length && wordCount < 50; i++) {
                        const paragraph = content[i];
                        if (paragraph.content && paragraph.content.length) {
                          for (let j = 0; j < paragraph.content.length; j++) {
                            if (paragraph.content[j].value) {
                              summary += paragraph.content[j].value + ' ';
                              wordCount += paragraph.content[j].value.split(' ').length;
                              if (wordCount >= 50) break;
                            }
                          }
                        }
                      }
                      
                      // If we couldn't get enough content, use the fallback
                      if (wordCount < 15 && featuredPost.fields.body?.content[0]?.content[0]?.value) {
                        summary = featuredPost.fields.body.content[0].content[0].value.substring(0, 300);
                      }
                      
                      return summary.trim() + '...';
                    })()}
                  </p>
                  <Link 
                    to={`/blog/${featuredPost.fields.slug}`} 
                    className="inline-flex items-center bg-[#025f99] hover:bg-[#014a73] text-white font-medium py-2 px-6 rounded-md transition-colors duration-200"
                  >
                    Read full article
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Rest of Posts */}
        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4 text-gray-800 border-b pb-2">All Articles</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {posts.map((post) => (
              <Link 
                to={`/blog/${post.fields.slug}`}
                key={post.sys.id} 
                className="bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-all duration-300 flex flex-col h-full"
              >
                <div className="h-48 overflow-hidden">
                  {post.fields.media?.fields?.file?.url ? (
                    <img
                      src={post.fields.media.fields.file.url}
                      alt={post.fields.title}
                      className="w-full h-full object-cover transition-transform duration-500 hover:scale-105"
                    />
                  ) : (
                    <div className="bg-gradient-to-r from-[#025f99] to-[#014a73] h-full flex items-center justify-center">
                      <span className="text-white text-xl font-bold">Trusted Interpreters</span>
                    </div>
                  )}
                </div>
                <div className="p-5 flex flex-col flex-grow">
                  <div className="flex items-center text-sm text-gray-500 mb-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                    <span>{formatDate(post.sys.createdAt)}</span>
                  </div>
                  <h3 className="text-xl font-bold mb-2 hover:text-[#025f99] transition-colors line-clamp-2">
                    {post.fields.title}
                  </h3>
                  <p className="text-gray-600 text-sm line-clamp-3 mb-2">
                    {post.fields.body?.content[0]?.content[0]?.value.substring(0, 150)}...
                  </p>
                </div>
                <div className="px-5 pb-3">
                  <span className="text-[#025f99] font-medium hover:underline inline-flex items-center">
                    Read more
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                  </span>
                </div>
              </Link>
            ))}
          </div>
        </div>

        {posts.length === 0 && !featuredPost && (
          <div className="text-center py-8">
            <p className="text-gray-600 text-lg">No blog posts available yet. Check back soon!</p>
          </div>
        )}
      </div>
      
      <Footer />
    </div>
  );
};

export default Blog;
