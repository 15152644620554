import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import SubsectionNavigator from "../../../components/SubsectionNavigator";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import CallToAction from "../../../components/CallToAction";
import { FaUserShield, FaClock, FaHandshake } from "react-icons/fa";
import { FaClipboardCheck } from "react-icons/fa";
import { FaMicroscope, FaHeadphones } from "react-icons/fa";
// import OnsiteImage from "../../../../src/assets/images/adobe/onsite-img.webp";
import OnsiteImage from "../../../../src/assets/images/vectors/on-site.png";
import OnsiteImage1 from "../../../../src/assets/images/adobe/onsite-img-1.webp";
import OnsiteImage2 from "../../../../src/assets/images/adobe/onsite-img-2.webp";
import Helmet from "react-helmet";

const OnSite: React.FC = () => {
  const onSiteBenefits = [
    {
      icon: FaUserShield,
      title: "Confidentiality Guaranteed",
      text: "All interpreters are bound by confidentiality agreements and follow strict interpreting codes of ethics.",
    },
    {
      icon: FaClock,
      title: "International Coverage",
      text: "Over 10,000 language experts available throughout the U.S. and abroad, ready to serve any location, language, or time.",
    },
    {
      icon: FaHandshake,
      title: "Sector Specialization",
      text: "Expert linguists specialized in legal, business, education, medical, technical, military, and other industries.",
    },
  ];

  const subsections = [
    {
      title: "Consecutive",
      description: "Professional consecutive interpretation for formal events.",
      icon: <FaClipboardCheck size={24} />,
      link: "/interpretation-types/on-site/consecutive",
    },
    {
      title: "Simultaneous",
      description: "Real-time interpretation for live conferences.",
      icon: <FaMicroscope size={24} />,
      link: "/interpretation-types/on-site/simultaneous",
    },
    {
      title: "Equipment",
      description: "Rental equipment for interpretation needs.",
      icon: <FaHeadphones size={24} />,
      link: "t",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>On-Site Interpretation in the US</title>
        <meta
          name="description"
          content="Trusted Interpreters offers professional and accurate services in the US."
        />
      </Helmet>
      <PageHeader
        title="On-Site Interpretation Services Across the US"
        description="At Trusted Interpreters, we offer professional on-site interpretation services nationwide, connecting you with expert linguists for seamless communication in any setting. From legal proceedings and medical appointments to business meetings and conferences, our interpreters are ready to assist you anywhere in the United States."
        imageUrl={OnsiteImage}
      />
      <div className="container mx-auto px-4 md:py-10">
        <ContentSection
          title="Nationwide Network of Industry-Specific Interpreters"
          content="Trusted Interpreters connects you with a nationwide network of highly qualified professionals fluent in over 200 languages and dialects. 
           We specialize in catering to diverse sectors, ensuring interpreters are well-versed in industry-specific terminology and cultural nuances. 
           Our services include:"
          contentList={[
            {
              text: "Legal settings, such as depositions and court hearings.",
              links: [
                { linkText: "Legal", link: "/expertise/legal-interpretation" },
              ],
            },
            {
              text: "Medical appointments, ensuring clear communication between patients and healthcare providers.",
              links: [
                {
                  linkText: "Medical",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Business environments, facilitating efficient discussions in meetings or negotiations.",
              links: [
                {
                  linkText: "Business",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Education settings, supporting learning and academic activities.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
            {
              text: "Technical fields, such as engineering and IT industries.",
              links: [
                {
                  linkText: "Technical fields",
                  link: "/expertise/technical-interpretation",
                },
              ],
            },
            {
              text: "Military and engineering sectors, ensuring mission-critical communication.",
              links: [
                {
                  linkText: "Military and engineering",
                  link: "/expertise/military-interpretation",
                },
              ],
            },
          ]}
          additionalContent="With our customized solutions, we guarantee accurate and effective communication tailored to your unique needs."
          imageUrl={OnsiteImage1}
          direction="right"
        />

        {/* Subsections */}
        <div className="container mx-auto px-6 py-6">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
            Explore On-Site Interpretation Services
          </h2>
          <SubsectionNavigator subsections={subsections} />
        </div>

        {/* Workflow */}
        <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Flexible Scheduling with Unmatched Professionalism"
          content="At Trusted Interpreters, we adapt to your on-site interpretation needs with flexible scheduling and the ability to travel to any location across the U.S. Our interpreters uphold the highest standards of professionalism, adhering to strict codes of ethics and confidentiality agreements. This ensures reliable, secure, and tailored services, wherever and whenever you need them."
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Our On-Site Interpretation?">
          <KeyBenefits benefits={onSiteBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Get Started with Trusted Interpreters"
          description="Ready to bridge language barriers? Contact us today to learn more about our on-site interpretation services and understand how we can assist efficient communication for your organization."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default OnSite;
