import React from "react";
import LandingImage from "../../src/assets/images/adobe/Houston1.webp";

const LandingBanner: React.FC = () => {
    return (
      <div className="relative w-full h-[400px] md:h-[400px] lg:h-[400px] mt-[-30px]">
        <div
            className="absolute inset-0 bg-cover bg-center"
            style={{ backgroundImage: `url(${LandingImage})` }}
        />
  
        <div className="absolute inset-0 bg-[#024772] opacity-75"></div>
  
        <div className="relative z-10 flex flex-col items-center justify-center text-white text-center px-6 h-full">
          <h1 className="text-2xl md:text-4xl font-semibold">Leading Interpretation Company</h1>
          <p className="mt-4 max-w-3xl text-sm md:text-lg">
            Trusted by over <strong>10,000 businesses</strong> and backed by <strong>20+ years</strong> of experience, we are a leading provider of on-site interpretation services. 
            Let <em>Trusted Interpreters</em> handle your interpretation needs. Get a free quote today.
          </p>
        </div>
      </div>
    );
  };
  
export default LandingBanner;