import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import CallToAction from "../../../components/CallToAction";
import ASLImage from "../../../../src/assets/images/vectors/asl1.png";
import OnsiteImage1 from "../../../../src/assets/images/adobe/ASL2.webp";
import OnsiteImage2 from "../../../../src/assets/images/adobe/ESL.webp";
import OnsiteImage3 from "../../../../src/assets/images/adobe/adobe10.webp";
import { FaHands, FaIndustry, FaPeopleArrows } from "react-icons/fa6";
import Helmet from "react-helmet";


const ASL: React.FC = () => {
  const ASLBenefits = [
    {
      icon: FaHands,
      title: "Highly Skilled Interpreters",
      text: "Our interpreters are trained to handle complex ASL interpretation, ensuring clear and accurate communication.",
    },
    {
      icon: FaIndustry,
      title: "Specialized Services",
      text: "We provide ASL interpretation for various industries, including legal, medical, educational, and corporate sectors.",
    },
    {
      icon: FaPeopleArrows,
      title: "Adaptable Solutions",
      text: "From one-on-one meetings to large conferences, our interpreters cater to events of all sizes.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>American Sign Language Services US</title>
        <meta
          name="description"
          content="Reliable American Sign Language interpretation services available across the U.S."
        />
      </Helmet>
      <PageHeader
        title="American Sign Language Interpretation Services Nationwide"
        description="Trusted Interpreters is proud to provide professional American Sign Language (ASL) interpretation services across the United States. ASL is the most widely used form of communication in the hearing-impaired community in the U.S., and our expert interpreters are here to support seamless communication in any setting."
        imageUrl={ASLImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="What is American Sign Language (ASL)?"
          content="ASL is a visual language expressed through hand signs, facial expressions, and body movements. It is distinct from spoken English, with its own unique grammar and syntax. ASL is widely used in the U.S., parts of Canada, and even some regions of Mexico."
          imageUrl={OnsiteImage1}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Why ASL Interpretation is Essential:"
          contentList={[
            {
              text: "Ensures accessibility for the deaf and hard-of-hearing community.",
            },
            {
              text: "Facilitates clear communication in professional, educational, and personal settings.",
            },
            {
              text: "Supports inclusivity at events, conferences, and public gatherings.",
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <ContentSection
          title="Key Features of Our ASL Services"
          contentList={[
            {
              text: "Flexible Scheduling: Whether for a short meeting or a multi-day event, we adapt to your requirements.",
            },
            {
              text: "Team Interpreting for Long Events: To maintain quality and avoid fatigue, we provide multiple interpreters for extended sessions.",
            },
            {
              text: "Nationwide Coverage: Our interpreters are available anywhere in the U.S., ensuring accessibility wherever you need it.",
            },
          ]}
          additionalContent="Trusted Interpreters is committed to bridging communication gaps and promoting inclusivity through reliable ASL services. By hiring our interpreters, you ensure that everyone, regardless of hearing ability, can fully participate and engage in your events."
          imageUrl={OnsiteImage3}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Our ASL Interpretation Services?">
          <KeyBenefits benefits={ASLBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Us Today"
          description="Need ASL interpretation services? Contact Trusted Interpreters now to schedule professional interpreters for your upcoming event or meeting."
          buttonText="Contact Us"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default ASL;