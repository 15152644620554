import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import CallToAction from "../../../components/CallToAction";
import { FaArrowsAlt, FaMapMarkedAlt } from "react-icons/fa";
import BusinessImage from "../../../../src/assets/images/vectors/business.png";
import BusinessImage1 from "../../../../src/assets/images/adobe/SanAntonio1.webp";
import OnsiteImage1 from "../../../../src/assets/images/adobe/landing-img.webp";
import OnsiteImage2 from "../../../../src/assets/images/adobe/LA2.webp";
import { FaUserGraduate } from "react-icons/fa6";

const BusinessInterpretation: React.FC = () => {
  const businessBenefits = [
    {
      icon: FaUserGraduate,
      title: "Industry Expertise",
      text: "Our interpreters are selected based on their experience and knowledge in your specific industry.",
    },
    {
      icon: FaArrowsAlt,
      title: "Flexible Options",
      text: "Offering on-site, over-the-phone, and virtual interpretation services to suit your business needs.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Availability",
      text: "Access professional interpreters anywhere in the United States.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <PageHeader
        title="Professional Business Interpretation for Corporate Needs"
        description="Trusted Interpreters offers expert business interpretation services designed to support seamless communication in professional environments. From international meetings to corporate events, our interpreters provide accurate and culturally sensitive solutions tailored to your business objectives."
        imageUrl={BusinessImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Business Interpreters You Can Rely On"
          content="Our interpreters are:"
          contentList={[
            {
              text: "Fluent in Industry Terminology: Skilled in handling corporate language across sectors like finance, technology, marketing, and more.",
            },
            {
              text: "Culturally Aware: Equipped to navigate cultural nuances, ensuring effective communication in international settings.",
            },
            {
              text: "Professional and Confidential: Bound by strict confidentiality agreements to safeguard sensitive business information.",
            },
          ]}
          imageUrl={OnsiteImage1}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Comprehensive Business Interpretation Services"
          content="We support a wide range of business scenarios, including:"
          contentList={[
            {
              text: "Meetings and Conferences: Facilitating clear communication in boardrooms, team meetings, and international summits.",
            },
            {
              text: "Negotiations and Deals: Ensuring mutual understanding during high-stakes discussions.",
            },
            {
              text: "Training Sessions: Bridging language gaps in employee training programs.",
            },
            {
              text: "Corporate Events: Supporting presentations, product launches, and press conferences.",
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <ContentSection
          title="Enhancing Communication for Business Success"
          content="In today’s globalized world, effective communication is critical for business growth. Trusted Interpreters helps organizations overcome language barriers, enabling them to connect with clients, partners, and employees worldwide."
          imageUrl={BusinessImage1}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Our Business Interpretation Services?">
          <KeyBenefits benefits={businessBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Us Today"
          description="Elevate your business interactions with professional interpretation services. Contact Trusted Interpreters to learn more about how we can support your corporate communication needs."
          buttonText="Contact Us"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default BusinessInterpretation;