import React from "react";

interface HighlightedSectionProps {
  title: string;
  children: React.ReactNode;
}

const HighlightedSection: React.FC<HighlightedSectionProps> = ({
  title,
  children,
}) => {
  return (
    <div className="container mx-auto px-6 py-4 md:py-8">
      <div className="bg-white rounded-lg shadow-lg p-8">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
          {title}
        </h2>
        {children}
      </div>
    </div>
  );
};

export default HighlightedSection;
