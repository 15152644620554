import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import LanguageLinks from "../../../components/LanguageLinks";
import CallToAction from "../../../components/CallToAction";
import OnsiteImage2 from "../../../../src/assets/images/adobe/adobe24.webp";
import { FaCertificate } from "react-icons/fa6";
import { FaGlobeAmericas, FaMapMarkedAlt } from "react-icons/fa";
import Helmet from "react-helmet";
// images
import InterpretersImage from "../../../../src/assets/images/vectors/our-interpreters.png";
import PeopleImage from "../../../../src/assets/images/adobe/adobe23.webp";

const Interpreters: React.FC = () => {
  const interpretersBenefits = [
    {
      icon: FaCertificate,
      title: "Certified Professionals",
      text: "Our interpreters are highly trained and certified, offering reliable and accurate services.",
    },
    {
      icon: FaGlobeAmericas,
      title: "Cultural Understanding",
      text: "We pair you with interpreters who understand the cultural context of the target language, ensuring effective communication.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Availability",
      text: "With interpreters located across the U.S., we are ready to support your language needs anywhere.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Certified Interpreters Team</title>
        <meta
          name="description"
          content="Trusted Interpreters' team offers certified, expert interpreters nationwide."
        />
      </Helmet>
      <PageHeader
        title="Meet Our Certified Interpreters: Skilled Experts for Every Need"
        description="At Trusted Interpreters, our interpreters are the heart of what we do. With years of experience, specialized training, and a passion for effective communication, our team is dedicated to breaking language barriers and ensuring seamless interactions across industries."
        imageUrl={InterpretersImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="What Makes Our Interpreters Exceptional?"
          contentList={[
            {
              text: "Certified Professionals: Each interpreter in our network is certified and meets the highest standards of linguistic and cultural proficiency.",
            },
            {
              text: "Fluency in 200+ Languages: From widely spoken languages like Spanish, Mandarin, and French to less common dialects, our interpreters cover it all.",
              links: [
                {
                  linkText: "Spanish",
                  link: "/language-expertise/spanish-interpretation",
                },
                {
                  linkText: "Mandarin",
                  link: "/language-expertise/chinese-interpretation",
                },
                {
                  linkText: "French",
                  link: "/language-expertise/french-interpretation",
                },
              ],
            },
            {
              text: "Industry-Specific Expertise: Our interpreters specialize in various fields, including legal, medical, corporate, education, and government sectors, ensuring accurate and context-sensitive communication.",
              links: [
                {
                  linkText: "legal",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "medical",
                  link: "/expertise/medical-interpretation",
                },
                {
                  linkText: "corporate",
                  link: "/expertise/business-interpretation",
                },
                {
                  linkText: "education",
                  link: "/expertise/educational-interpretation",
                },
                {
                  linkText: "government",
                  link: "/expertise/government-interpretation",
                },
              ],
            },
          ]}
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
        <ProcessWorkflow process="presencial" />

        <ContentSection
          title="Rigorous Selection and Training"
          content="We pride ourselves on working with the best interpreters in the industry. Here’s how we ensure quality:"
          contentList={[
            {
              text: "Comprehensive Screening: Every interpreter undergoes a rigorous evaluation process to verify their language proficiency and subject matter expertise.",
            },
            {
              text: "Continuous Training: Our interpreters participate in ongoing professional development to stay updated on the latest trends, tools, and best practices in the interpretation industry.",
            },
            {
              text: "Cultural Sensitivity: In addition to language skills, our interpreters are trained in cultural nuances to deliver contextually appropriate communication.",
            }
          ]}
          additionalContent="With a vast network of interpreters nationwide, Trusted Interpreters is here to meet your multilingual communication needs. Whether you need on-site, over-the-phone, or video remote interpretation, we’ll connect you with the right professional for your project."
          imageUrl={OnsiteImage2}
          direction="left"
        />

        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={interpretersBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Us"
          description="Reach out today to learn more about our team of interpreters and how we can support your communication goals."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Interpreters;