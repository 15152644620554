import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import CallToAction from "../../../components/CallToAction";
import { FaCheckCircle } from "react-icons/fa";
import MedicalImage from "../../../../src/assets/images/vectors/medical.png";
import MedicalImage1 from "../../../../src/assets/images/adobe/medical1.webp";
import OnsiteImage1 from "../../../../src/assets/images/adobe/medical.webp";
import OnsiteImage2 from "../../../../src/assets/images/adobe/interpreter1.webp";
import { FaClock, FaGlobe } from "react-icons/fa6";

const MedicalInterpretation: React.FC = () => {
  const medicalBenefits = [
    {
      icon: FaGlobe,
      title: "Cultural Understanding",
      text: "Our interpreters are fluent in over 200 languages and dialects, with deep knowledge of cultural nuances to foster trust and understanding.",
    },
    {
      icon: FaClock,
      title: "24/7 Availability",
      text: "We provide on-demand medical interpretation services, including on-site, over-the-phone, and video remote options.",
    },
    {
      icon: FaCheckCircle,
      title: "High Standards of Accuracy",
      text: "Our interpreters are committed to delivering precise and thorough interpretations to enhance patient outcomes.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <PageHeader
        title="Professional Medical Interpretation Tailored to Your Needs"
        description="Trusted Interpreters offers expert medical interpretation services to ensure clear communication between healthcare providers and patients. With certified interpreters trained in medical terminology and cultural sensitivity, we help bridge language barriers in healthcare settings across the United States."
        imageUrl={MedicalImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Certified Medical Interpreters You Can Trust"
          content="Our interpreters are:"
          contentList={[
            {
              text: "Medically Certified: Trained and certified by reputable organizations such as the National Board of Certification for Medical Interpreters (NBCMI) and the Certification Commission for Healthcare Interpreters (CCHI).",
            },
            {
              text: "Experienced in Medical Settings: Skilled in handling complex medical terminology and high-pressure environments.",
            },
            {
              text: "HIPAA Compliant: Adhering to strict confidentiality and privacy standards to protect patient information.",
            },
          ]}
          imageUrl={OnsiteImage1}
          direction="right"
        />

        {/* Workflow */}
        <ProcessWorkflow process="presencial" />

        <ContentSection
          title="Comprehensive Medical Interpretation Services"
          content="We provide interpretation solutions for a variety of healthcare needs, including:"
          contentList={[
            {
              text: "Doctor-Patient Consultations: Ensuring clear communication during diagnoses, treatments, and follow-ups.",
            },
            {
              text: "Hospital Services: Supporting emergency room visits, surgeries, and inpatient care.",
            },
            {
              text: "Specialized Medical Fields: Catering to fields such as pediatrics, oncology, cardiology, and mental health.",
            },
            {
              text: "Health Education: Facilitating communication in patient education sessions and community health programs.",
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <ContentSection
          title="Supporting Healthcare Professionals and Patients Nationwide"
          content="From small clinics to large hospitals, Trusted Interpreters is a trusted partner for healthcare providers seeking reliable interpretation services. We help eliminate language barriers, ensuring equitable and effective care for all patients."
          imageUrl={MedicalImage1}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Our Medical Interpretation Services?">
          <KeyBenefits benefits={medicalBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Us Today"
          description="Enhance patient care with expert medical interpretation services. Contact Trusted Interpreters now to learn more or to book a certified medical interpreter for your healthcare facility."
          buttonText="Contact Us"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default MedicalInterpretation;