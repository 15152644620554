import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import OnsiteImage2 from "../../../src/assets/images/adobe/adobe5.webp";
import { FaCertificate } from "react-icons/fa6";
import { FaGlobeAmericas, FaMapMarkedAlt } from "react-icons/fa";
import Helmet from "react-helmet";
// images
import HaitianImage from "../../../src/assets/images/vectors/haitian.png";
import seattle2 from "../../../src/assets/images/adobe/seattle2.webp";
import adobe15 from "../../../src/assets/images/adobe/adobe15.webp";



const HaitianExpertise: React.FC = () => {
  const haitianBenefits = [
    {
      icon: FaCertificate,
      title: "Certified Professionals",
      text: "Our interpreters are highly trained and certified, offering reliable and accurate services.",
    },
    {
      icon: FaGlobeAmericas,
      title: "Cultural Understanding",
      text: "We pair you with interpreters who understand the cultural context of the target language, ensuring effective communication.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Availability",
      text: "With interpreters located across the U.S., we are ready to support your language needs anywhere.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Reliable Haitian Creole Interpretation</title>
        <meta
          name="description"
          content="Get professional Haitian Creole interpretation services tailored to your needs."
        />
      </Helmet>
      <PageHeader
        title="Professional Haitian Creole Interpretation Services"
        description="Haitian Creole is a vital language for connecting with communities and businesses in Haiti and Haitian populations worldwide. At Trusted Interpreters, we offer expert Haitian Creole interpretation services designed to ensure clear and culturally accurate communication for any setting or industry."
        imageUrl={HaitianImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Choose Trusted Interpreters for Haitian Creole?"
          contentList={[
            {
              text: "Certified Experts: Our interpreters are fluent in both Haitian Creole and English, with specialized training in various fields.",
            },
            {
              text: "Cultural Sensitivity: We understand the nuances of Haitian Creole, ensuring accurate and respectful communication.",
            },
            {
              text: "Tailored Solutions: Whether for legal, medical, or corporate needs, our services are customized to meet your specific requirements.",
              links: [
                {
                  linkText: "legal",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "medical",
                  link: "/expertise/medical-interpretation",
                },
                {
                  linkText: "corporate",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Tailored Interpretation Solutions"
          contentList={[
            {
              text: "On-Site Interpretation: Perfect for face-to-face interactions, including business meetings, legal proceedings, and medical consultations. Our interpreters ensure seamless communication in real-time.",
              links: [
                {
                  linkText: "On-Site Interpretation",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "business meetings",
                  link: "/expertise/business-interpretation",
                },
                {
                  linkText: "legal proceedings",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "medical consultations",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Phone Interpretation: For urgent needs or remote communication, our over-the-phone interpretation services provide quick and reliable access to professional interpreters.",
              links: [
                {
                  linkText: "Phone Interpretation",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Video Remote Interpretation (VRI): Ideal for virtual meetings, hybrid events, and webinars, our VRI services ensure effective communication across digital platforms in real time.",
              links: [
                {
                  linkText: "Video Remote Interpretation (VRI)",
                  link: "/interpretation-types/vri",
                },
                {
                  linkText: "virtual meetings",
                  link: "/interpretation-types/vri/zoom-interpretation-services",
                },
              ],
            }
          ]}
          imageUrl={seattle2}
          direction="left"
        />

        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks currentLanguage="Haitian"/>

        <ContentSection
          title="Industries We Serve"
          contentList={[
            {
              text: "Healthcare: Assisting patients and providers in hospitals, clinics, and private practices with accurate and clear communication.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                }
              ],
            },
            {
              text: "Legal Services: Providing court-certified interpreters for trials, depositions, and client consultations.",
              links: [
                {
                  linkText: "Legal Services",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Corporate: Supporting international negotiations, team collaborations, and business operations.",
              links: [
                {
                  linkText: "Corporate",
                  link: "/expertise/business-interpretation",
                }
              ],
            },
            {
              text: "Education: Bridging language gaps in schools, universities, and training programs.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                }
              ],
            }
          ]}
          additionalContent="Whether you need interpretation services in-person, over the phone, or online, Trusted Interpreters is equipped to assist you anywhere in the United States."
          imageUrl={adobe15}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={haitianBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Get Started with Trusted Interpreters"
          description="Break language barriers and connect effectively with Haitian Creole-speaking audiences. Contact Trusted Interpreters today to learn more about our Haitian Creole interpretation services and schedule a professional interpreter for your next project."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default HaitianExpertise;
