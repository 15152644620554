import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import CallToAction from "../../../components/CallToAction";
import { FaArrowsAlt, FaMapMarkedAlt } from "react-icons/fa";
import EducationalImage from "../../../../src/assets/images/vectors/education.png";
import EducationalImage1 from "../../../../src/assets/images/adobe/seattle2.webp";
import OnsiteImage1 from "../../../../src/assets/images/adobe/NYC1.webp";
import OnsiteImage2 from "../../../../src/assets/images/adobe/SanDiego1.webp";
import { FaLanguage } from "react-icons/fa6";
import Helmet from "react-helmet";


const EducationalInterpretation: React.FC = () => {
  const educationalBenefits = [
    {
      icon: FaLanguage,
      title: "Comprehensive Language Coverage",
      text: "We offer interpretation in over 200 languages and dialects to meet the diverse needs of educational institutions.",
    },
    {
      icon: FaArrowsAlt,
      title: "Flexible Options",
      text: "Choose on-site, over-the-phone, or video remote interpretation services based on your specific requirements.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Reach",
      text: "Our interpreters are available across the U.S., ensuring support wherever it’s needed.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Accurate Educational Interpretation US</title>
        <meta
          name="description"
          content="Enhance communication in schools with our professional educational interpretation."
        />
      </Helmet>
      <PageHeader
        title="Accurate Educational Interpretation for Students and Educators"
        description="Trusted Interpreters provides professional educational interpretation services to support effective communication in academic settings. Our interpreters help schools, universities, and educational institutions bridge language barriers, ensuring every student and family has equal access to education."
        imageUrl={EducationalImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Expert Educational Interpreters"
          content="Our interpreters are:"
          contentList={[
            {
              text: "Trained in Educational Terminology: Proficient in the language used in academic environments, from primary schools to higher education.",
            },
            {
              text: "Culturally Sensitive: Equipped to navigate cultural differences that may impact communication.",
            },
            {
              text: "Confidential and Professional: Adhering to strict ethical standards to ensure privacy and trust.",
            },
          ]}
          imageUrl={OnsiteImage1}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Educational Interpretation Services We Offer"
          content="We support a variety of educational needs, including:"
          contentList={[
            {
              text: "Parent-Teacher Conferences: Facilitating clear communication between teachers and multilingual families.",
            },
            {
              text: "Student Assessments: Ensuring accurate interpretation during evaluations and special education meetings.",
            },
            {
              text: "Classroom Support: Assisting in multilingual classrooms for effective instruction and student engagement.",
            },
            {
              text: "Workshops and Seminars: Supporting educators during training sessions and professional development events.",
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <ContentSection
          title="Empowering Education Through Communication"
          content="At Trusted Interpreters, we believe language should never be a barrier to education. Our interpretation services foster inclusivity, helping students and families fully participate in their educational journey."
          imageUrl={EducationalImage1}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Our Educational Interpretation Services?">
          <KeyBenefits benefits={educationalBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Us Today"
          description="Enhance communication in your educational institution with professional interpretation services. Contact Trusted Interpreters now to learn more or to book an interpreter for your next event."
          buttonText="Contact Us"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default EducationalInterpretation;