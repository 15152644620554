import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import { Helmet } from "react-helmet";
// images
import HoustonImage from "../../../src/assets/images/adobe/Houston.webp";
import HoustonImage1 from "../../../src/assets/images/adobe/Houston1.webp";
import HoustonImage2 from "../../../src/assets/images/adobe/interpreter1.webp";
import HoustonImage3 from "../../../src/assets/images/adobe/onsite-img-1.webp";

const Houston: React.FC = () => {
  const houstonBenefits = [
    {
      icon: FaAward,
      title: "Certified Professionals",
      text: "Our interpreters are highly trained and certified, ensuring reliable and precise interpretation.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Awareness",
      text: "We prioritize cultural nuances to ensure your message resonates effectively.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in Houston, delivering consistent support wherever needed.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services Houston</title>
        <meta
          name="description"
          content="Professional interpretation services tailored to your needs in Houston."
        />
      </Helmet>

      <PageHeader
        title="Professional Interpretation Services in Houston"
        description="Trusted Interpreters offers expert interpretation services tailored to meet the needs of Houston’s diverse industries and communities. Whether for legal, healthcare, corporate, or technical settings, our certified interpreters ensure accurate and culturally sensitive communication in over 200 languages."
        imageUrl={HoustonImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Choose Trusted Interpreters in Houston?"
          contentList={[
            {
              text: "Experienced Linguists: Our interpreters are proficient in over 200 languages, ensuring clear communication for diverse audiences.",
            },
            {
              text: "Industry Expertise: We specialize in various sectors, including medical, legal, corporate, and technical fields, making us the ideal partner for your interpretation needs.",
              links: [
                {
                  linkText: "medical",
                  link: "/expertise/medical-interpretation",
                },
                {
                  linkText: "legal",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "corporate",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Flexible Solutions: We offer on-site, over-the-phone, and virtual interpretation services to accommodate your schedule and preferences.",
              links: [
                {
                  linkText: "on-site",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "over-the-phone",
                  link: "/interpretation-types/phone-interpretation",
                },
                {
                  linkText: "virtual interpretation",
                  link: "/interpretation-types/vri",
                },
              ],
            },
          ]}
          imageUrl={HoustonImage1}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Comprehensive Services in Houston"
          contentList={[
            {
              text: "On-Site Interpretation: Perfect for legal proceedings, corporate meetings, and medical consultations. Our interpreters can travel to any location in Houston and its suburbs.",
              links: [
                {
                  linkText: "On-Site Interpretation",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "legal proceedings",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "corporate meetings",
                  link: "/expertise/business-interpretation",
                },
                {
                  linkText: "medical consultations",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Over-the-Phone Interpretation: Rapid language support for urgent communication needs.",
              links: [
                {
                  linkText: "Over-the-Phone Interpretation",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Video Remote Interpretation (VRI): Seamless multilingual communication for virtual meetings, webinars, and hybrid events.",
              links: [
                {
                  linkText: "Video Remote Interpretation (VRI)",
                  link: "/interpretation-types/vri",
                },
              ],
            },
          ]}
          imageUrl={HoustonImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks />

        <ContentSection
          title="Industries We Serve in Houston"
          content="Our interpreters have extensive experience in Houston’s key industries:"
          contentList={[
            {
              text: "Healthcare: Facilitating clear communication between patients and providers in hospitals, clinics, and medical offices.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal Services: Providing court-certified interpreters for trials, depositions, and legal consultations.",
              links: [
                {
                  linkText: "Legal Services",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Energy and Technology: Supporting multinational projects and collaborations in Houston’s energy and tech sectors.",
              links: [
                {
                  linkText: "Energy and Technology",
                  link: "/expertise/technology-interpretation",
                },
              ],
            },
            {
              text: "Business and Corporate: Ensuring smooth communication during international negotiations, training sessions, and conferences.",
              links: [
                {
                  linkText: "Business and Corporate",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
          ]}
          additionalContent="From downtown Houston to Sugar Land, The Woodlands to Katy, Trusted Interpreters offers professional interpretation services across the greater Houston area."
          imageUrl={HoustonImage3}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={houstonBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Trusted Interpreters Today"
          description="Ensure effective communication in Houston’s diverse and dynamic environment. Contact Trusted Interpreters today to learn more about our expert interpretation services and how we can support your success."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Houston;
