import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import OnsiteImage2 from "../../../src/assets/images/adobe/adobe5.webp";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import Helmet from "react-helmet";
// images
import ColumbusImage from "../../../src/assets/images/adobe/Columbus.webp";
import PeopleImage from "../../../src/assets/images/adobe/adobe15.webp";
import PeopleImage1 from "../../../src/assets/images/adobe/interpreter.webp";

const Columbus: React.FC = () => {
  const SFBenefits = [
    {
      icon: FaAward,
      title: "Experienced Professionals",
      text: "We ensure accurate communication while respecting cultural nuances.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Understanding",
      text: "Our interpreters are certified, trained, and fluent in over 200 languages.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in Columbus, delivering consistent support wherever needed.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services Columbus</title>
        <meta
          name="description"
          content="Trusted Interpreters offers professional interpretation services in Columbus."
        />
      </Helmet>
      <PageHeader
        title="Professional Interpretation Services in Columbus"
        description="Trusted Interpreters offers expert language solutions to meet the diverse communication needs of Columbus’s businesses, healthcare providers, legal professionals, and educators. With certified interpreters fluent in over 200 languages, we provide precise, culturally sensitive communication tailored to your unique requirements."
        imageUrl={ColumbusImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Your Go-To Partner for Interpretation Services"
          contentList={[
            {
              text: "Certified Experts: Our interpreters are fluent in over 200 languages and trained in industry-specific terminology to ensure accuracy and cultural sensitivity.",
            },
            {
              text: "Flexible Offerings: We provide on-site, over-the-phone, and video remote interpretation (VRI) services to meet your unique needs.",
              links: [
                {
                  linkText: "on-site",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "over-the-phone",
                  link: "/interpretation-types/phone-interpretation",
                },
                {
                  linkText: "video remote interpretation (VRI)",
                  link: "/interpretation-types/vri",
                },
              ],
            },
            {
              text: "Committed to Excellence: With a focus on professionalism and confidentiality, we deliver language solutions you can trust.",
            },
          ]}
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />

        
        <ContentSection
          title="Our Interpretation Solutions"
          contentList={[
            {
              text: "In-Person Language Support: For conferences, legal hearings, medical appointments, or corporate meetings, our interpreters provide on-site services tailored to your specific event or setting.",
              links: [
                {
                  linkText: "In-Person Language Support",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "corporate meetings",
                  link: "/expertise/business-interpretation",
                },
                {
                  linkText: "legal hearings",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "medical appointments",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Phone Interpretation Anytime: For urgent communication needs, our phone interpretation service offers 24/7 access to professional linguists, ensuring you’re never without language support.",
              links: [
                {
                  linkText: "Phone Interpretation Anytime",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Virtual Interpretation Made Easy: Our VRI services enable seamless communication in digital environments, perfect for webinars, hybrid meetings, and virtual conferences.",
              links: [
                {
                  linkText: "Virtual Interpretation Made Easy",
                  link: "/interpretation-types/vri",
                },
                {
                  linkText: "virtual conferences",
                  link: "/interpretation-types/vri/microsoft-teams-interpretation-services",
                },
              ],
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks/>

        <ContentSection
          title="Industries We Serve in Columbus"
          contentList={[
            {
              text: "Healthcare: Supporting patient-provider interactions in hospitals, clinics, and private practices.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal: Providing court-certified interpreters for trials, depositions, and legal consultations.",
              links: [
                {
                  linkText: "Legal",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Business: Facilitating international negotiations, corporate training sessions, and team collaborations.",
              links: [
                {
                  linkText: "Business",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Education: Assisting schools and universities with multilingual communication for students, parents, and faculty.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
          ]}
          additionalContent="From Downtown Columbus to neighborhoods like German Village and Short North, Trusted Interpreters delivers reliable language solutions across the city and surrounding areas."
          imageUrl={PeopleImage1}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={SFBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Us Today"
          description="Don’t let language barriers stand in your way. Contact Trusted Interpreters today to learn more about our professional interpretation services in Columbus and how we can support your success."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Columbus;
