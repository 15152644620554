import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import CallToAction from "../../../components/CallToAction";
import { FaArrowsAlt, FaMapMarkedAlt } from "react-icons/fa";
import BusinessImage from "../../../../src/assets/images/vectors/zoom.png";
import BusinessImage1 from "../../../../src/assets/images/adobe/Chicago1.webp";
import OnsiteImage1 from "../../../../src/assets/images/adobe/adobe1.webp";
import OnsiteImage2 from "../../../../src/assets/images/adobe/adobe12.webp";
import { FaUserGraduate } from "react-icons/fa6";

const TechnologyInterpretation: React.FC = () => {
  const techBenefits = [
    {
      icon: FaUserGraduate,
      title: "Certified Professionals",
      text: "Our interpreters are trained to handle the complexities of the technology sector.",
    },
    {
      icon: FaArrowsAlt,
      title: "Cultural Awareness",
      text: "We ensure that your message resonates effectively with global audiences.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Confidentiality Guaranteed",
      text: "Strict adherence to non-disclosure agreements and data security standards.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <PageHeader
        title="Expert Technology Interpretation Services"
        description="Trusted Interpreters provides professional technology interpretation services to ensure seamless communication in the ever-evolving tech industry. From global conferences to technical training sessions, our certified interpreters bridge language gaps and deliver precise, industry-specific communication tailored to your needs."
        imageUrl={BusinessImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Choose Technology Interpretation?"
          content="The technology sector is fast-paced and highly specialized, requiring interpreters with in-depth knowledge of technical terminology and concepts. Trusted Interpreters ensures that your message is accurately conveyed, fostering collaboration, innovation, and understanding across languages and cultures."
          imageUrl={OnsiteImage1}
          direction="right"
        />

        {/* Workflow */}
        <ProcessWorkflow process="presencial" />

        <ContentSection
          title="What Sets Our Technology Interpretation Services Apart?"
          contentList={[
            {
              text: "Industry Expertise: Interpreters with experience in AI, software development, cybersecurity, engineering, and more.",
            },
            {
              text: "Technical Accuracy: Proficient in translating complex technical jargon and industry-specific terminology.",
            },
            {
              text: "Flexible Formats: Available for on-site, over-the-phone, and video remote interpretation.",
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <ContentSection
          title="Applications of Technology Interpretation Services"
          content="Our interpreters provide solutions for a wide range of tech-related scenarios, including:"
          contentList={[
            {
              text: "Global Tech Conferences: Ensuring multilingual communication during keynote speeches, panel discussions, and workshops.",
            },
            {
              text: "Product Launches and Demos: Helping clients and stakeholders understand your innovations clearly.",
            },
            {
              text: "Training and Development: Supporting effective communication during technical training programs and certifications.",
            },
            {
              text: "Engineering and IT Consultations: Facilitating smooth collaboration between international teams.",
            },
            {
              text: "Cybersecurity and Data Protection Seminars: Ensuring accurate interpretation of critical information.",
            },
          ]}
          imageUrl={BusinessImage1}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Our Technology Interpretation Services?">
          <KeyBenefits benefits={techBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Us Today"
          description="Elevate your business interactions with professional interpretation services. Contact Trusted Interpreters to learn more about how we can support your corporate communication needs."
          buttonText="Contact Us"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default TechnologyInterpretation;