import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import { FaUserGraduate, FaLock, FaGlobeAmericas } from "react-icons/fa";
import ConsecutiveImg from "../../../../src/assets/images/vectors/consecutive.png";
import OnsiteImage1 from "../../../../src/assets/images/adobe/adobe16.webp";
import OnsiteImage2 from "../../../../src/assets/images/adobe/adobe19.webp";
import CallToAction from "../../../components/CallToAction";
import Helmet from "react-helmet";

const Consecutive: React.FC = () => {
  const consecutiveBenefits = [
    {
      icon: FaUserGraduate,
      title: "Specialized Skills",
      text: "Our interpreters are experts in their fields and fluent in both the source and target languages, ensuring accuracy and cultural understanding.",
    },
    {
      icon: FaLock,
      title: "Confidentiality",
      text: "All interpreters fit to strict confidentiality agreements and ethical standards.",
    },
    {
      icon: FaGlobeAmericas,
      title: "Nationwide Availability",
      text: "With a vast network of professionals, we provide services anywhere in the U.S.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Consecutive Interpretation in the US</title>
        <meta
          name="description"
          content="Trusted Interpreters delivers expert consecutive interpretation services nationwide."
        />
      </Helmet>
      <PageHeader
        title="Professional Consecutive Interpretation Services Across the U.S."
        description="Trusted Interpreters specializes in providing high-quality consecutive interpretation services tailored to your needs. Ideal for settings like legal hearings, medical appointments, and business meetings, our interpreters ensure seamless communication without the need for specialized equipment."
        imageUrl={ConsecutiveImg}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="What is Consecutive Interpretation?"
          content="In consecutive interpretation, the interpreter listens to the speaker, takes notes, and delivers the message in the target language once the speaker pauses. This method allows for:"
          contentList={[
            { text: "Clear and precise communication." },
            {
              text: "A natural flow of dialogue in one-on-one or small group settings.",
            },
            {
              text: "Adaptability to different environments without additional tools.",
            },
          ]}
          imageUrl={OnsiteImage1}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Expertise You Can Trust"
          content="Our interpreters are highly trained to deliver accurate interpretations while conserving the tone, intent, and cultural context of the primary message."
          contentList={[
            {
              text: "Legal settings, such as depositions and court hearings.",
              links: [
                { linkText: "Legal", link: "/expertise/legal-interpretation" },
              ],
            },
            {
              text: "Medical appointments, ensuring clear communication between patients and healthcare providers.",
              links: [
                {
                  linkText: "Medical",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Business environments, facilitating efficient discussions in meetings or negotiations.",
              links: [
                {
                  linkText: "Business",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="Consecutive" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Our Consecutive Interpretation?">
          <KeyBenefits benefits={consecutiveBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Elevate Your Communication"
          description="Whether you're organizing a legal proceeding, a medical consultation, or a business meeting, Trusted Interpreters is your partner in overcoming language barriers. Contact us today to book a professional consecutive interpreter for your next event."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Consecutive;
