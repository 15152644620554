import React, { useState, useEffect } from "react";
import {
  FaComments,
  FaLightbulb,
  FaLanguage,
  FaEllipsis,
} from "react-icons/fa6";
import { Link } from "react-router-dom"; // Importa Link para navegación interna

const faqsData: {
  [key: string]: { question: string; answer: React.ReactNode }[];
} = {
  Interpretations: [
    {
      question: "What is an interpretation, and why is it important?",
      answer: (
        <>
          Interpretation is the process of converting spoken or signed language from one language to another in real time. 
          It plays a vital role in ensuring clear communication in settings such as meetings, conferences, and medical appointments.
        </>
      ),
    },
    {
      question:
        "What’s the difference between simultaneous and consecutive interpretation?",
      answer: (
        <>
          <Link
            to="/interpretation-types/on-site/simultaneous"
            className="text-blue-600 underline hover:text-blue-800"
          >
            <strong>Simultaneous Interpretation:</strong>
          </Link>{" "}
          Real-time translation as the speaker talks, ideal for conferences.{" "}
          <br />
          <Link
            to="/interpretation-types/on-site/consecutive"
            className="text-blue-600 underline hover:text-blue-800"
          >
            <strong>Consecutive Interpretation:</strong>
          </Link>{" "}
          The interpreter speaks after the speaker pauses, best for smaller
          meetings.
        </>
      ),
    },
    {
      question: "What types of interpretation services do you offer?",
      answer: (
        <>
          We provide{" "}
          <Link
            to="/interpretation-types/on-site"
            className="text-blue-600 underline hover:text-blue-800"
          >
            on-site
          </Link>
          ,{" "}
          <Link
            to="/interpretation-types/phone-interpretation"
            className="text-blue-600 underline hover:text-blue-800"
          >
            over-the-phone (OPI)
          </Link>
          ,{" "}
          <Link
            to="/interpretation-types/vri"
            className="text-blue-600 underline hover:text-blue-800"
          >
            video remote (VRI)
          </Link>
          ,
          <Link
            to="/interpretation-types/on-site/simultaneous"
            className="text-blue-600 underline hover:text-blue-800"
          >
            simultaneous
          </Link>
          ,{" "}
          <Link
            to="/interpretation-types/on-site/consecutive"
            className="text-blue-600 underline hover:text-blue-800"
          >
            consecutive
          </Link>
          , and{" "}
          <Link
            to="/interpretation-types/asl-interpretation/english-sign-language"
            className="text-blue-600 underline hover:text-blue-800"
          >
            sign language interpretation
          </Link>
          .
        </>
      ),
    },
  ],
  Expertise: [
    {
      question: "What industries do you serve?",
      answer: (
        <>
          Trusted Interpreters serves industries like{" "}
          <Link
            to="/expertise/legal-interpretation"
            className="text-blue-600 underline hover:text-blue-800"
          >
            legal
          </Link>
          ,{" "}
          <Link
            to="/expertise/medical-interpretation"
            className="text-blue-600 underline hover:text-blue-800"
          >
            healthcare
          </Link>
          ,{" "}
          <Link
            to="/expertise/immigration-interpretation"
            className="text-blue-600 underline hover:text-blue-800"
          >
            immigration
          </Link>
          ,{" "}
          <Link
            to="/expertise/business-interpretation"
            className="text-blue-600 underline hover:text-blue-800"
          >
            business
          </Link>
          ,{" "}
          <Link
            to="/expertise/educational-interpretation"
            className="text-blue-600 underline hover:text-blue-800"
          >
            education
          </Link>
          , and{" "}
          <Link
            to="/expertise/government-interpretation"
            className="text-blue-600 underline hover:text-blue-800"
          >
            government
          </Link>
          , and many more.
        </>
      ),
    },
    {
      question: "Are your interpreters certified?",
      answer: (
        <>
          Yes, all{" "}
          <Link
            to="/about-us/our-interpreters"
            className="text-blue-600 underline hover:text-blue-800"
          >
            our interpreters
          </Link>{" "}
          are professionally trained and certified to handle specific industry
          requirements.
        </>
      ),
    },
    {
      question: "Do you provide interpretation for immigration interviews?",
      answer: (
        <>
          Yes, we specialize in{" "}
          <Link
            to="/expertise/immigration-interpretation"
            className="text-blue-600 underline hover:text-blue-800"
          >
            immigration-related services
          </Link>
          , including interviews, hearings, and naturalization ceremonies.
        </>
      ),
    },
  ],
  Languages: [
    {
      question: "What languages are available for interpretation?",
      answer: (
        <>
          We support over 200 languages, including{" "}
          <Link
            to="/language-expertise/spanish-expertise"
            className="text-blue-600 underline hover:text-blue-800"
          >
            Spanish
          </Link>
          ,{" "}
          <Link
            to="/language-expertise/french-expertise"
            className="text-blue-600 underline hover:text-blue-800"
          >
            French
          </Link>
          ,{" "}
          <Link
            to="/language-expertise/chinese-expertise"
            className="text-blue-600 underline hover:text-blue-800"
          >
            Chinese
          </Link>
          ,{" "}
          <Link
            to="/language-expertise/arabic-expertise"
            className="text-blue-600 underline hover:text-blue-800"
          >
            Arabic
          </Link>
          ,{" "}
          <Link
            to="/language-expertise/portuguese-expertise"
            className="text-blue-600 underline hover:text-blue-800"
          >
            Portuguese
          </Link>
          , and{" "}
          <Link
            to="/interpretation-types/asl-interpretation/english-sign-language"
            className="text-blue-600 underline hover:text-blue-800"
          >
            American Sign Language (ASL)
          </Link>
          .
        </>
      ),
    },
    {
      question: "Can you handle rare languages or dialects?",
      answer:
        "Yes, our global network includes interpreters for less commonly spoken languages and regional dialects.",
    },
    {
      question: "Do you offer sign language interpretation?",
      answer: (
        <>
          Absolutely! We provide{" "}
          <Link
            to="/interpretation-types/asl-interpretation/english-sign-language"
            className="text-blue-600 underline hover:text-blue-800"
          >
            American Sign Language (ASL)
          </Link>
          ,{" "}
          <Link
            to="/interpretation-types/asl-interpretation/english-sign-language"
            className="text-blue-600 underline hover:text-blue-800"
          >
            English Sign Language (ESL)
          </Link>
          , and{" "}
          <Link
            to="/interpretation-types/asl-interpretation/RID-Certified-ASL-Interpreters"
            className="text-blue-600 underline hover:text-blue-800"
          >
            RID-certified interpreters
          </Link>
          .
        </>
      ),
    },
  ],
  Others: [
    {
      question: "What payment methods do you accept?",
      answer:
        "We accept all major credit cards, PayPal, and other secure online payment methods.",
    },
    {
      question: "Is my payment information secure?",
      answer:
        "Absolutely. We use industry-standard encryption and secure payment gateways to protect your financial data. Your privacy and safety are our top priorities during every transaction.",
    },
    {
      question:
        "Is there a guarantee of confidentiality for my conversations and interactions?",
      answer:
        "Yes, confidentiality is at the core of our services. All interpreters are bound by strict confidentiality agreements, and we use secure servers and platforms to protect your information.",
    },
    {
      question: "Do you provide contracts or agreements for interpretation projects?",
      answer:
        "Yes, we provide detailed agreements tailored to your specific interpretation needs. These agreements clearly outline the scope of the project, pricing, confidentiality terms, and other essential details to ensure transparency and trust. We work closely with you to meet your requirements, ensuring we are a reliable and trusted partner.",
    },
  ],
};

const FAQ: React.FC = () => {
  const [selectedSection, setSelectedSection] = useState<string | null>(null);
  const [activeQuestion, setActiveQuestion] = useState<number>(0);

  const sections = [
    { name: "Interpretations", icon: <FaComments /> },
    { name: "Expertise", icon: <FaLightbulb /> },
    { name: "Languages", icon: <FaLanguage /> },
    { name: "Others", icon: <FaEllipsis /> },
  ];

  useEffect(() => {
    const isDesktop = window.innerWidth >= 768;
    if (isDesktop) {
      setSelectedSection("Interpretations");
    }
  }, []);

  const selectSection = (sectionName: string) => {
    setSelectedSection(sectionName);
    setActiveQuestion(0);
  };

  const toggleSection = (sectionName: string) => {
    setSelectedSection((prev) => (prev === sectionName ? null : sectionName));
  };

  const toggleQuestion = (index: number) => {
    setActiveQuestion(activeQuestion === index ? -1 : index);
  };

  return (
    <div className="px-6 py-2">
      <div className="container mx-auto mt-4 lg:mt-12 px-8 py-16 bg-white rounded-lg shadow-lg">
        <h2 className="text-3xl text-[#024772] font-bold text-gray-800 -mt-10 mb-2 text-center">
          Frequently Asked Questions
        </h2>

        {/* Mobile View */}
        <div className="block lg:hidden space-y-4">
          {sections.map((section) => (
            <div key={section.name}>
              <button
                className={`w-full flex items-center justify-between px-4 py-3 rounded-lg text-lg font-semibold ${
                  selectedSection === section.name
                    ? "bg-blue-100 text-[#024772]"
                    : "bg-gray-100 text-gray-500"
                }`}
                onClick={() => toggleSection(section.name)}
              >
                <div className="flex items-center space-x-3">
                  <span className="text-xl">{section.icon}</span>
                  <span>{section.name}</span>
                </div>
                <span>{selectedSection === section.name ? "−" : "+"}</span>
              </button>

              {selectedSection === section.name && (
                <div className="mt-4 space-y-4 px-4 py-2 bg-gray-50 rounded-lg">
                  {faqsData[section.name].map((faq, index) => (
                    <div key={index}>
                      <button
                        className="text-lg font-medium text-left w-full text-gray-800 hover:text-blue-600 focus:outline-none"
                        onClick={() => toggleQuestion(index)}
                      >
                        {faq.question}
                        <span className="float-right">
                          {activeQuestion === index ? "−" : "+"}
                        </span>
                      </button>
                      {activeQuestion === index && (
                        <div className="text-gray-600 mt-2 pl-2">
                          {faq.answer}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Desktop View */}
        <div className="hidden lg:flex mt-10">
          <div className="w-1/4">
            <nav className="space-y-4">
              {sections.map((section) => (
                <button
                  key={section.name}
                  onClick={() => selectSection(section.name)}
                  className={`flex items-center space-x-3 w-full px-4 py-2 rounded-lg ${
                    selectedSection === section.name
                      ? "bg-blue-100 text-[#024772] font-semibold"
                      : "text-gray-600"
                  } hover:bg-blue-50`}
                >
                  <span className="text-xl">{section.icon}</span>
                  <span>{section.name}</span>
                </button>
              ))}
            </nav>
          </div>

          <div className="w-3/4 pl-8">
            {selectedSection && (
              <div>
                <h3 className="text-2xl text-[#024772] font-bold mb-4">
                  {selectedSection}
                </h3>
                <div className="space-y-4">
                  {faqsData[selectedSection].map((faq, index) => (
                    <div key={index}>
                      <button
                        className="text-lg font-semibold text-left w-full text-gray-800 hover:text-blue-600 focus:outline-none"
                        onClick={() => toggleQuestion(index)}
                      >
                        {faq.question}
                        <span className="float-right">
                          {activeQuestion === index ? "−" : "+"}
                        </span>
                      </button>
                      <div
                        className={`overflow-hidden transition-max-height duration-300 ease-in-out ${
                          activeQuestion === index ? "max-h-screen" : "max-h-0"
                        }`}
                      >
                        {activeQuestion === index && (
                          <div className="text-gray-600 mt-2 transition-opacity duration-300 ease-in-out">
                            {faq.answer}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>

  );
};

export default FAQ;
