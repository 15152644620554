import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import { Helmet } from "react-helmet";
// images
import ChicagoImage from "../../../src/assets/images/adobe/Chicago.webp";
import ChicagoImage1 from "../../../src/assets/images/adobe/Chicago1.webp";
import ChicagoImage2 from "../../../src/assets/images/adobe/over-the-phone-img.webp";
import ChicagoImage3 from "../../../src/assets/images/adobe/medical1.webp";

const Chicago: React.FC = () => {
  const chicagoBenefits = [
    {
      icon: FaAward,
      title: "Certified Professionals",
      text: "Our interpreters are highly trained and certified, ensuring reliable and precise interpretation.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Awareness",
      text: "We prioritize cultural nuances to ensure your message resonates effectively.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in Chicago, delivering consistent support wherever needed.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services Chicago</title>
        <meta
          name="description"
          content="Accurate interpretation services tailored for businesses and events in Chicago."
        />
      </Helmet>

      <PageHeader
        title="Professional Interpretation Services in Chicago"
        description="Trusted Interpreters provides reliable and professional interpretation services designed to meet the demands of Chicago’s vibrant and multilingual communities. Whether for legal hearings, healthcare appointments, corporate meetings, or educational events, our certified interpreters ensure clear and accurate communication in over 200 languages."
        imageUrl={ChicagoImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Trusted Interpreters is the Right Choice in Chicago"
          contentList={[
            {
              text: "Skilled Professionals: Our interpreters are fluent in over 200 languages and bring expertise in various industries, including law, medicine, and corporate sectors.",
              links: [
                {
                  linkText: "medicine",
                  link: "/expertise/medical-interpretation",
                },
                {
                  linkText: "law",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "corporate",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Adaptable Solutions: Whether you need on-site, over-the-phone, or virtual interpretation, our services are designed to meet your specific needs.",
              links: [
                {
                  linkText: "on-site",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "over-the-phone",
                  link: "/interpretation-types/phone-interpretation",
                },
                {
                  linkText: "virtual interpretation",
                  link: "/interpretation-types/vri",
                },
              ],
            },
            {
              text: "Local Expertise: We understand the cultural and linguistic nuances unique to Chicago’s diverse communities.",
            },
          ]}
          imageUrl={ChicagoImage1}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Our Services in Chicago"
          contentList={[
            {
              text: "On-Site Interpretation: Reliable services for legal hearings, conferences, and business meetings. Our interpreters are available throughout the Chicagoland area.",
              links: [
                {
                  linkText: "On-Site Interpretation",
                  link: "/interpretation-types/on-site",
                },
              ],
            },
            {
              text: "Over-the-Phone Interpretation: Immediate language support, perfect for urgent communication needs.",
              links: [
                {
                  linkText: "Over-the-Phone Interpretation",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Video Remote Interpretation (VRI): Ideal for virtual meetings and hybrid events, ensuring real-time multilingual communication.",
              links: [
                {
                  linkText: "Video Remote Interpretation (VRI)",
                  link: "/interpretation-types/vri",
                },
              ],
            },
          ]}
          imageUrl={ChicagoImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks />

        <ContentSection
          title="Industries We Serve in Chicago"
          content="Our team of interpreters specializes in providing solutions for a wide range of sectors:"
          contentList={[
            {
              text: "Legal Services: Court-certified interpreters for trials, depositions, and legal consultations.",
              links: [
                {
                  linkText: "Legal Services",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Healthcare: Helping doctors, nurses, and patients overcome language barriers in hospitals and clinics.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Corporate and Business: Supporting international negotiations, training sessions, and team collaboration.",
              links: [
                {
                  linkText: "Corporate and Business",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Education: Enhancing communication in schools, universities, and training programs.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
          ]}
          additionalContent="From the Loop to Lincoln Park, Hyde Park to Evanston, Trusted Interpreters is proud to offer professional interpretation services tailored to your needs, no matter where you are in the Chicago area."
          imageUrl={ChicagoImage3}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={chicagoBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Us for Expert Services"
          description="Break language barriers and achieve effective communication with Trusted Interpreters. Contact us today to learn how our interpretation services can support your success in Chicago."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Chicago;
