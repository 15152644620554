import React from "react";

interface PageHeaderProps {
  title: string;
  description: React.ReactNode;
  imageUrl: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  description,
  imageUrl,
}) => {
  
  const handlePageHeaderRedirect = () => {
    const target = document.getElementById("quote-form");
  
    if (target) {
      const targetPosition = target.getBoundingClientRect().top + window.scrollY;

      const headerHeight = 50; 

      window.scrollTo({ top: targetPosition - headerHeight, behavior: "smooth" });
    } else {
      localStorage.setItem("scrollToQuoteForm", "true");
      window.location.href = "/free-quote";
    }
  };
/*   usePreserveCallUsText(); */

  return (
    <div
      className="relative text-white py-4 bg-gradient-to-b from-[#024772] md:via-[#3e6f8f] md:to-[#e5e9ec] md:bg-gradient-to-r md:from-[#024772] md:via-[#3e6f8f] md:to-[#e5e9ec]"
    >
      <div className="container mx-auto px-6 flex flex-col md:flex-row items-center">
        {/* Texto */}
        <div className="text-center md:text-start md:w-1/2 p-6 animate-fade-in">
          <h1 className="text-3xl md:text-4xl font-bold leading-snug mb-4">
            {title}
          </h1>
          <p className="text-lg hidden md:block md:text-lg leading-relaxed mb-6">
            {description}
          </p>
          <div className="mt-8 md:mt-0 flex gap-2 justify-items-center items-center">
            <button
                onClick={handlePageHeaderRedirect}
                className="w-full lg:w-fit bg-white text-blue-600 px-2 py-2 rounded-lg font-semibold hover:bg-green-600 hover:text-white transition whitespace-nowrap"
              >
                Get a Quote Now
            </button>
            <a
              id="call-us-button"
              href="tel:+18884442554"
              className="w-full lg:w-fit bg-white text-blue-600 px-2 py-2 rounded-lg font-semibold hover:bg-green-600 hover:text-white transition lg:hidden whitespace-nowrap"
              data-phone-number="true"
              data-number="1 (888) 444-2554"
            >
              <p>Call Us</p>
            </a>
          </div>
        </div>

        {/* Imagen */}
        <div className="md:w-1/2 p-6 flex justify-center items-center">
          <img
            src={imageUrl}
            alt={title}
            className="max-w-full md:max-w-md rounded-lg object-cover animate-slide-in md:ml-10 mb-4"
            id="header-image" loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
