import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import CallToAction from "../../../components/CallToAction";
import SkypeImage from "../../../../src/assets/images/vectors/skype.png";
import OnsiteImage1 from "../../../../src/assets/images/adobe/adobe1.webp";
import OnsiteImage2 from "../../../../src/assets/images/adobe/ESL.webp";
import OnsiteImage3 from "../../../../src/assets/images/adobe/adobe13.webp";
import { FaPuzzlePiece, FaUserTie } from "react-icons/fa6";
import { FaCogs } from "react-icons/fa";
import Helmet from "react-helmet";

const Skype: React.FC = () => {
  const skypeBenefits = [
    {
      icon: FaUserTie,
      title: "Experienced Professionals",
      text: "Our interpreters are trained to handle diverse communication needs, from business negotiations to personal consultations.",
    },
    {
      icon: FaCogs,
      title: "Technical Expertise",
      text: "We provide guidance on setting up interpretation services within Skype, ensuring a smooth process for all participants.",
    },
    {
      icon: FaPuzzlePiece,
      title: "Customized Solutions",
      text: "Tailored interpretation services for your specific needs, whether it’s a small meeting or a virtual event.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Reliable Skype Interpretation US</title>
        <meta
          name="description"
          content="Trusted Interpreters delivers professional Skype interpretation nationwide."
        />
      </Helmet>
      <PageHeader
        title="Reliable Skype Interpretation Services for Your Business Needs"
        description="Trusted Interpreters offers professional Skype interpretation services, enabling seamless multilingual communication for virtual meetings, consultations, and events. With expert interpreters fluent in over 200 languages, we ensure your message is delivered accurately and effectively across language barriers."
        imageUrl={SkypeImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="What is Skype Interpretation?"
          content="Skype interpretation involves real-time language translation during virtual sessions on the Skype platform. This service is ideal for businesses and individuals looking to connect with global audiences or multilingual teams in an accessible and affordable way."
          imageUrl={OnsiteImage1}
          direction="right"
        />

        {/* Workflow */}
<ProcessWorkflow process="virtual" />


        <ContentSection
          title="Industries We Serve with Skype Interpretation"
          content="Trusted Interpreters supports various industries and use cases through Skype, including:"
          contentList={[
            {
              text: "Corporate: Facilitate multilingual team meetings, interviews, and client calls.",
              links: [
                {
                  linkText: "Corporate",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Education: Support one-on-one or small group virtual learning sessions.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
            {
              text: "Healthcare: Enable remote patient consultations with precise interpretation.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal: Provide interpretation for remote legal consultations or depositions.",
              links: [
                {
                  linkText: "Legal",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>
        <ContentSection
          title="Benefits of Skype Interpretation:"
          contentList={[
            {
              text: "Cost-Effective: Ideal for small- to medium-sized businesses or personal use.",
            },
            {
              text: "User-Friendly: Works with Skype’s straightforward interface for a hassle-free experience.",
            },
            {
              text: "Flexible Solutions: Perfect for one-on-one meetings, interviews, or group calls.",
            },
          ]}
          additionalContent="Our Skype interpretation services are available throughout the U.S. and internationally. Trusted Interpreters connects you with expert interpreters wherever you need them, ensuring clear and effective communication."
          imageUrl={OnsiteImage3}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Trusted Interpreters: Your Partner for Skype">
          <KeyBenefits benefits={skypeBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Trusted Interpreters Today"
          description="Make your Skype meetings more inclusive and efficient with our professional interpretation services. Contact Trusted Interpreters now to discuss your needs and schedule an interpreter for your next call or event."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Skype;