import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import OnsiteImage2 from "../../../src/assets/images/adobe/onsite-img-2.webp";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import { Helmet } from "react-helmet";
// images
import BostonImage from "../../../src/assets/images/adobe/Boston.webp";
import PeopleImage from "../../../src/assets/images/adobe/adobe17.webp";
import PeopleImage1 from "../../../src/assets/images/adobe/medical2.webp";

const Boston: React.FC = () => {
  const bostonBenefits = [
    {
      icon: FaAward,
      title: "Experienced Professionals",
      text: "We ensure accurate communication while respecting cultural nuances.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Understanding",
      text: "Our interpreters are certified, trained, and fluent in over 200 languages.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in Boston, delivering consistent support wherever needed.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services Boston</title>
        <meta
          name="description"
          content="Trusted Interpreters delivers accurate interpretation services in Boston."
        />
      </Helmet>
      <PageHeader
        title="Professional Interpretation Services in Boston"
        description="Trusted Interpreters offers expert language solutions tailored to the diverse industries and communities in Boston. With certified interpreters fluent in over 200 languages, we deliver accurate and culturally sensitive communication to help you succeed."
        imageUrl={BostonImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Boston Chooses Trusted Interpreters"
          contentList={[
            {
              text: "Expert Interpreters: Our team of certified professionals is fluent in over 200 languages, equipped to handle any industry-specific terminology.",
            },
            {
              text: "Tailored Solutions: We provide customized services, whether for one-time events or ongoing interpretation needs.",
              links: [
                {
                  linkText: "one-time events",
                  link: "/interpretation-types/phone-interpretation/one-time-service",
                },
                {
                  linkText: "ongoing interpretation",
                  link: "/interpretation-types/phone-interpretation/monthly-recurrent-service",
                },
              ],
            },
            {
              text: "Local Focus: With an understanding of Boston’s diverse communities, we deliver culturally aware and precise communication.",
            },
          ]}
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
        <ProcessWorkflow process="presencial" />

        <ContentSection
          title="Specialized Language Solutions"
          contentList={[
            {
              text: "Precision On-Site Interpretation: For in-person meetings, legal proceedings, medical consultations, or corporate events, our interpreters ensure seamless, face-to-face communication.",
              links: [
                {
                  linkText: "Precision On-Site Interpretation",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "corporate events",
                  link: "/expertise/business-interpretation",
                },
                {
                  linkText: "legal proceedings",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "medical consultations",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Immediate Phone Interpretation: Get instant access to professional interpreters for urgent communication needs. Available 24/7, our phone services ensure you’re never without language support.",
              links: [
                {
                  linkText: "Immediate Phone Interpretation",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Real-Time Virtual Interpretation: Our video remote interpretation (VRI) services provide smooth multilingual communication for webinars, hybrid meetings, and virtual events.",
              links: [
                {
                  linkText: "Real-Time Virtual Interpretation",
                  link: "/interpretation-types/vri",
                },
                {
                  linkText: "(VRI)",
                  link: "/interpretation-types/vri",
                },
                {
                  linkText: "virtual events",
                  link: "/interpretation-types/vri/zoom-interpretation-services",
                },
              ],
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks />

        <ContentSection
          title="Supporting Boston’s Key Industries"
          contentList={[
            {
              text: "Healthcare: Facilitating patient-provider communication in hospitals, clinics, and private practices.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal Services: Providing court-certified interpreters for depositions, trials, and legal consultations.",
              links: [
                {
                  linkText: "Legal Services",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Corporate: Supporting global negotiations, team collaborations, and conferences.",
              links: [
                {
                  linkText: "Corporate",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Education: Enhancing communication in schools, universities, and training programs.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
          ]}
          additionalContent="From Downtown Boston to neighborhoods like Back Bay, South End, and Cambridge, Trusted Interpreters provides language services tailored to your needs, wherever you are in the greater Boston area."
          imageUrl={PeopleImage1}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={bostonBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Let’s Bridge the Language Gap"
          description="Unlock seamless communication with Trusted Interpreters. Contact us today to learn more about our professional interpretation services in Boston and how we can support your success."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Boston;
