import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import OnsiteImage2 from "../../../src/assets/images/adobe/adobe18.webp";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import Helmet from "react-helmet";
// images
import IndianapolisImage from "../../../src/assets/images/adobe/Indianapolis.webp";
import PeopleImage from "../../../src/assets/images/adobe/onsite-img.webp";
import PeopleImage1 from "../../../src/assets/images/adobe/charlotte2.webp";

const Indianapolis: React.FC = () => {
  const indianapolisBenefits = [
    {
      icon: FaAward,
      title: "Experienced Professionals",
      text: "We ensure accurate communication while respecting cultural nuances.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Understanding",
      text: "Our interpreters are certified, trained, and fluent in over 200 languages.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in Indianapolis, delivering consistent support wherever needed.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services Indianapolis</title>
        <meta
          name="description"
          content="Trusted Interpreters offers accurate interpretation services in Indianapolis."
        />
      </Helmet>
      <PageHeader
        title="Professional Interpretation Services in Indianapolis for Clear Communication"
        description="Trusted Interpreters delivers expert language solutions for businesses, healthcare providers, legal professionals, and educators across Indianapolis. With certified interpreters fluent in over 200 languages, we ensure precise, culturally sensitive communication tailored to your needs."
        imageUrl={IndianapolisImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Choose Trusted Interpreters in Indianapolis?"
          contentList={[
            {
              text: "Certified Experts: Our interpreters are fluent in over 200 languages and specialize in industry-specific terminology to ensure accurate and culturally sensitive communication.",
            },
            {
              text: "Flexible Options: From on-site services to virtual and phone-based solutions, we offer interpretation tailored to your unique needs.",
              links: [
                {
                  linkText: "on-site services",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "virtual",
                  link: "/interpretation-types/vri",
                },
                {
                  linkText: "phone-based",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Local Understanding: We are familiar with Indianapolis’s industries and communities, making us the ideal partner for effective communication.",
            },
          ]}
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />

        
        <ContentSection
          title="Interpretation Services Designed for You"
          contentList={[
            {
              text: "On-Site Language Support: For in-person events like corporate meetings, legal proceedings, or medical consultations, our interpreters ensure precise and culturally aware communication, tailored to your specific needs.",
              links: [
                {
                  linkText: "On-Site Language Support",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "corporate meetings",
                  link: "/expertise/business-interpretation",
                },
                {
                  linkText: "legal proceedings",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "medical consultations",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Over-the-Phone Interpretation: When immediate assistance is required, our phone interpretation services provide 24/7 access to experienced linguists for real-time communication.",
              links: [
                {
                  linkText: "Over-the-Phone Interpretation",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Video Remote Interpretation (VRI): Ideal for virtual meetings, webinars, and hybrid events, our VRI services deliver seamless, real-time multilingual support in digital environments.",
              links: [
                {
                  linkText: "Video Remote Interpretation (VRI)",
                  link: "/interpretation-types/vri",
                },
                {
                  linkText: "virtual meetings",
                  link: "/interpretation-types/vri/google-meet-interpretation-services",
                },
              ],
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks/>

        <ContentSection
          title="Industries We Serve in Indianapolis"
          contentList={[
            {
              text: "Healthcare: Helping providers and patients communicate effectively in clinics, hospitals, and private practices.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal: Offering court-certified interpreters for trials, depositions, and consultations.",
              links: [
                {
                  linkText: "Legal",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Corporate: Facilitating international negotiations, training sessions, and conferences.",
              links: [
                {
                  linkText: "Corporate",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Education: Bridging language gaps in schools, universities, and training programs.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
          ]}
          additionalContent="From downtown Indianapolis to neighborhoods like Broad Ripple, Fountain Square, and surrounding areas like Carmel and Greenwood, Trusted Interpreters provides reliable interpretation services wherever you need them."
          imageUrl={PeopleImage1}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={indianapolisBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Trusted Interpreters Today"
          description="Overcome language barriers and achieve effective communication with ease. Contact Trusted Interpreters today to learn more about our professional interpretation services in Indianapolis and how we can support your goals."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Indianapolis;
