import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import OnsiteImage2 from "../../../src/assets/images/adobe/adobe9.webp";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import Helmet from "react-helmet";
// images
import DenverImage from "../../../src/assets/images/adobe/Denver.webp";
import PeopleImage from "../../../src/assets/images/adobe/adobe8.webp";
import PeopleImage1 from "../../../src/assets/images/adobe/LA2.webp";

const Denver: React.FC = () => {
  const denverBenefits = [
    {
      icon: FaAward,
      title: "Experienced Professionals",
      text: "We ensure accurate communication while respecting cultural nuances.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Understanding",
      text: "Our interpreters are certified, trained, and fluent in over 200 languages.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in Denver, delivering consistent support wherever needed.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services Denver</title>
        <meta
          name="description"
          content="Trusted Interpreters provides accurate interpretation services in Denver."
        />
      </Helmet>
      <PageHeader
        title="Expert Interpretation Services in Denver for All Industries"
        description="Trusted Interpreters provides professional interpretation solutions tailored to meet the diverse needs of Denver’s industries and communities. With services designed to support businesses, healthcare providers, legal professionals, and educational institutions, we help break language barriers and foster clear communication throughout the Denver metropolitan area."
        imageUrl={DenverImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Trusted Interpreters is the Right Choice in Denver"
          contentList={[
            {
              text: "Certified Professionals: Our interpreters are fluent in over 200 languages and bring industry-specific expertise to every assignment.",
            },
            {
              text: "Custom-Tailored Services: From one-time events to ongoing projects, we adapt our offerings to meet your unique needs.",
              links: [
                {
                  linkText: "one-time events",
                  link: "/interpretation-types/phone-interpretation/one-time-service",
                },
                {
                  linkText: "ongoing projects",
                  link: "/interpretation-types/phone-interpretation/monthly-recurrent-service",
                },
              ],
            },
            {
              text: "Local Understanding: We know Denver’s industries and communities, delivering culturally aware and accurate communication.",
            },
          ]}
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />

        
        <ContentSection
          title="Tailored Interpretation Services"
          contentList={[
            {
              text: "On-Site Interpretation for Key Events: Our interpreters provide in-person support for legal proceedings, medical consultations, business meetings, and conferences, ensuring clear and accurate communication.",
              links: [
                {
                  linkText: "On-Site Interpretation for Key Events",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "business meetings",
                  link: "/expertise/business-interpretation",
                },
                {
                  linkText: "legal proceedings",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "medical consultations",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "24/7 Phone Interpretation: For urgent or remote communication needs, our phone interpretation services connect you instantly with experienced linguists, available around the clock.",
              links: [
                {
                  linkText: "24/7 Phone Interpretation",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Seamless Virtual Interpretation: For virtual meetings, webinars, and hybrid events, our video remote interpretation (VRI) services provide real-time multilingual support for any digital platform.",
              links: [
                {
                  linkText: "Seamless Virtual Interpretation",
                  link: "/interpretation-types/vri",
                },
                {
                  linkText: "video remote interpretation (VRI)",
                  link: "/interpretation-types/vri",
                },
                {
                  linkText: "virtual meetings",
                  link: "/interpretation-types/vri/zoom-interpretation-services",
                },
              ],
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks/>

        <ContentSection
          title="Industries We Serve in Denver"
          contentList={[
            {
              text: "Healthcare: Supporting patient-provider communication in hospitals, clinics, and private practices.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal Services: Providing court-certified interpreters for trials, depositions, and consultations.",
              links: [
                {
                  linkText: "Legal Services",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Corporate: Facilitating international negotiations, corporate training, and team collaborations.",
              links: [
                {
                  linkText: "Corporate",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Education: Bridging language gaps in schools, universities, and training programs.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
          ]}
          additionalContent="From Downtown Denver to neighborhoods like Cherry Creek, RiNo, and Capitol Hill, Trusted Interpreters offers professional interpretation services throughout the Denver area and surrounding suburbs."
          imageUrl={PeopleImage1}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={denverBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Trusted Interpreters Today"
          description="Ensure clear communication in any setting with the help of Trusted Interpreters. Contact us today to learn more about our expert interpretation services in Denver and how we can assist you."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Denver;
