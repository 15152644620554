import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import OnsiteImage2 from "../../../src/assets/images/adobe/onsite-img-2.webp";
import { FaCertificate } from "react-icons/fa6";
import { FaGlobeAmericas, FaMapMarkedAlt } from "react-icons/fa";
import Helmet from "react-helmet";
// images
import FrenchImage from "../../../src/assets/images/vectors/french.png";
import PeopleImage from "../../../src/assets/images/adobe/adobe20.webp";
import PeopleImage1 from "../../../src/assets/images/adobe/Philadelphia1.webp";

const FrenchExpertise: React.FC = () => {
  const frenchBenefits = [
    {
      icon: FaCertificate,
      title: "Certified Professionals",
      text: "Our interpreters are experienced, certified, and fluent in all forms of French.",
    },
    {
      icon: FaGlobeAmericas,
      title: "Tailored Solutions",
      text: "We customize our interpretation services to meet the specific needs of your event or project.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Accessibility",
      text: "Trusted Interpreters provides French interpretation services across the U.S., ensuring availability wherever you are.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Expert French Interpretation US</title>
        <meta
          name="description"
          content="Trusted Interpreters provides reliable French interpretation across the U.S."
        />
      </Helmet>
      <PageHeader
        title="Expert French Interpretation Solutions Tailored to Your Needs"
        description="Trusted Interpreters provides high-quality French interpretation services, offering seamless communication for French-speaking audiences in business, legal, medical, and educational settings. Our interpreters are skilled in both European and Canadian French, ensuring culturally accurate and precise translations."
        imageUrl={FrenchImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why French Interpretation is Essential"
          content="French is one of the most widely spoken languages globally, with significant influence in international business, diplomacy, and culture. Whether you’re collaborating with French-speaking clients or serving French-speaking communities, our interpreters help you break language barriers effortlessly."
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Practical Uses of Our French Interpretation Services"
          content="We tailor our services to fit a wide range of real-world scenarios:"
          contentList={[
            {
              text: "International Negotiations: Ensure clarity and professionalism during business meetings or trade discussions.",
              links: [
                {
                  linkText: "International Negotiations",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Community Engagement: Help organizations connect with French-speaking populations in outreach programs.",
              links: [
                {
                  linkText: "Community Engagement",
                  link: "/expertise/immigration-interpretation",
                },
              ],
            },
            {
              text: "Educational Settings: Support French-speaking students and families during academic events or parent-teacher conferences.",
              links: [
                {
                  linkText: "Educational Settings",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
            {
              text: "Healthcare Communication: Facilitate accurate patient-provider interactions for French-speaking individuals.",
              links: [
                {
                  linkText: "Healthcare Communication",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal Proceedings: Provide precise interpretations for depositions, hearings, and client consultations.",
              links: [
                {
                  linkText: "Legal Proceedings",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks currentLanguage="French"/>

        <ContentSection
          title="What Sets Our French Interpretation Apart:"
          contentList={[
            {
              text: "Dialect Expertise: Skilled in European French, Canadian French, and regional nuances.",
            },
            {
              text: "Real-Time Support: Available for on-site, over-the-phone, or virtual meetings.",
            },
            {
              text: "Cultural Understanding: Our interpreters ensure not only linguistic accuracy but also cultural appropriateness.",
            },
          ]}
          additionalContent="With interpreters located across the U.S., Trusted Interpreters ensures that French interpretation services are available wherever you need them. Whether for a local meeting or a nationwide event, we provide reliable solutions to suit your needs."
          imageUrl={PeopleImage1}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={frenchBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Let Trusted Interpreters Simplify Your Communication"
          description="Enhance your interactions with French-speaking audiences by partnering with Trusted Interpreters. Contact us today to schedule an interpreter and discover how our services can make a difference for your organization."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default FrenchExpertise;