import React from "react";
import RequestImg from "../../src/assets/images/adobe/request.png";
import RequestImg1 from "../../src/assets/images/adobe/request1.png";
import CityImage from "../../src/assets/images/adobe/city-img.webp";
import InterpreterImage from "../../src/assets/images/adobe/interpreter.webp";
import GreetingImage from "../../src/assets/images/adobe/greeting-img.webp";

interface ProcessWorkflowProps {
  process: "presencial" | "virtual";
}

const ProcessWorkflow: React.FC<ProcessWorkflowProps> = ({ process }) => {
  const presencialSteps = [
    {
      title: "Submit Your Request",
      description:
        "Provide details about your project, including location, time, and required language.",
      illustration: RequestImg1,
    },
    {
      title: "Schedule Time and Location",
      description: "We coordinate the time and place to meet your needs.",
      illustration: CityImage,
    },
    {
      title: "Interpreter Arrives",
      description:
        "Our professional interpreter arrives at the agreed location and provides live interpretation.",
      illustration: InterpreterImage,
    },
    {
      title: "Feedback and Support",
      description:
        "Share your experience and receive ongoing support for future needs.",
      illustration: GreetingImage,
    },
  ];

  const virtualSteps = [
    {
      title: "Submit Your Request",
      description:
        "Provide details about your project, including the time and required language.",
      illustration: RequestImg1,
    },
    {
      title: "Schedule a Virtual Session",
      description:
        "We coordinate the time and provide a secure platform for the session.",
      illustration: CityImage,
    },
    {
      title: "Feedback and Support",
      description:
        "Share your experience and receive ongoing support for future needs.",
      illustration: GreetingImage,
    },
  ];

  const steps = process === "presencial" ? presencialSteps : virtualSteps;

  return (
    <section className="px-6 py-6 mb-10 mt-4">
      {/* Encabezado */}
      <div className="container mx-auto text-center mb-12">
        <h2 className="text-4xl font-bold text-gray-800 mb-4">
          How is the Interpretation Process?
        </h2>
        <p className="text-lg text-gray-600">
          Follow these simple steps to ensure seamless communication.
        </p>
      </div>

      {/* Tarjetas */}
      <div
        className={`grid gap-8 px-4 lg:px-0 ${
          steps.length === 3
            ? "grid-cols-1 md:grid-cols-3 justify-items-center"
            : "grid-cols-1 md:grid-cols-2 lg:grid-cols-4"
        }`}
      >
        {steps.map((step, index) => (
          <div
            key={index}
            className="bg-white rounded-lg shadow-lg overflow-hidden transition hover:shadow-xl"
          >
            <img
              src={step.illustration}
              alt={step.title}
              className="w-full h-40 object-cover"
            />
            <div className="p-6">
              <h3 className="text-lg font-semibold text-gray-800 mb-2">
                {step.title}
              </h3>
              <p className="text-gray-600 text-base">{step.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ProcessWorkflow;
