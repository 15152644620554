import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import SubsectionNavigator from "../../../components/SubsectionNavigator";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import CallToAction from "../../../components/CallToAction";
import {
  FaUserShield,
  FaClock,
  FaHandshake,
  FaPhoneAlt,
  FaCalendarCheck,
} from "react-icons/fa";
import OnsiteImage from "../../../../src/assets/images/vectors/over-the-phone.png";
import OnsiteImage1 from "../../../../src/assets/images/adobe/meeting.webp";
import OnsiteImage2 from "../../../../src/assets/images/adobe/Legal1.webp";
import OnsiteImage3 from "../../../../src/assets/images/adobe/LA.webp";
import Helmet from "react-helmet";


const OverThePhone: React.FC = () => {
  const OPIBenefits = [
    {
      icon: FaUserShield,
      title: "Confidentiality Guaranteed",
      text: "All interpreters are bound by confidentiality agreements and follow strict interpreting codes of ethics.",
    },
    {
      icon: FaClock,
      title: "International Coverage",
      text: "Over 10,000 language experts available throughout the U.S. and abroad, ready to serve any location, language, or time.",
    },
    {
      icon: FaHandshake,
      title: "Sector Specialization",
      text: "Expert linguists specialized in legal, business, education, medical, technical, military, and other industries.",
    },
  ];

  const subsections = [
    {
      title: "One Time Service",
      description:
        "Quick and reliable interpretation for one-time calls or urgent needs.",
      icon: <FaPhoneAlt size={24} />,
      link: "/interpretation-types/phone-interpretation/one-time-service",
    },
    {
      title: "Monthly Recurrent Service",
      description:
        "Convenient and consistent interpretation service for ongoing communication needs.",
      icon: <FaCalendarCheck size={24} />,
      link: "/interpretation-types/phone-interpretation/monthly-recurrent-service",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Phone Interpretation in the US</title>
        <meta
          name="description"
          content="Connect with expert interpreters instantly through our phone interpretation services."
        />
      </Helmet>
      <PageHeader
        title="Reliable Over-the-Phone Interpretation Services in the U.S."
        description="Trusted Interpreters offers professional over-the-phone interpretation (OPI) services, connecting you with experienced interpreters for quick and accurate communication. Whether for a one-time need or recurring requirements, our phone interpretation services are available nationwide."
        imageUrl={OnsiteImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Instant Language Solutions"
          content="With Trusted Interpreters, you can access:"
          contentList={[
            {
              text: "24/7 Availability: We ensure you have interpretation support whenever you need it.",
            },
            {
              text: "Wide Language Coverage: Choose from over 200 languages and dialects to suit your needs.",
            },
            {
              text: "No Scheduling Required: Instantly connect with interpreters for immediate assistance.",
            },
            {
              text: "Affordable and Flexible Options.",
            },
          ]}
          imageUrl={OnsiteImage1}
          direction="right"
        />

        {/* Subsections */}
        <div className="container mx-auto px-6 py-6">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
            Explore On-Site Interpretation Services
          </h2>
          <SubsectionNavigator subsections={subsections} />
        </div>

        {/* Workflow */}
        <ProcessWorkflow process="virtual" />

        <ContentSection
          title="Tailored for Every Industry"
          content="Our over-the-phone interpretation services are ideal for various sectors, including:"
          contentList={[
            {
              text: "Healthcare: Bridging communication between patients and providers.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal: Ensuring accuracy during client consultations or proceedings.",
              links: [
                {
                  linkText: "Legal",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Business: Facilitating effective communication in meetings or negotiations.",
              links: [
                {
                  linkText: "Business",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <ContentSection
          title="Trusted by Clients Nationwide"
          content="With a network of skilled interpreters and cutting-edge telecommunications technology, Trusted Interpreters ensures exceptional service quality. All interpreters are bound by strict confidentiality agreements, adhering to industry standards of professionalism and ethics."
          imageUrl={OnsiteImage3}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Our Over-The-Phone (OPI) Interpretation?">
          <KeyBenefits benefits={OPIBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Get Connected Today"
          description="Overcome language barriers with ease. Contact Trusted Interpreters to learn more about our reliable over-the-phone interpretation services or to get started now."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default OverThePhone;
