import React from "react";
import { FaUserTie, FaCertificate } from "react-icons/fa";

const AboutUsExtras: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-16">
      <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
        Want to Know More About Us?
      </h2>

      <div className="flex flex-col md:flex-row justify-center gap-8">
        {/* Our Interpreters Card */}
        <div className="relative group w-full max-w-xs md:max-w-sm bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
          <div className="p-6">
            <FaUserTie className="text-[#024772] text-4xl mb-4" />
            <h3 className="text-xl font-bold text-gray-800">Our Interpreters</h3>
            <p className="text-gray-600 mt-2">
              Meet the experts behind our success.
            </p>
          </div>

          <div className="absolute inset-0 bg-[#024772] text-white p-6 flex flex-col justify-center items-center opacity-0 group-hover:opacity-100 transform translate-y-8 group-hover:translate-y-0 transition-all duration-300">
            <h3 className="text-lg font-bold mb-2">Our Interpreters</h3>
            <p className="text-base text-center mb-4 px-2">
              Our interpreters are certified professionals committed to
              delivering accurate and reliable interpretation services.
            </p>
            <a
              href="/about-us/our-interpreters"
              className="bg-white text-blue-600 text-sm px-3 py-1 rounded-lg shadow hover:bg-gray-200 transition"
            >
              Learn More
            </a>
          </div>
        </div>

        <div className="relative group w-full max-w-xs md:max-w-sm bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
          <div className="p-6">
            <FaCertificate className="text-[#024772] text-4xl mb-4" />
            <h3 className="text-xl font-bold text-gray-800">Certifications</h3>
            <p className="text-gray-600 mt-2">
              Discover our accreditations.
            </p>
          </div>

          <div className="absolute inset-0 bg-[#024772] text-white p-6 flex flex-col justify-center items-center opacity-0 group-hover:opacity-100 transform translate-y-8 group-hover:translate-y-0 transition-all duration-300">
            <h3 className="text-lg font-bold mb-2">Certifications</h3>
            <p className="text-base text-center mb-4 px-2">
              Learn about the certifications and standards that ensure our
              services meet the highest quality requirements.
            </p>
            <a
              href="/about-us/certifications"
              className="bg-white text-blue-600 text-sm px-3 py-1 rounded-lg shadow hover:bg-gray-200 transition"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsExtras;
