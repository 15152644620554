import React, { useEffect } from "react";
import QuoteForm from "../../components/QuoteForm";

const Quote: React.FC = () => {
  useEffect(() => {
    const shouldScroll = localStorage.getItem("scrollToQuoteForm");
    if (shouldScroll === "true") {
      localStorage.removeItem("scrollToQuoteForm");
      const target = document.getElementById("quote-form");
      if (target) {
        setTimeout(() => {
          target.scrollIntoView({ behavior: "smooth" });
        }, 300);
      }
    }
  }, []);

  return (
    <div id="quote-form">
        <QuoteForm />
    </div>
  );
};

export default Quote;