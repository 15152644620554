import React from "react";
import { FaUsers, FaMapMarkedAlt, FaTools, FaShieldAlt } from "react-icons/fa";

const WhatSetsUsApart: React.FC = () => {
  const benefits = [
    {
      title: "Expert Interpreters",
      description:
        "We work with certified professionals fluent in over 200 languages, specializing in various fields such as legal, medical, corporate, and education.",
      icon: <FaUsers size={30} />,
    },
    {
      title: "Nationwide Coverage",
      description:
        "With a network of interpreters across the U.S., we offer services wherever and whenever you need them.",
      icon: <FaMapMarkedAlt size={30} />,
    },
    {
      title: "Flexible Solutions",
      description:
        "From on-site interpretation to over-the-phone and video remote services, we adapt to meet your specific requirements.",
      icon: <FaTools size={30} />,
    },
    {
      title: "Commitment to Confidentiality",
      description:
        "All our interpreters adhere to strict confidentiality agreements and professional codes of ethics, ensuring your privacy and trust.",
      icon: <FaShieldAlt size={30} />,
    },
  ];

  return (
    <section className="py-6">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {/* Heading Section */}
        <div className="mb-10 text-center">
          <h2 className="text-4xl font-bold text-gray-900 leading-tight">
            What Sets Us Apart
          </h2>
          <p className="text-lg text-gray-500 mt-4">
            Discover why Trusted Interpreters is the premier choice for all your
            interpretation needs.
          </p>
        </div>

        {/* Benefits Section */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {benefits.map((benefit, index) => (
            <div
              key={index}
              className="group bg-gray-100 rounded-2xl p-6 xl:p-8 transition-all duration-500 hover:bg-[#024772]"
            >
              <div className="bg-white rounded-full flex justify-center items-center mb-5 w-14 h-14">
                {benefit.icon}
              </div>
              <h4 className="text-xl font-semibold text-gray-900 mb-3 capitalize transition-all duration-500 group-hover:text-white">
                {benefit.title}
              </h4>
              <p className="text-gray-600 leading-5 transition-all duration-500 group-hover:text-white">
                {benefit.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhatSetsUsApart;
