import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import CallToAction from "../../../components/CallToAction";
import { FaArrowsAlt, FaMapMarkedAlt } from "react-icons/fa";
import ImmigrationImage from "../../../../src/assets/images/vectors/immigration.png";
import ImmigrationImage1 from "../../../../src/assets/images/adobe/Houston1.webp";
import OnsiteImage1 from "../../../../src/assets/images/adobe/adobe4.webp";
import OnsiteImage2 from "../../../../src/assets/images/adobe/adobe22.webp";
import { FaUserGraduate } from "react-icons/fa6";

const ImmigrationInterpretation: React.FC = () => {
  const immigrationBenefits = [
    {
      icon: FaUserGraduate,
      title: "Specialized Expertise",
      text: "Our interpreters have in-depth knowledge of immigration terminology and processes.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Availability",
      text: "We provide services across the U.S., ensuring interpreters are available wherever they’re needed.",
    },
    {
      icon: FaArrowsAlt,
      title: "Flexible Options",
      text: "Choose from on-site, over-the-phone, or video remote interpretation to suit your specific requirements.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <PageHeader
        title="Reliable Interpretation Services for Immigration and Naturalization"
        description="Trusted Interpreters specializes in providing professional immigration interpretation services to ensure clear and accurate communication during critical immigration processes. Our experienced interpreters are well-versed in the unique requirements of immigration law and are available nationwide."
        imageUrl={ImmigrationImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Expert Immigration Interpreters"
          content="Our interpreters are:"
          contentList={[
            {
              text: "Experienced in Immigration Cases: Proficient in handling interviews, hearings, and documentation reviews.",
            },
            {
              text: "Culturally Sensitive: Understanding the nuances of diverse cultural backgrounds to ensure accurate interpretation.",
            },
            {
              text: "Ethical and Confidential: Bound by strict confidentiality agreements and professional ethical standards.",
            },
          ]}
          imageUrl={OnsiteImage1}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Immigration Interpretation Services We Offer"
          content="We provide interpretation solutions for a variety of immigration needs, including:"
          contentList={[
            {
              text: "USCIS Interviews: Assisting applicants during naturalization, green card, and asylum interviews.",
            },
            {
              text: "Immigration Court Hearings: Ensuring accurate communication during legal proceedings.",
            },
            {
              text: "Visa Application Assistance: Helping applicants navigate complex requirements and processes.",
            },
            {
              text: "Community Support: Facilitating communication for immigrant support programs and services.",
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <ContentSection
          title="Supporting Immigrants and Their Families"
          content="We understand that immigration processes can be stressful and complex. Trusted Interpreters is committed to providing reliable and professional interpretation services that help clients confidently navigate these crucial moments."
          imageUrl={ImmigrationImage1}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Our Immigration Interpretation Services?">
          <KeyBenefits benefits={immigrationBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Us Today"
          description="Ensure accurate communication during your immigration process. Contact Trusted Interpreters today to learn more about our immigration interpretation services or to schedule an interpreter for your next appointment."
          buttonText="Contact Us"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default ImmigrationInterpretation;