import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import OnsiteImage2 from "../../../src/assets/images/adobe/handshake.webp";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import Helmet from "react-helmet";
// images
import ItalianImage from "../../../src/assets/images/vectors/italian.png";
import PeopleImage from "../../../src/assets/images/adobe/Phoenix1.webp";
import PeopleImage1 from "../../../src/assets/images/adobe/adobe11.webp";

const ItalianExpertise: React.FC = () => {
  const italianBenefits = [
    {
      icon: FaAward,
      title: "Certified Professionals",
      text: "Our interpreters are highly trained and certified, ensuring reliable and precise interpretation.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Awareness",
      text: "We prioritize cultural nuances to ensure your message resonates effectively.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers Italian interpretation services across the U.S., delivering consistent support wherever needed.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Italian Interpretation Services US</title>
        <meta
          name="description"
          content="Trusted Interpreters provides accurate Italian interpretation services nationwide."
        />
      </Helmet>
      <PageHeader
        title="Professional Italian Interpretation Services Nationwide"
        description="Trusted Interpreters provides expert Italian interpretation services to bridge communication gaps and connect you with Italian-speaking clients, partners, and communities. Whether for business meetings, legal matters, or personal interactions, our certified interpreters ensure precise and culturally appropriate communication."
        imageUrl={ItalianImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="The Value of Italian Interpretation"
          content="Italian is not only a language of culture and history but also vital for international trade, tourism, and diplomacy. Accurate Italian interpretation can strengthen relationships, enhance understanding, and ensure inclusivity in professional and personal settings."
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="How Our Italian Interpretation Services Help"
          content="We provide tailored solutions for a variety of real-world applications:"
          contentList={[
            {
              text: "Business Collaboration: Foster strong relationships during meetings, negotiations, and presentations with Italian-speaking stakeholders.",
              links: [
                {
                  linkText: "Business Collaboration",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Cultural Events: Promote inclusivity and understanding at events with Italian-speaking participants or audiences.",
              links: [
                {
                  linkText: "Cultural Events",
                  link: "/expertise/immigration-interpretation",
                },
              ],
            },
            {
              text: "Educational Engagement: Enhance academic collaboration with Italian-speaking students, educators, or families.",
              links: [
                {
                  linkText: "Educational Engagement",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
            {
              text: "Healthcare Support: Help patients and providers communicate effectively during medical appointments and consultations.",
              links: [
                {
                  linkText: "Healthcare Support",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal Clarity: Ensure fairness and accuracy in legal proceedings involving Italian speakers.",
              links: [
                {
                  linkText: "Legal Clarity",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks currentLanguage="Italian"/>

        <ContentSection
          title="What Sets Our Italian Interpretation Services Apart:"
          contentList={[
            {
              text: "Dialects Covered: Expertise in regional variations, including Northern, Central, and Southern Italian dialects.",
            },
            {
              text: "Industry-Specific Knowledge: Interpreters skilled in business, healthcare, law, and more.",
            },
            {
              text: "Flexible Formats: Available for on-site, over-the-phone, and video remote interpretation.",
            },
          ]}
          additionalContent="With interpreters located across the U.S., Trusted Interpreters ensures that Italian interpretation services are available wherever you need them. Whether for a local meeting or a nationwide event, we provide reliable solutions to suit your needs."
          imageUrl={PeopleImage1}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={italianBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Ready to Connect with Italian-Speaking Audiences?"
          description="Let Trusted Interpreters help you break language barriers and communicate with confidence. Contact us today to schedule professional Italian interpretation services tailored to your needs."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default ItalianExpertise;