"use client"

/* eslint-disable jsx-a11y/anchor-is-valid */
import type React from "react"
import { useEffect, useState, useRef } from "react"
import { FaLinkedin, FaInstagram } from "react-icons/fa"
import { FaXTwitter } from "react-icons/fa6"
import Cookies from 'js-cookie';

const Footer: React.FC = () => {
  
  useEffect(() => {
/*     const loadCallRail = () => {
      const script = document.createElement("script")
      script.src = "//cdn.callrail.com/companies/264620413/5a552952aa7fdafcc9fe/12/swap.js"
      script.async = true
      script.onload = () => {
        
        setTimeout(() => {
          const phoneLinks = document.querySelectorAll('a[data-phone-number="true"]')
          phoneLinks.forEach((phoneLink) => {
            const currentHref = phoneLink.getAttribute("href")
            if (currentHref && currentHref.startsWith("tel:+")) {

              const correctedHref = correctPhoneNumber(currentHref)
              if (correctedHref !== currentHref) {
                phoneLink.setAttribute("href", correctedHref)

                const correctedNumberText = formatPhoneNumberForDisplay(correctedHref)
                phoneLink.textContent = correctedNumberText
              }
            }
          })
        }, 2000)
      }
      document.body.appendChild(script)
    }
    
    const correctPhoneNumber = (phoneNumber: string) => {
      
      const cleanedNumber = phoneNumber.replace(/\D/g, "");
      
      if (cleanedNumber.length === 11 && !cleanedNumber.startsWith("1")) {
        const correctedNumber = `tel:+1${cleanedNumber.slice(0, -1)}`;
        return correctedNumber;
      } else if (cleanedNumber.length === 10) {
        const correctedNumber = `tel:+1${cleanedNumber}`;
        return correctedNumber;
      } else if (cleanedNumber.length === 12 && cleanedNumber.startsWith("1")) {
        const correctedNumber = `tel:+${cleanedNumber}`;
        return correctedNumber;
      }
    
      return phoneNumber;
    }
    
    const formatPhoneNumberForDisplay = (phoneNumber: string) => {
      const cleanedNumber = phoneNumber.replace(/\D/g, "");
      if (cleanedNumber.length === 11) {
        return `+1 (${cleanedNumber.slice(1, 4)}) ${cleanedNumber.slice(4, 7)}-${cleanedNumber.slice(7)}`;
      } else if (cleanedNumber.length === 12) {
        return `+${cleanedNumber.slice(0, 2)} (${cleanedNumber.slice(2, 5)}) ${cleanedNumber.slice(5, 8)}-${cleanedNumber.slice(8)}`;
      }
      return phoneNumber;
    }
    
    loadCallRail() */
  }, [])
  
  return (
    <footer className="bg-gray-900 text-white">
      <div className="container text-center md:text-center mx-auto px-4 py-12 grid grid-cols-1 md:grid-cols-3 gap-8">
        <div>
          {/* Our Office */}
          <div className="text-center md:text-start mb-6">
            <h3 className="text-lg font-semibold mb-2">Main Office</h3>
            <p className="text-sm text-gray-300">
              <a
                href="https://maps.app.goo.gl/8ytiqWQFabVB46wz5"
                target="_blank"
                rel="noopener noreferrer"
                className="text-base hover:underline"
              >
                South Florida Central Office <br />
                66 West Flagler, Suite 1204 <br />
                Miami, FL, 33130, United States
              </a>
            </p>
          </div>
        </div>

        <div>
          <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
          <ul className="space-y-6">
            <li>
              Email:{" "}
              <a href="mailto:sales@trustedinterpreters.com" className="hover:underline">
                sales@trustedinterpreters.com
              </a>
            </li>
            <li className="flex items-center justify-center gap-2">
              <span>Phone:</span>
              <a
                href="tel:+18884442554"
                className="hover:underline"
                data-phone-number="true"
                data-number="1 (888) 444-2554"
              >
                1 (888) 444-2554
              </a>
            </li>
            <li>
              Social Media:
              <div className="flex justify-center md:justify-center space-x-8 md:space-x-4 mt-4">
                <a href="https://www.x.com/trinterpreters" aria-label="Twitter" className="hover:text-blue-600">
                  <FaXTwitter size={22} />
                </a>
                <a
                  href="https://www.linkedin.com/company/trusted-interpreters/"
                  aria-label="LinkedIn"
                  className="hover:text-blue-600"
                >
                  <FaLinkedin size={22} />
                </a>
                <a
                  href="https://www.instagram.com/trustedinterpreters/"
                  aria-label="Instagram"
                  className="hover:text-blue-600"
                >
                  <FaInstagram size={22} />
                </a>
              </div>
            </li>
          </ul>
        </div>
        
        <div>
          <h3 className="text-lg font-semibold mb-4">Other Services</h3>
          <ul className="grid grid-cols-2 md:grid-cols-1 space-y-0 md:space-y-4 gap-4 md:gap-0">
            <li>
              <a href="https://www.spanishtranslation.us/" className="hover:underline">
                Spanish Translation
              </a>
            </li>
            <li>
              <a href="https://trustedtranslations.com" className="hover:underline">
                Translation Services
              </a>
            </li>
            <li>
              <a href="https://www.24x7translations.com/" className="hover:underline">
                AI Translations
              </a>
            </li>
            <li>
              <a href="https://www.translationscertified.com/" className="hover:underline">
                Certified Translations
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* sub-footer */}
      <div className="bg-gray-800 py-4 text-center text-sm text-gray-400">
        <span>
          &copy; 2025 Trusted Interpreters. All rights reserved.{" "}
          <a href="/sitemap.xml" className="hover:underline">
            Sitemap
          </a>{" "}
          |{" "}
          <a href="/terms-and-conditions" className="hover:underline">
            Terms and Conditions
          </a>{" "}
          |{" "}
          <a href="/privacy-policy" className="hover:underline">
            Privacy Policy
          </a>
        </span>
      </div>
    </footer>
  )
}

export default Footer