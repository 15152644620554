import React from "react";
import Footer from "./Footer";

const Thanks: React.FC = () => {
  return (
    <div className="bg-white py-6">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
            Thank You for Contacting Us
        </h2>
        <p className="text-xl text-center text-gray-600 mb-12">
            One of our expert sales representatives will contact you immediately to discuss your project. <br />
            If you have any additional questions about our service, please do not hesitate to contact us at: <br />
            <a href="mailto:sales@trustedinterpreters.com" className="hover:underline">
                sales@trustedinterpreters.com
              </a> or  
              <a href="tel:+18884442554" className="hover:underline">
                +1 (888) 444-2554
              </a> <br />
            We look forward to the possibility of working with you.
        </p>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Thanks;