import React from "react";
import { Link } from "react-router-dom";

interface ContentSectionProps {
  title: string;
  content?: string;
  contentLinks?: { linkText: string; link: string }[];
  contentList?: { text: string; links?: { linkText: string; link: string }[] }[];
  additionalContent?: string;
  imageUrl?: string;
  direction?: "left" | "right";
}

const ContentSection: React.FC<ContentSectionProps> = ({
  title,
  content,
  contentLinks,
  contentList,
  additionalContent,
  imageUrl,
  direction = "left",
}) => {
  const isTextLeft = direction === "left";

  // Función para renderizar el texto con múltiples enlaces
  const renderTextWithLinks = (
    text: string,
    links?: { linkText: string; link: string }[]
  ) => {
    if (!links || links.length === 0) return text;
  
    let result: (string | JSX.Element)[] = [text];
  
    links.forEach(({ linkText, link }, linkIndex) => {
      result = result.flatMap((part, partIndex) =>
        typeof part === "string"
          ? part.split(linkText).flatMap((splitText, splitIndex, array) =>
              splitIndex < array.length - 1
                ? [
                    splitText,
                    <Link
                      key={`link-${linkIndex}-${partIndex}-${splitIndex}`}
                      to={link}
                      className="text-blue-600 underline hover:text-blue-800"
                    >
                      {linkText}
                    </Link>,
                  ]
                : splitText
            )
          : part
      );
    });
  
    return <>{result}</>;
  };
  

  return (
    <div className="container mx-auto px-6 py-4 mb-4 mt-4">
      <div
        className={`flex flex-col lg:flex-row items-center lg:text-start ${
          isTextLeft ? "lg:flex-row" : "lg:flex-row-reverse"
        } bg-white shadow-lg rounded-lg overflow-hidden`}
      >
        {/* Texto */}
        <div className="lg:w-1/2 p-6">
          <h2 className="text-3xl text-center lg:text-start font-bold text-gray-800 mb-4 px-4">{title}</h2>

          {content && (
            <p className="text-lg text-gray-600 leading-relaxed mb-4 px-4">
              {renderTextWithLinks(content, contentLinks)}
            </p>
          )}

          {contentList && (
            <ul className="text-lg text-gray-600 leading-relaxed list-disc mb-4 px-4 space-y-2">
              {contentList.map((item, index) => (
                <li key={index}>{renderTextWithLinks(item.text, item.links)}</li>
              ))}
            </ul>
          )}

          {additionalContent && <p className="text-lg text-gray-600 leading-relaxed mb-4 px-4">{additionalContent}</p>}
        </div>

        {/* Imagen */}
        {imageUrl && (
          <div className="lg:w-1/2">
            <img src={imageUrl} alt={title} className="object-cover w-full h-full" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ContentSection;
