import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import { Helmet } from "react-helmet";
// images
import SanJoseImage from "../../../src/assets/images/adobe/SanJose.webp";
import PeopleImage from "../../../src/assets/images/adobe/people-img.webp";
import OnsiteImage2 from "../../../src/assets/images/adobe/onsite-img-2.webp";
import sanJose1 from  "../../../src/assets/images/adobe/sanjose1.webp";
import sanJose2 from  "../../../src/assets/images/adobe/sanjose2.webp";

const SanJose: React.FC = () => {
  const sanJoseBenefits = [
    {
      icon: FaAward,
      title: "Experienced Professionals",
      text: "We ensure accurate communication while respecting cultural nuances.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Understanding",
      text: "Our interpreters are certified, trained, and fluent in over 200 languages.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in San Jose, delivering consistent support wherever needed.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services San Jose</title>
        <meta
          name="description"
          content="Trusted Interpreters provides professional interpretation services in San Jose."
        />
      </Helmet>

      <PageHeader
        title="Reliable Interpretation Services for San Jose Businesses and Events"
        description="As a hub for innovation and technology, San Jose brings together a diverse array of cultures and industries. At Trusted Interpreters, we are committed to breaking language barriers, ensuring effective communication for businesses, healthcare providers, legal professionals, and educational institutions in the heart of Silicon Valley."
        imageUrl={SanJoseImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Choose Trusted Interpreters in San Jose?"
          contentList={[
            {
              text: "Certified Experts: Our interpreters are fluent in over 200 languages, with specialized knowledge in a variety of fields.",
            },
            {
              text: "Flexible Solutions: From on-site interpretation to virtual and phone-based services, we tailor our offerings to suit your needs.",
              links: [
                {
                  linkText: "on-site interpretation",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "phone-based",
                  link: "/interpretation-types/phone-interpretation",
                },
                {
                  linkText: "virtual",
                  link: "/interpretation-types/vri",
                },
              ],
            },
            {
              text: "Local Focus: We understand the unique dynamics of San Jose’s multilingual population and industries.",
            },
          ]}
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
        <ProcessWorkflow process="presencial" />

        <ContentSection
          title="Services Designed for San Jose"
          contentList={[
            {
              text: "On-Site Interpretation: For conferences, legal matters, and in-person meetings, our interpreters provide accurate and culturally sensitive communication at your location.",
              links: [
                {
                  linkText: "On-Site Interpretation",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "legal matters",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "in-person meetings",
                  link: "/interpretation-types/on-site/simultaneous",
                },
              ],
            },
            {
              text: "Over-the-Phone Interpretation: Need assistance quickly? Our on-demand phone interpretation connects you with professional linguists 24/7.",
              links: [
                {
                  linkText: "Over-the-Phone Interpretation",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Video Remote Interpretation (VRI): Perfect for virtual events and hybrid meetings, our VRI services ensure seamless communication across digital platforms in real time.",
              links: [
                {
                  linkText: "Video Remote Interpretation (VRI)",
                  link: "/interpretation-types/vri",
                },
              ],
            },
          ]}
          imageUrl={sanJose1}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks />

        <ContentSection
          title="Key Industries We Support"
          contentList={[
            {
              text: "Technology and Innovation: Supporting communication in tech conferences, meetings, and collaborations.",
              links: [
                {
                  linkText: "Technology and Innovation",
                  link: "/expertise/technology-interpretation",
                },
              ],
            },
            {
              text: "Healthcare: Ensuring effective dialogue between patients and providers in hospitals and clinics.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal Services: Providing certified interpreters for court hearings, depositions, and consultations.",
              links: [
                {
                  linkText: "Legal Services",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Education: Assisting schools and universities with multilingual communication for students and faculty.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
          ]}
          additionalContent="From Downtown San Jose to neighborhoods like Willow Glen and surrounding areas like Palo Alto and Mountain View, Trusted Interpreters delivers reliable language solutions throughout Silicon Valley."
          imageUrl={sanJose2}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={sanJoseBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Trusted Interpreters Today"
          description="Clear communication drives success. Partner with Trusted Interpreters for expert interpretation services in San Jose and let us help you bridge language gaps effortlessly."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default SanJose;
