import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import SubsectionNavigator from "../../../components/SubsectionNavigator";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import CallToAction from "../../../components/CallToAction";
import { FaAmericanSignLanguageInterpreting, FaGlobeAmericas, FaCalendarAlt } from "react-icons/fa";
// import ASLImage from "../../../../src/assets/images/adobe/ASL.webp";
import ASLImage from "../../../../src/assets/images/vectors/asl.png";
import ASLImage1 from "../../../../src/assets/images/adobe/ASL1.webp";
import ASLImage2 from "../../../../src/assets/images/adobe/ASL2.webp";
import ASLImage3 from "../../../../src/assets/images/adobe/greeting-img.webp";
import { FaAward, FaCertificate, FaHandsClapping, FaLock, FaUserTie } from "react-icons/fa6";
import Helmet from "react-helmet";

const HomeASL: React.FC = () => {
  const ASLBenefits = [
    {
      icon: FaAward,
      title: "Certified Professionals",
      text: "Our ASL interpreters are certified by RID, ensuring the highest standards of accuracy and professionalism.",
    },
    {
      icon: FaGlobeAmericas,
      title: "Nationwide Availability",
      text: "Our interpreters are available across the U.S., providing services for urban and rural locations alike.",
    },
    {
      icon: FaUserTie,
      title: "Specialized Expertise",
      text: "We match interpreters with the specific needs of your industry or event, ensuring seamless communication.",
    },
    {
      icon: FaLock,
      title: "Commitment to Confidentiality",
      text: "All interpreters adhere to strict confidentiality agreements and ethical standards, ensuring your information remains secure.",
    },
    {
      icon: FaCalendarAlt,
      title: "Adaptable Scheduling",
      text: "From short meetings to multi-day events, our services are tailored to your requirements.",
    },
  ];

  const subsections = [
    {
      title: "American Sign Language (ASL)",
      description: "Professional interpretation in American Sign Language (ASL) for clear, accessible communication.",
      icon: <FaAmericanSignLanguageInterpreting size={24} />,
      link: "/interpretation-types/asl-interpretation/american-sign-language",
    },
    {
      title: "English Sign Language (ESL)",
      description: "Accurate interpretation in English Sign Language (ESL) for effective communication.",
      icon: <FaHandsClapping size={24} />,
      link: "/interpretation-types/asl-interpretation/english-sign-language",
    },
    {
      title: "RID-Certified ASL",
      description: "RID-Certified ASL interpretation for clear communication.",
      icon: <FaCertificate size={24} />,
      link: "/interpretation-types/asl-interpretation/rid-certified-asl-interpreters",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>ASL Language Services</title>
        <meta
          name="description"
          content="Professional ASL language services offering reliable interpretation and translation solutions to bridge communication gaps across the U.S."
        />
      </Helmet>
      <PageHeader
        title="ASL and English Sign Language Experts Across the U.S."
        description="At Trusted Interpreters, we provide professional American Sign Language (ASL) and English Sign Language (ESL) interpretation services, ensuring seamless communication for the deaf and hard-of-hearing community. Whether for personal interactions, professional events, or public gatherings, our expert interpreters are here to bridge communication gaps and promote accessibility and inclusion."
        imageUrl={ASLImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Comprehensive Sign Language Services"
          content="We offer a full range of sign language interpretation services, including:"
          contentList={[
            {
              text: "American Sign Language (ASL): The most widely used form of communication for the hearing-impaired in the U.S., ASL combines hand signs, body language, and facial expressions with its own unique grammar and syntax.",
              links: [
                { linkText: "American Sign Language (ASL)", link: "/interpretation-types/asl-interpretation/american-sign-language" },
              ],
            },
            {
              text: "English Sign Language (ESL): Distinct from ASL, ESL is often used in English-speaking regions outside the U.S., adapting English grammar and structure for visual communication.",
              links: [
                {
                  linkText: "English Sign Language (ESL)", link: "/interpretation-types/asl-interpretation/english-sign-language",
                },
              ],
            },
            {
              text: "RID-Certified ASL Interpreters: Trusted Interpreters provides interpreters certified by the Registry of Interpreters for the Deaf (RID), ensuring the highest level of professionalism and expertise in all ASL-related settings.",
              links: [
                {
                  linkText: "RID-Certified ASL Interpreters",
                  link: "/interpretation-types/asl-interpretation/rid-certified-asl-interpreters",
                },
              ],
            },
          ]}
          imageUrl={ASLImage1}
          direction="right"
        />

        {/* Subsections */}
        <div className="container mx-auto px-6 py-6">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
            Explore ASL Interpretation Services
          </h2>
          <SubsectionNavigator subsections={subsections} />
        </div>

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Tailored Solutions for Key Industries"
          content="Our sign language interpreters are trained to support communication in critical sectors, including:"
          contentList={[
            {
              text: "Legal: Courtroom proceedings, depositions, and attorney-client meetings.",
              links: [
                { linkText: "Legal", link: "/expertise/legal-interpretation" },
              ],
            },
            {
              text: "Medical: Hospital communication, patient appointments, and telehealth sessions.",
              links: [
                {
                  linkText: "Medical", link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Immigration: Supporting interviews, hearings, and documentation processes.",
              links: [
                {
                  linkText: "Immigration",
                  link: "/expertise/immigration-interpretation",
                },
              ],
            },
            {
              text: "Business: Corporate meetings, conferences, and negotiations.",
              links: [
                {
                  linkText: "Business",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Educational: Classroom instruction, academic events, and parent-teacher meetings.",
              links: [
                {
                  linkText: "Educational",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
          ]}
          additionalContent="No matter the industry, our interpreters provide accurate, culturally sensitive communication to meet your needs."
          imageUrl={ASLImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>
        
        <ContentSection
          title="Promoting Accessibility and Inclusion"
          content="At Trusted Interpreters, we are dedicated to fostering inclusion and ensuring that everyone, regardless of hearing ability, can fully participate in any setting. By hiring our expert interpreters, you demonstrate a commitment to accessibility, inclusivity, and effective communication."
          imageUrl={ASLImage3}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Our ASL Interpretation?">
          <KeyBenefits benefits={ASLBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Us Today"
          description="Ready to enhance accessibility and communication? Contact Trusted Interpreters to schedule professional ASL and ESL interpreters for your next event or meeting."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default HomeASL;
