import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import LanguageLinks from "../../../components/LanguageLinks";
import CallToAction from "../../../components/CallToAction";
import OurCertifications from "../../../components/CertificationsComponent";
import OnsiteImage2 from "../../../../src/assets/images/adobe/adobe25.webp";
import { FaBook, FaHandshake } from "react-icons/fa6";
import { FaShieldAlt } from "react-icons/fa";
import Helmet from "react-helmet";
// images
import CertifImage from "../../../../src/assets/images/vectors/certifications.png";

const Certifications: React.FC = () => {
  const certificationsBenefits = [
    {
      icon: FaBook,
      title: "Accuracy and Expertise",
      text: "Certified interpreters possess the skills and knowledge required for precise communication in specialized fields.",
    },
    {
      icon: FaShieldAlt,
      title: "Ethical Standards",
      text: "Certification ensures interpreters adhere to strict ethical guidelines, including confidentiality and impartiality.",
    },
    {
      icon: FaHandshake,
      title: "Client Confidence",
      text: "Working with certified professionals provides peace of mind that your communication needs are in expert hands.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Certifications</title>
        <meta
          name="description"
          content="See our qualifications and certifications that ensure quality interpretation."
        />
      </Helmet>
      <PageHeader
        title="Certified Professional Interpretation Services"
        description="At Trusted Interpreters, quality and professionalism are at the core of what we do. Our certifications reflect our commitment to delivering reliable, accurate, and culturally sensitive interpretation services across industries. We adhere to the highest industry standards, ensuring every project is handled with precision and integrity."
        imageUrl={CertifImage}
      />
      <div className="container mx-auto px-4 py-16">
        <OurCertifications />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks />

        <ContentSection
          title="Ongoing Professional Development"
          content="At Trusted Interpreters, we believe in continuous growth. Our interpreters participate in regular training to stay current with industry trends, tools, and best practices, ensuring they deliver the highest quality service."
          additionalContent="When you work with Trusted Interpreters, you’re choosing a team backed by industry-recognized certifications and a proven commitment to excellence. Let us help you achieve clear and effective communication for your business, legal, medical, or educational needs."
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Certifications Matter">
          <KeyBenefits benefits={certificationsBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Us"
          description="Reach out today to learn more about our team of interpreters and how we can support your communication goals."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Certifications;