import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import CallToAction from "../../../components/CallToAction";
import { FaCogs, FaHeadphonesAlt, FaUserTie } from "react-icons/fa";
import OneTimeImg from "../../../../src/assets/images/vectors/one-time-service.png";
import OnsiteImage1 from "../../../../src/assets/images/adobe/adobe2.webp";
import OnsiteImage2 from "../../../../src/assets/images/adobe/fort worth2.webp";
import Helmet from "react-helmet";
const OneTime: React.FC = () => {
  const oneTimeBenefits = [
    {
      icon: FaUserTie,
      title: "Highly Skilled Interpreters",
      text: "Our team includes experts trained to handle the complexities of simultaneous interpretation, ensuring flawless delivery even under high-pressure situations.",
    },
    {
      icon: FaHeadphonesAlt,
      title: "Cutting-Edge Equipment",
      text: "We provide advanced interpretation technology, including headsets, microphones, and soundproof booths, tailored to your event’s requirements.",
    },
    {
      icon: FaCogs,
      title: "Custom Solutions",
      text: "Whether it’s a small corporate meeting or a large international conference, we design our services to meet your specific needs.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>On-Demand Phone Interpretation US</title>
        <meta
          name="description"
          content="Access professional one-time phone interpretation services for any situation."
        />
      </Helmet>
      <PageHeader
        title="On-Demand One-Time Phone Interpretation"
        description="Trusted Interpreters offers reliable, on-demand phone interpretation services tailored for one-time or immediate needs. Our service ensures instant access to expert interpreters, providing seamless communication when and where you need it."
        imageUrl={OneTimeImg}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Instant Access to Expert Interpreters"
          content="With no scheduling required, our one-time phone interpretation service is perfect for:"
          contentList={[
            {
              text: "Emergency situations requiring immediate interpretation.",
            },
            {
              text: "Short-term business meetings or client calls.",
            },
            {
              text: "Personal needs, such as travel or quick consultations.",
            },
            {
              text: "Simply dial in and connect to a professional interpreter within minutes, ensuring efficient and accurate communication.",
            },
          ]}
          imageUrl={OnsiteImage1}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Trusted Professionals"
          content="All interpreters in our network are highly trained in cultural nuances and industry-specific terminology. They also adhere to strict confidentiality agreements, ensuring your privacy and security during every call."
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="Consecutive" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Our On-Demand One-Time Phone Interpretation?">
          <KeyBenefits benefits={oneTimeBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Simplify Your Communication Today"
          description="Need interpretation on short notice? Trusted Interpreters is here to help. Contact us now to access our one-time phone interpretation service and eliminate language barriers effortlessly."
          buttonText="Contact Us"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default OneTime;