import type React from "react";

const HomeOrganizationSchema: React.FC = () => {
  const homeSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Trusted Interpreters - Professional Interpretation Services",
    url: window.location.origin,
    logo: "https://www.trustedinterpreters.com/static/media/logo_ti.093360928a109ef4474b.jpg",
    description: "Expert on-site, over-the-phone, and video remote interpretation services.",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+1-888-444-2554",
      contactType: "customer service",
    }
  };

  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "What industries do you serve?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Trusted Interpreters serves industries like legal, healthcare, immigration, business, education, and government, and many more."
        }
      },
      {
        "@type": "Question",
        name: "Are your interpreters certified?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Yes, we have certified interpreters available for various industries and requirements."
        }
      },
      {
        "@type": "Question",
        name: "Do you provide interpretation for immigration interviews?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Yes, we offer interpretation services for immigration interviews."
        }
      },
      {
        "@type": "Question",
        name: "What languages are available for interpretation?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "We support over 200 languages, including Spanish, French, Chinese, Arabic, Portuguese, and American Sign Language (ASL)."
        }
      },
      {
        "@type": "Question",
        name: "Can you handle rare languages or dialects?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Yes, we have interpreters available for rare languages and dialects upon request."
        }
      },
      {
        "@type": "Question",
        name: "Do you offer sign language interpretation?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Yes, we provide American Sign Language (ASL) and other sign language interpretation services."
        }
      },
      {
        "@type": "Question",
        name: "What payment methods do you accept?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "We accept all major credit cards, PayPal, and other secure online payment methods."
        }
      },
      {
        "@type": "Question",
        name: "Is my payment information secure?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Yes, we use industry-standard security measures to protect your payment information."
        }
      },
      {
        "@type": "Question",
        name: "Is there a guarantee of confidentiality for my conversations and interactions?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Yes, all of our interpretation services are confidential, and we adhere to strict privacy standards."
        }
      },
      {
        "@type": "Question",
        name: "Do you provide contracts or agreements for interpretation projects?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Yes, we can provide contracts or agreements for interpretation projects upon request."
        }
      }
    ]
  };

  return (
    <>
      <script type="application/ld+json">{JSON.stringify(homeSchema)}</script>
      <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
    </>
  );
};

export default HomeOrganizationSchema;