import React from "react";
import { Link } from "react-router-dom";
import { Video, Monitor, MessageSquare, UserCheck, Webcam, Cast } from "lucide-react";

const VRIMenu: React.FC<{ onMouseLeave: () => void }> = ({ onMouseLeave }) => {
  return (
    <div
      className="lg:absolute lg:bg-white lg:shadow-lg rounded-lg p-4 mt-2 w-full lg:w-64 z-50"
      onMouseLeave={onMouseLeave}
    >
      <ul>
        <li className="lg:hidden hover:bg-gray-100 rounded">
          <Link to="/interpretation-types/vri/" className="flex items-center p-2">
            <Cast className="h-6 w-6 text-blue-600 mr-3" />
            <div>
            <p className="text-gray-800 font-semibold">Check all Video Remote Interpretation Services</p>
            </div>
          </Link>
        </li>
        <li className="hover:bg-gray-100 rounded">
          <Link to="/interpretation-types/vri/zoom-interpretation-services" className="flex items-center p-2">
            <Video className="h-6 w-6 text-blue-600 mr-3" />
            <div>
              <p className="text-gray-800 font-semibold">Zoom</p>
              <p className="text-gray-500 text-sm">Virtual interpretation via Zoom.</p>
            </div>
          </Link>
        </li>
        <li className="hover:bg-gray-100 rounded">
          <Link to="/interpretation-types/vri/google-meet-interpretation-services" className="flex items-center p-2">
            <Monitor className="h-6 w-6 text-blue-600 mr-3" />
            <div>
              <p className="text-gray-800 font-semibold">Google Meet</p>
              <p className="text-gray-500 text-sm">Interpretation on Google Meet.</p>
            </div>
          </Link>
        </li>
        <li className="hover:bg-gray-100 rounded">
          <Link to="/interpretation-types/vri/microsoft-teams-interpretation-services" className="flex items-center p-2">
            <MessageSquare className="h-6 w-6 text-blue-600 mr-3" />
            <div>
              <p className="text-gray-800 font-semibold">Teams</p>
              <p className="text-gray-500 text-sm">Microsoft Teams interpretation services.</p>
            </div>
          </Link>
        </li>
        <li className="hover:bg-gray-100 rounded">
          <Link to="/interpretation-types/vri/skype-interpretation-services" className="flex items-center p-2">
            <UserCheck className="h-6 w-6 text-blue-600 mr-3" />
            <div>
              <p className="text-gray-800 font-semibold">Skype</p>
              <p className="text-gray-500 text-sm">Seamless interpretation using Skype.</p>
            </div>
          </Link>
        </li>
        <li className="hover:bg-gray-100 rounded">
          <Link to="/interpretation-types/vri/other-interpretation-services" className="flex items-center p-2">
            <Webcam className="h-6 w-6 text-blue-600 mr-3" />
            <div>
              <p className="text-gray-800 font-semibold">Others</p>
              <p className="text-gray-500 text-sm">Cisco Webex, GoTo Meeting, BlueJeans</p>
            </div>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default VRIMenu;
