import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import { Helmet } from "react-helmet";
// images
import PhoenixImage from "../../../src/assets/images/adobe/Phoenix.webp";
import PhoenixImage1 from "../../../src/assets/images/adobe/Phoenix1.webp";
import PhoenixImage2 from "../../../src/assets/images/adobe/onsite-img.webp";
import PhoenixImage3 from "../../../src/assets/images/adobe/Phoenix2.webp";

const Phoenix: React.FC = () => {
  const phoenixBenefits = [
    {
      icon: FaAward,
      title: "Certified Professionals",
      text: "Our interpreters are highly trained and certified, ensuring reliable and precise interpretation.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Awareness",
      text: "We prioritize cultural nuances to ensure your message resonates effectively.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in Phoenix, delivering consistent support wherever needed.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services Phoenix</title>
        <meta
          name="description"
          content="Accurate interpretation services tailored to businesses and events in Phoenix."
        />
      </Helmet>

      <PageHeader
        title="Professional Interpretation Services in Phoenix"
        description="Trusted Interpreters offers expert interpretation services tailored to the needs of Phoenix’s growing and diverse population. Whether for business meetings, legal hearings, healthcare appointments, or educational events, our certified interpreters ensure precise and effective communication in over 200 languages."
        imageUrl={PhoenixImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Choose Trusted Interpreters in Phoenix?"
          contentList={[
            {
              text: "Experienced Interpreters: Our team of professionals is fluent in over 200 languages and experienced in a wide range of industries.",
            },
            {
              text: "Tailored Solutions: We provide customized services for various scenarios, from corporate events to medical appointments.",
              links: [
                {
                  linkText: "medical appointments",
                  link: "/expertise/medical-interpretation",
                },
                {
                  linkText: "corporate events",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Convenience: Serving Phoenix and surrounding areas, we’re equipped to meet your on-site, over-the-phone, and virtual interpretation needs.",
              links: [
                {
                  linkText: "on-site",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "over-the-phone",
                  link: "/interpretation-types/phone-interpretation",
                },
                {
                  linkText: "virtual interpretation",
                  link: "/interpretation-types/vri",
                },
              ],
            },
          ]}
          imageUrl={PhoenixImage1}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Specialized Services in Phoenix"
          contentList={[
            {
              text: "On-Site Interpretation: Perfect for business meetings, legal proceedings, and conferences. Our interpreters travel to your location, offering in-person support for effective communication.",
              links: [
                {
                  linkText: "On-Site Interpretation",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "legal proceedings",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "business meetings",
                  link: "/expertise/business-interpretation",
                },
                {
                  linkText: "conferences",
                  link: "/interpretation-types/on-site/simultaneous",
                },
              ],
            },
            {
              text: "Phone Interpretation: An ideal solution for urgent or remote communication needs. Connect with professional interpreters in real-time, 24/7.",
              links: [
                {
                  linkText: "Phone Interpretation",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Video Remote Interpretation (VRI): Seamless interpretation for virtual meetings, webinars, and hybrid events. Designed to ensure clear communication regardless of physical distance.",
              links: [
                {
                  linkText: "Video Remote Interpretation (VRI)",
                  link: "/interpretation-types/vri",
                },
              ],
            },
          ]}
          imageUrl={PhoenixImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks />

        <ContentSection
          title="Industries We Support in Phoenix"
          content="Our interpreters have extensive experience in Phoenix’s key industries:"
          contentList={[
            {
              text: "Healthcare: Helping healthcare providers and patients communicate effectively in clinics, hospitals, and private practices.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal: Providing court-certified interpreters for hearings, depositions, and legal consultations.",
              links: [
                {
                  linkText: "Legal",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Education: Ensuring smooth communication for schools, universities, and training programs.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
            {
              text: "Corporate: Supporting international business negotiations, training sessions, and conferences.",
              links: [
                {
                  linkText: "Corporate",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
          ]}
          additionalContent="From downtown Phoenix to Scottsdale, Mesa, and Tempe, Trusted Interpreters is your go-to partner for all interpretation needs in the Phoenix metropolitan area."
          imageUrl={PhoenixImage3}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={phoenixBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Trusted Interpreters Today"
          description="Don’t let language barriers hinder your success. Contact Trusted Interpreters to learn more about our professional interpretation services in Phoenix and how we can support your business or event."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Phoenix;
