import React from "react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// Images
import LandingImg from "../../src/assets/images/adobe/landing-img.webp";
import OnsiteImage from "../../src/assets/images/adobe/Speaker.webp";
import OPIImage from "../../src/assets/images/adobe/adobe2.webp";
import VriImg from "../../src/assets/images/adobe/vri-img.webp";
import ASLImage from "../../src/assets/images/adobe/ASL.webp";
import Consecutive from "../../src/assets/images/adobe/adobe3.webp";
import Simultaneous from "../../src/assets/images/adobe/SanDiego1.webp";
import LegalImage from "../../src/assets/images/adobe/Legal1.webp";
import Medical from "../../src/assets/images/adobe/medical1.webp";
import Immigration from "../../src/assets/images/adobe/adobe4.webp";
import Corporate from "../../src/assets/images/adobe/adobe5.webp";
import Education from "../../src/assets/images/adobe/NYC1.webp";
import Government from "../../src/assets/images/adobe/adobe7.webp";

const LandingInfo: React.FC = () => {
  const cards = [
    {
      category: "Interpretation Types",
      items: [
        {
          name: "On-Site Interpretation",
          description:
            "Ideal for face-to-face meetings, conferences, legal hearings, and events.",
          image: OnsiteImage,
          link: "/interpretation-types/on-site",
        },
        {
          name: "Over-the-Phone",
          description:
            "On-demand interpretation for immediate language support.",
          image: OPIImage,
          link: "/interpretation-types/phone-interpretation",
        },
        {
          name: "Video Remote Interpretation",
          description:
            "Real-time solutions for virtual meetings, webinars, and telehealth.",
          image: VriImg,
          link: "/interpretation-types/vri",
        },
        {
          name: "Sign Language",
          description:
            "Inclusive services for the deaf and hard-of-hearing communities.",
          image: ASLImage,
          link: "/interpretation-types/asl-interpretation",
        },
        {
          name: "Consecutive Interpretation",
          description:
            "Perfect for events, negotiations, and one-on-one interactions.",
          image: Consecutive,
          link: "/interpretation-types/on-site/consecutive",
        },
        {
          name: "Simultaneous Interpretation",
          description:
            "Ideal for large conferences and seminars requiring real-time language support without interruptions.",
          image: Simultaneous,
          link: "/interpretation-types/on-site/simultaneous",
        },
      ],
    },
    {
      category: "Industries Expertise",
      items: [
        {
          name: "Legal",
          description: "Courtroom proceedings and depositions.",
          image: LegalImage,
          link: "/expertise/legal-interpretation",
        },
        {
          name: "Healthcare",
          description: "Medical appointments and hospital communication.",
          image: Medical,
          link: "/expertise/medical-interpretation",
        },
        {
          name: "Immigration",
          description: "Support for immigration-related interpretations.",
          image: Immigration,
          link: "/expertise/immigration-interpretation",
        },
        {
          name: "Corporate",
          description:
            "Board meetings, international conferences, and training sessions.",
          image: Corporate,
          link: "/expertise/business-interpretation",
        },
        {
          name: "Education",
          description:
            "Academic events, parent-teacher meetings, and classroom instruction.",
          image: Education,
          link: "/expertise/educational-interpretation",
        },
        {
          name: "Government",
          description:
            "Policy discussions, public hearings, and community outreach.",
          image: Government,
          link: "/expertise/government-interpretation",
        },
      ],
    },
  ];

  const languages = [
    {
      name: "English",
      code: "US",
      link: "/language-expertise/english-interpretation",
    },
    {
      name: "Spanish",
      code: "ES",
      link: "/language-expertise/spanish-interpretation",
    },
    {
      name: "French",
      code: "FR",
      link: "/language-expertise/french-interpretation",
    },
    {
      name: "German",
      code: "DE",
      link: "/language-expertise/german-interpretation",
    },
    {
      name: "Italian",
      code: "IT",
      link: "/language-expertise/italian-interpretation",
    },
    {
      name: "Portuguese",
      code: "PT",
      link: "/language-expertise/portuguese-interpretation",
    },
    {
      name: "Japanese",
      code: "JP",
      link: "/language-expertise/japanese-interpretation",
    },
    {
      name: "Chinese",
      code: "CN",
      link: "/language-expertise/chinese-interpretation",
    },
    {
      name: "Arabic",
      code: "SA",
      link: "/language-expertise/arabic-interpretation",
    },
    {
      name: "Haitian",
      code: "HT",
      link: "/language-expertise/haitian-creole-interpretation",
    },
  ];

  return (
    <div className="container mx-auto px-4 py-8 space-y-6">
      {/* Title Section */}
      <div className="text-center ">
        <h2 className="text-4xl font-bold text-gray-800">
          Explore Our Services
        </h2>
        <p className="text-lg text-gray-600">
          Discover our expertise in interpretation services tailored for every
          industry.
        </p>
      </div>

      {/* Presentation Section */}
      <div className="flex flex-col lg:flex-row items-center text-center gap-8 bg-blue-50 rounded-lg shadow">
        <img
          src={LandingImg}
          alt="Trusted Experts in Interpretation Services"
          className="w-full lg:w-1/3 rounded-lg shadow-md"
        />
        <div className="lg:w-2/3">
          <h3 className="text-2xl font-bold text-gray-800 mb-4">
            Trusted Experts in Interpretation Services
          </h3>
          <p className="text-lg text-gray-600 mb-6">
            With over 20 years of experience and a network of more than 10,000
            certified interpreters, Trusted Interpreters is your global partner
            for professional interpretation services.
          </p>
        </div>
      </div>

      {/* Card Grid */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
        {/* Interpretation Types */}
        <div className="bg-gray-100 rounded-lg shadow-lg relative">
          <h3 className="text-xl font-semibold mb-2 text-center text-gray-800 pt-2">
            Interpretation Types
          </h3>
          <div className="border-t border-gray-300 w-full"></div>
          <div className="relative">
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              navigation={{
                nextEl: ".swiper-button-next-interpretation",
                prevEl: ".swiper-button-prev-interpretation",
              }}
              pagination={{ clickable: true }}
              autoplay={{ delay: 3000 }}
              spaceBetween={1}
              slidesPerView={1}
              className="w-full"
            >
              {cards[0].items.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="flex items-center min-h-44">
                    <img
                      src={item.image}
                      alt={item.name}
                      className="w-2/5 h-full object-cover md:rounded-bl-lg"
                    />
                    <div className="flex-1 p-4">
                      <a
                        href={item.link}
                        className="text-lg font-medium text-gray-800"
                      >
                        {item.name}
                      </a>
                      <p className="text-sm text-gray-600">
                        {item.description}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            {/* Navigation buttons */}
            <div className="swiper-button-prev-interpretation absolute left-[-25px] top-1/2 transform -translate-y-1/2 text-3xl text-gray-800 p-2 cursor-pointer z-10">
              &#x276E;
            </div>
            <div className="swiper-button-next-interpretation absolute right-[-25px] top-1/2 transform -translate-y-1/2 text-3xl text-gray-800 p-2 cursor-pointer z-10">
              &#x276F;
            </div>
          </div>
        </div>

        {/* Industries Expertise */}
        <div className="bg-gray-100 rounded-lg shadow-lg relative">
          <h3 className="text-xl font-semibold mb-2 text-center text-gray-800 pt-2">
            Our Industries of Expertise
          </h3>
          <div className="border-t border-gray-300 w-full"></div>
          <div className="relative">
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              navigation={{
                nextEl: ".swiper-button-next-industries",
                prevEl: ".swiper-button-prev-industries",
              }}
              pagination={{ clickable: true }}
              autoplay={{ delay: 3000 }}
              spaceBetween={1}
              slidesPerView={1}
              className="w-full"
            >
              {cards[1].items.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="flex items-center min-h-44">
                    <img
                      src={item.image}
                      alt={item.name}
                      className="w-2/5 h-full object-cover md:rounded-bl-lg"
                    />
                    <div className="flex-1 p-4">
                      <a
                        href={item.link}
                        className="text-lg font-medium text-gray-800"
                      >
                        {item.name}
                      </a>
                      <p className="text-sm text-gray-600">
                        {item.description}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            {/* Navigation buttons */}
            <div className="swiper-button-prev-industries absolute left-[-25px] top-1/2 transform -translate-y-1/2 text-3xl text-gray-800 p-2 cursor-pointer z-10">
              &#x276E;
            </div>
            <div className="swiper-button-next-industries absolute right-[-25px] top-1/2 transform -translate-y-1/2 text-3xl text-gray-800 p-2 cursor-pointer z-10">
              &#x276F;
            </div>
          </div>
        </div>
      </div>

      {/* Languages Section */}
      <div className="invisible md:visible border-t border-gray-300 w-full"></div>

      <div className="flex flex-col items-center space-y-4">
        <h2 className="text-2xl font-semibold text-gray-800 mt-[-15px]">
        Our Languages of Expertise
        </h2>
        <ul className="grid grid-cols-2 gap-4 text-gray-700 mt-4 md:flex md:flex-wrap md:justify-center md:gap-8">
          {languages.map((language, index) => (
            <li key={index} className="flex items-center space-x-2 text-lg">
              {/* Checkmark Icon */}
              <svg
                className="w-5 h-5 text-green-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
              {/* Language Name */}
              <a
                href={language.link}
                className="hover:text-blue-600 transition-colors duration-200"
              >
                {language.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default LandingInfo;
