import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import { Helmet } from "react-helmet";
// images
import PhiImage from "../../../src/assets/images/adobe/Philadelphia.webp";
import PhiImage1 from "../../../src/assets/images/adobe/Philadelphia1.webp";
import PhiImage2 from "../../../src/assets/images/adobe/Legal.webp";
import PhiImage3 from "../../../src/assets/images/adobe/Philadelphia2.webp";

const Philadelphia: React.FC = () => {
  const philadelphiaBenefits = [
    {
      icon: FaAward,
      title: "Experienced Professionals",
      text: "We ensure accurate communication while respecting cultural nuances.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Understanding",
      text: "Our interpreters are certified, trained, and fluent in over 200 languages.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in Philadelphia, delivering consistent support wherever needed.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title> Interpretation Services Philadelphia</title>
        <meta
          name="description"
          content="Trusted Interpreters provides expert interpretation services in Philadelphia."
        />
      </Helmet>

      <PageHeader
        title="Professional Interpretation Services in Philadelphia"
        description="Trusted Interpreters provides expert language solutions to bridge communication gaps in Philadelphia’s vibrant and diverse community. Whether you need support for business meetings, legal matters, healthcare appointments, or educational events, our certified interpreters deliver accurate and culturally sensitive communication in over 200 languages."
        imageUrl={PhiImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Tailored Services to Fit Philadelphia's Needs"
          contentList={[
            {
              text: "On-Site Support: Ideal for events, legal matters, and face-to-face meetings. Our interpreters arrive prepared to provide accurate and culturally sensitive communication.",
              links: [
                {
                  linkText: "On-Site Support",
                  link: "/interpretation-types/on-site",
                },
              ],
            },
            {
              text: "Remote Solutions: Need immediate assistance? Our phone and video remote interpretation services deliver instant, reliable language support.",
              links: [
                {
                  linkText: "phone",
                  link: "/interpretation-types/phone-interpretation",
                },
                {
                  linkText: "video remote",
                  link: "/interpretation-types/vri",
                },
              ],
            },
            {
              text: "Customized Plans: Whether it's a single event or ongoing projects, we adapt to meet your unique requirements.",
            },
          ]}
          imageUrl={PhiImage1}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Specialized Industries We Serve"
          content="Philadelphia’s dynamic industries require specialized expertise. Our services cover:"
          contentList={[
            {
              text: "Healthcare: Helping doctors and patients communicate effectively in hospitals and clinics.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal: Offering certified interpreters for courtrooms, legal consultations, and depositions.",
              links: [
                {
                  linkText: "Legal",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Business: Supporting corporate discussions, training sessions, and international partnerships.",
              links: [
                {
                  linkText: "Business",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Education: Assisting schools and universities in bridging language barriers for students and faculty.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
          ]}
          imageUrl={PhiImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks />

        <ContentSection
          title="Comprehensive Citywide Coverage"
          content="From bustling Center City to vibrant neighborhoods like Fishtown and West Philadelphia, Trusted Interpreters provides reliable interpretation services across the region. Whether you're hosting a corporate event in the city or need support for a private appointment, we’re here to help."
          imageUrl={PhiImage3}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={philadelphiaBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Us for Expert Interpretation Services in Philadelphia"
          description="Unlock the power of clear communication. Contact Trusted Interpreters today to learn how we can support your next event, meeting, or project in Philadelphia."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Philadelphia;
