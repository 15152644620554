import React from "react";
import { Link } from "react-router-dom";
import ProcessBenefits from "./ProcessBenefits";

const OurProcess: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-4">
      <div className="flex flex-col items-center text-center mb-12">
        <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl">
          The <span className="animated-highlight">Process</span> with Trusted
          Interpreters
        </h1>
        <p className="mt-6 text-lg font-normal text-gray-500 lg:text-xl text-center">
          Discover a seamless approach to language interpretation tailored to
          your needs. Trusted Interpreters ensures every project meets the
          highest standards of accuracy, professionalism, and reliability,
          making communication effortless.
        </p>
      </div>

      <div className="flex flex-col lg:flex-row items-center lg:space-x-8">
        <div className="lg:w-1/2 mb-8 lg:mb-0">
          <ProcessBenefits />
        </div>

        <div className="lg:w-1/2 pl-8">
          <ol className="relative text-gray-500 border-l border-gray-200 dark:border-gray-700">
            <li className="mb-10 ml-10">
              <span className="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -left-4 ring-4 ring-white">
                <svg
                  className="w-5 h-5" // Incrementamos el tamaño
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none" // Eliminamos el relleno
                  viewBox="0 0 16 12"
                >
                  <path
                    stroke="#025f99" // Color principal
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 5.917 5.724 10.5 15 1.5"
                  />
                </svg>
              </span>
              <h3 className="font-semibold text-xl leading-tight text-[#025f99]">
                Submit Your Request
              </h3>
              <p className="text-lg text-gray-600">
                Share your event details with our Account Manager, and we’ll
                provide a tailored quote.
              </p>
            </li>

            <li className="mb-10 ml-10">
              <span className="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -left-4 ring-4 ring-white">
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#025f99"
                  viewBox="0 0 20 16"
                >
                  <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z" />
                </svg>
              </span>
              <h3 className="font-semibold text-xl leading-tight text-[#025f99]">
                Confirm Your Project
              </h3>
              <p className="text-lg text-gray-600">
                Once you approve the quote, our team assigns the perfect
                interpreter for your event.
              </p>
            </li>

            <li className="ml-10">
              <span className="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -left-4 ring-4 ring-white">
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#025f99"
                  viewBox="0 0 18 20"
                >
                  <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
                </svg>
              </span>
              <h3 className="font-semibold text-xl leading-tight text-[#025f99]">
                Ensure Flawless Communication
              </h3>
              <p className="text-lg text-gray-600">
                Your interpreter delivers accurate and professional service, and
                we follow up for your feedback.
              </p>
            </li>
          </ol>

          <div className="mt-8 lg:ml-10 pr-8 flex justify-center lg:justify-start">
            <Link
              to="/free-quote"
              className="bg-[#025f99] text-white px-6 py-3 rounded-lg text-lg hover:bg-[#014a73]"
            >
              Get a Quote Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurProcess;
