import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import CallToAction from "../../../components/CallToAction";
import { FaArrowsAlt, FaMapMarkedAlt } from "react-icons/fa";
import GovernmentImage from "../../../../src/assets/images/vectors/government.png";
import GovernmentImage1 from "../../../../src/assets/images/adobe/government1.webp";
import OnsiteImage1 from "../../../../src/assets/images/adobe/government.webp";
import OnsiteImage2 from "../../../../src/assets/images/adobe/onsite-img-2.webp";
import { FaLanguage } from "react-icons/fa6";
import Helmet from "react-helmet";

const GovernmentInterpretation: React.FC = () => {
  const governmentBenefits = [
    {
      icon: FaLanguage,
      title: "Comprehensive Language Coverage",
      text: "We offer interpretation in over 200 languages and dialects to meet the diverse needs of educational institutions.",
    },
    {
      icon: FaArrowsAlt,
      title: "Flexible Options",
      text: "Choose on-site, over-the-phone, or video remote interpretation services based on your specific requirements.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Reach",
      text: "Our interpreters are available across the U.S., ensuring support wherever it’s needed.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Government Interpretation Services</title>
        <meta
          name="description"
          content="Trusted Interpreters offers accurate and reliable interpretation services for government agencies nationwide. Contact us for on-site, phone, or video interpretation."
        />
      </Helmet>
      <PageHeader
        title="Government Interpretation Services"
        description="Trusted Interpreters specializes in providing reliable and accurate interpretation services for government agencies at the local, state, and federal levels. With years of experience working with public institutions, we understand the importance of clear communication to serve diverse communities effectively."
        imageUrl={GovernmentImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Expert Government Interpreters"
          content="Our interpreters are:"
          contentList={[
            {
              text: "Trained in Government Protocols: Proficient in handling sensitive information and adhering to strict guidelines.",
            },
            {
              text: "Culturally Competent: Ensuring inclusivity and understanding across diverse communities.",
            },
            {
              text: "Certified and Confidential: Bound by confidentiality agreements and professional ethical standards to protect sensitive data.",
            },
          ]}
          imageUrl={OnsiteImage1}
          direction="right"
        />

        {/* Workflow */}
        <ProcessWorkflow process="presencial" />

        <ContentSection
          title="Government Interpretation Services We Offer"
          content="We provide interpretation services for a wide range of government needs, including:"
          contentList={[
            {
              text: "Public Hearings and Meetings: Supporting effective communication with diverse audiences.",
              links: [
                {
                  linkText: "Public Hearings and Meetings",
                  link: "/interpretation-types/on-site",
                },
              ],
            },
            {
              text: "Court Proceedings: Ensuring accuracy during legal processes involving multilingual parties.",
            },
            {
              text: "Community Programs: Facilitating engagement and understanding in local outreach programs.",
            },
            {
              text: "Policy Discussions: Assisting in critical conversations with global partners.",
            },
            {
              text: "Emergency Management: Providing real-time interpretation for disaster response and recovery efforts.",
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <ContentSection
          title="Supporting Public Institutions Nationwide"
          content="From urban centers to rural communities, Trusted Interpreters is committed to delivering high-quality interpretation services wherever they are needed. Our interpreters are available nationwide, ensuring that your communication needs are met with professionalism and accuracy."
          imageUrl={GovernmentImage1}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters for Government Services?">
          <KeyBenefits benefits={governmentBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Us Today"
          description="Ensure clear and effective communication for your government agency. Contact Trusted Interpreters today to learn more about our government interpretation services or to schedule an interpreter for your next project."
          buttonText="Contact Us"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default GovernmentInterpretation;