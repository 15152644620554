import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import OnsiteImage from "../../../src/assets/images/adobe/onsite-img.webp";
import OnsiteImage1 from "../../../src/assets/images/adobe/onsite-img-1.webp";
import OnsiteImage2 from "../../../src/assets/images/adobe/onsite-img-2.webp";
import { FaCertificate } from "react-icons/fa6";
import { FaGlobeAmericas, FaMapMarkedAlt } from "react-icons/fa";
import Helmet from "react-helmet";

const LanguageExpertise: React.FC = () => {
    const languageBenefits = [
        {
          icon: FaCertificate,
          title: "Certified Professionals",
          text: "Our interpreters are highly trained and certified, offering reliable and accurate services.",
        },
        {
          icon: FaGlobeAmericas,
          title: "Cultural Understanding",
          text: "We pair you with interpreters who understand the cultural context of the target language, ensuring effective communication.",
        },
        {
          icon: FaMapMarkedAlt,
          title: "Nationwide Availability",
          text: "With interpreters located across the U.S., we are ready to support your language needs anywhere.",
        },
      ];
      
      return (
        <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
        <Helmet>
          <title>Comprehensive Language Expertise US</title>
          <meta
            name="description"
            content="Explore Trusted Interpreters' language expertise for industries nationwide."
          />
        </Helmet>
          <PageHeader
            title="Comprehensive Language Expertise for Your Interpretation Needs"
            description="Trusted Interpreters specializes in providing professional interpretation services across a wide range of languages. With a network of certified interpreters fluent in over 200 languages, we ensure seamless communication tailored to your specific needs, industry, and audience."
            imageUrl={OnsiteImage}
          />
          <div className="container mx-auto px-4 py-16">
            <ContentSection
              title="Our Language Expertise"
              content="We offer expert interpretation services in the most widely spoken languages, including:"
              contentList={[
                {
                  text: "Spanish",
                  links: [
                    {
                      linkText: "Spanish",
                      link: "/language-expertise/spanish-interpretation",
                    },
                  ],
                },
                {
                  text: "English",
                  links: [
                    {
                      linkText: "English",
                      link: "/language-expertise/english-interpretation",
                    },
                  ],
                },
                {
                  text: "Chinese",
                  links: [
                    {
                      linkText: "Chinese",
                      link: "/language-expertise/chinese-interpretation",
                    },
                  ],
                },
                {
                  text: "French",
                  links: [
                    {
                      linkText: "French",
                      link: "/language-expertise/french-interpretation",
                    },
                  ],
                },
                {
                  text: "Japanese",
                  links: [
                    {
                      linkText: "Japanese",
                      link: "/language-expertise/japanese-interpretation",
                    },
                  ],
                },
                {
                  text: "Arabic",
                  links: [
                    {
                      linkText: "Arabic",
                      link: "/language-expertise/arabic-interpretation",
                    },
                  ],
                },
                {
                  text: "German",
                  links: [
                    {
                      linkText: "German",
                      link: "/language-expertise/german-interpretation",
                    },
                  ],
                },
                {
                  text: "Italian",
                  links: [
                    {
                      linkText: "Italian",
                      link: "/language-expertise/italian-interpretation",
                    },
                  ],
                },
                {
                  text: "Portuguese",
                  links: [
                    {
                      linkText: "Portuguese",
                      link: "/language-expertise/portuguese-interpretation",
                    },
                  ],
                },
                {
                  text: "Haitian Creole",
                  links: [
                    {
                      linkText: "Haitian Creole",
                      link: "/language-expertise/haitian-creole-interpretation",
                    },
                  ],
                },
              ]}
              additionalContent="Our interpreters are also skilled in regional dialects and cultural nuances, ensuring accurate and culturally appropriate communication."
              imageUrl={OnsiteImage1}
              direction="right"
            />
    
            {/* Workflow */}
            <HighlightedSection title="">
              <ProcessWorkflow process="presencial" />
            </HighlightedSection>
    
            <ContentSection
              title="Industries We Serve"
              content="Trusted Interpreters provides language expertise for diverse sectors, such as:"
              contentList={[
                {
                  text: "Business: Facilitating multilingual meetings, negotiations, and conferences.",
                  links: [
                    {
                      linkText: "Business",
                      link: "/expertise/business-interpretation",
                    },
                  ],
                },
                {
                  text: "Government: Assisting with official proceedings and community programs.",
                  links: [
                    {
                      linkText: "Government",
                      link: "/expertise/government-interpretation",
                    },
                  ],
                },
                {
                  text: "Education: Enhancing communication in classrooms and academic seminars.",
                  links: [
                    {
                      linkText: "Education",
                      link: "/expertise/educational-interpretation",
                    },
                  ],
                },
                {
                  text: "Healthcare: Supporting patient-provider communication in medical settings.",
                  links: [
                    {
                      linkText: "Healthcare",
                      link: "/expertise/medical-interpretation",
                    },
                  ],
                },
                {
                  text: "Legal: Delivering precise interpretation for court hearings, depositions, and consultations.",
                  links: [
                    {
                      linkText: "Legal",
                      link: "/expertise/legal-interpretation",
                    },
                  ],
                },
              ]}
              imageUrl={OnsiteImage2}
              direction="left"
            />
    
            {/* <CitiesGrid serviceName="On-Site" /> */}
            <div className="container mx-auto px-4 py-16">
              <InteractiveMap />
            </div>

            <LanguageLinks />

            <ContentSection
              title="Tailored Solutions for Every Project"
              content="Whether you need:"
              contentList={[
                {
                  text: "On-Site",
                  links: [
                    {
                      linkText: "On-Site",
                      link: "/interpretation-types/on-site",
                    },
                  ],
                },
                {
                  text: "Over-The-Phone",
                  links: [
                    {
                      linkText: "Over-The-Phone",
                      link: "/interpretation-types/phone-interpretation",
                    },
                  ],
                },
                {
                  text: "Video Remote Interpretation",
                  links: [
                    {
                      linkText: "Video Remote Interpretation",
                      link: "/interpretation-types/vri",
                    },
                  ],
                },
                {
                  text: "Sign Language services",
                  links: [
                    {
                      linkText: "Sign Language services",
                      link: "/interpretation-types/asl-interpretation",
                    },
                  ],
                },
              ]}
              additionalContent="We provide customized solutions to meet your requirements. From small meetings to large-scale events, we handle projects of all sizes with professionalism and expertise."
              imageUrl={OnsiteImage2}
              direction="right"
            />

            {/* Key Benefits */}
            <HighlightedSection title="Why Choose Trusted Interpreters?">
              <KeyBenefits benefits={languageBenefits} />
            </HighlightedSection>
    
            <CallToAction
              title="Contact Us Today"
              description="Break language barriers with Trusted Interpreters' comprehensive language expertise. Contact us now to learn more about our interpretation services and discover how we can assist you in achieving effective communication."
              buttonText="Get a Quote"
              buttonLink="/free-quote"
            />
    
            {/* faq */}
            <FAQ />
    
            {/* testimonials */}
            <TestimonialsSection />
          </div>
          <Footer />
        </div>
      );
};

export default LanguageExpertise;