import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import OnsiteImage2 from "../../../src/assets/images/adobe/sanjose2.webp";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import Helmet from "react-helmet";
// images
import ElPasoImage from "../../../src/assets/images/adobe/El-Paso.webp";
import PeopleImage from "../../../src/assets/images/adobe/adobe17.webp";
import PeopleImage1 from "../../../src/assets/images/adobe/SanAntonio1.webp";

const ElPaso: React.FC = () => {
  const elPasoBenefits = [
    {
      icon: FaAward,
      title: "Experienced Professionals",
      text: "We ensure accurate communication while respecting cultural nuances.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Understanding",
      text: "Our interpreters are certified, trained, and fluent in over 200 languages.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in El Paso, delivering consistent support wherever needed.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services El Paso</title>
        <meta
          name="description"
          content="Trusted Interpreters delivers accurate interpretation services in El Paso."
        />
      </Helmet>
      <PageHeader
        title="Reliable Interpretation Services in El Paso"
        description="At Trusted Interpreters, we provide expert language solutions to meet the needs of El Paso’s diverse communities and industries. From legal proceedings to corporate meetings, our services are designed to bridge language gaps with precision and professionalism."
        imageUrl={ElPasoImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Choose Trusted Interpreters in El Paso?"
          contentList={[
            {
              text: "Certified Interpreters: Our linguists are fluent in over 200 languages and have specialized knowledge across industries, ensuring precise communication.",
            },
            {
              text: "Tailored Services: We adapt our offerings to meet your unique needs, whether for one-time events or ongoing language support.",
              links: [
                {
                  linkText: "one-time events",
                  link: "/interpretation-types/phone-interpretation/one-time-service",
                },
                {
                  linkText: "ongoing language support",
                  link: "/interpretation-types/phone-interpretation/monthly-recurrent-service",
                },
              ],
            },
            {
              text: "Cultural Understanding: With expertise in bilingual and bicultural dynamics, we ensure accurate, culturally sensitive communication.",
            },
          ]}
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />

        
        <ContentSection
          title="How We Support Your Communication Needs"
          contentList={[
            {
              text: "Personalized On-Site Interpretation: Our interpreters provide in-person assistance for meetings, legal proceedings, medical appointments, and conferences, ensuring accurate and professional communication in any setting.",
              links: [
                {
                  linkText: "Personalized On-Site Interpretation",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "conferences",
                  link: "/interpretation-types/vri/skype-interpretation-services",
                },
                {
                  linkText: "legal proceedings",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "medical appointments",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Accessible Phone Interpretation: For immediate or remote needs, our phone interpretation services connect you with expert linguists 24/7, providing rapid language solutions.",
              links: [
                {
                  linkText: "Accessible Phone Interpretation",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Virtual Communication Made Easy: Our video remote interpretation (VRI) services are ideal for digital events, webinars, and hybrid meetings, enabling seamless multilingual communication in real time.",
              links: [
                {
                  linkText: "Virtual Communication Made Easy",
                  link: "/interpretation-types/vri",
                },
                {
                  linkText: "video remote interpretation (VRI)",
                  link: "/interpretation-types/vri",
                },
                {
                  linkText: "digital events",
                  link: "/interpretation-types/vri/google-meet-interpretation-services",
                },
              ],
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks/>

        <ContentSection
          title="Industries We Serve in El Paso"
          contentList={[
            {
              text: "Healthcare: Supporting providers and patients in hospitals, clinics, and private practices with clear communication.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal: Providing court-certified interpreters for trials, depositions, and legal consultations.",
              links: [
                {
                  linkText: "Legal",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Corporate: Facilitating international business negotiations, team training, and conferences.",
              links: [
                {
                  linkText: "Corporate",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Education: Bridging language gaps in schools, universities, and community programs.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
          ]}
          additionalContent="From Downtown El Paso to neighborhoods like Kern Place, Mission Valley, and surrounding areas, Trusted Interpreters delivers reliable interpretation services wherever you need them."
          imageUrl={PeopleImage1}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={elPasoBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Us Today"
          description="Ensure effective communication in El Paso with the help of Trusted Interpreters. Contact us today to learn more about our expert interpretation services and how we can support your needs."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default ElPaso;
