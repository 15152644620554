import React from "react";
import { Link } from "react-router-dom";

interface Subsection {
  title: string;
  description: string;
  icon?: React.ReactNode;
  link: string;
}

interface SubsectionNavigatorProps {
  subsections: Subsection[];
}

const SubsectionNavigator: React.FC<SubsectionNavigatorProps> = ({ subsections }) => {
  let gridClass = "grid-cols-1 md:grid-cols-3";

  if (subsections.length === 2) {
    gridClass = "grid-cols-2 justify-center";
  } else if (subsections.length > 3) {
    gridClass = "grid-cols-1 md:grid-cols-2";
  }

  return (
    <div className={`grid ${gridClass} gap-6`}>
      {subsections.map((subsection, index) => (
        <Link
          to={subsection.link}
          key={index}
          className="p-6 bg-white shadow-lg rounded-lg hover:shadow-xl transition"
        >
          <div className="flex items-center space-x-4">
            {subsection.icon && <div className="text-blue-600">{subsection.icon}</div>}
            <h3 className="text-xl font-bold text-gray-800">{subsection.title}</h3>
          </div>
          <p className="mt-2 text-gray-600">{subsection.description}</p>
        </Link>
      ))}
    </div>
  );
};

export default SubsectionNavigator;
