import React, { useState } from "react";

interface PricingPlan {
  icon: React.ElementType;
  title: string;
  price: number;
  description: string;
  benefits: string[];
  url: string; // URL específica para el servicio
}

interface PricingPlansProps {
  title: string;
  plans: PricingPlan[];
  subtitleClassName?: string;
}

const PricingPlans: React.FC<PricingPlansProps> = ({ title, plans, subtitleClassName }) => {
  const defaultSubtitleClass = "text-lg text-center text-gray-600 mb-8";
  return (
    <div className="container mx-auto px-4 py-2">
      {/* Title */}
      <h2 className="text-3xl font-bold text-center text-gray-800 mb-4">
        {title}
      </h2>
      <p className={`text-center text-gray-600 mb-8 ${subtitleClassName || "text-lg"}`}>
        Choose the service that suits your needs.
      </p>
      
      {/* Grid */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {plans.map((plan, index) => (
          <PricingPlanCard key={index} plan={plan} />
        ))}
      </div>
    </div>
  );
};

const PricingPlanCard: React.FC<{ plan: PricingPlan }> = ({ plan }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded((prev) => !prev);

  const handleRedirect = () => {
    const target = document.getElementById("quote-form");

    if (target) {
      target.scrollIntoView({ behavior: "smooth" });
    } else {
      localStorage.setItem("selectedServiceURL", plan.url);
      window.location.href = "/free-quote";
    }  
  };

  return (
    <div className="group relative flex flex-col mx-auto w-full max-w-sm bg-white rounded-2xl shadow-2xl transition-all duration-300 p-8 xl:p-12 hover:shadow-xl">
      {/* Icon */}
      <div className="w-16 h-16 rounded-full bg-[#025f99] mx-auto flex justify-center items-center transition-all duration-300 group-hover:bg-[#014a73]">
        <plan.icon className="w-6 h-6 text-white transition-all duration-300 group-hover:text-gray-100" />
      </div>
      {/* Title */}
      <h3 className="font-manrope text-xl font-bold my-7 text-center text-[#025f99] group-hover:text-[#014a73]">
        {plan.title}
      </h3>
      {/* Price */}
      <div className="flex flex-col items-center justify-center text-gray-900">
        <div className="text-4xl font-medium">
          From ${plan.price}
          <span className="text-xl text-gray-500 ml-2">
            {plan.title === "Over-The-Phone" ? "/minute" : "/hour"}
          </span>
        </div>
      </div>
      {/* Benefits Toggle */}
      <div className="relative">
        <p className="text-base text-gray-500 text-center mt-1">
          Check service benefits
        </p>
        <button
          className="absolute left-1/2 transform -translate-x-1/2 text-[#025f99] hover:text-[#014a73]"
          onClick={toggleExpand}
        >
          <svg
            className={`w-10 h-10 transition-transform duration-300 ${
              isExpanded ? "rotate-180" : "rotate-0"
            }`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
        <ul
          className={`mt-12 overflow-hidden transition-all duration-300 ${
            isExpanded ? "max-h-screen opacity-100 mb-6" : "max-h-0 opacity-0"
          } space-y-6 text-left text-lg text-gray-600`}
        >
          {plan.benefits.map((benefit, idx) => (
            <li key={idx} className="flex items-center space-x-3.5">
              <span className="w-1.5 h-1.5 rounded-full bg-[#025f99]"></span>
              <span>{benefit}</span>
            </li>
          ))}
        </ul>
      </div>
      {/* Get Quote */}
      <button
        onClick={handleRedirect}
        className="py-2.5 px-5 bg-[#025f99] shadow-sm rounded-full transition-all duration-500 text-base text-white font-semibold text-center w-fit mx-auto group-hover:bg-[#014a73]"
      >
        {`Get ${plan.title}`} <br /> {`Interpretation Quote`}
      </button>
    </div>
  );
};

export default PricingPlans;
