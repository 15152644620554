import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import OnsiteImage2 from "../../../src/assets/images/adobe/onsite-img-2.webp";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import Helmet from "react-helmet";
// images
import ArabicImage from "../../../src/assets/images/vectors/arabic.png";
import PeopleImage from "../../../src/assets/images/adobe/Houston1.webp";
import PeopleImage1 from "../../../src/assets/images/adobe/interpreter.webp";
import PeopleImage2 from "../../../src/assets/images/adobe/Phoenix2.webp";



const ArabicExpertise: React.FC = () => {
  const arabicBenefits = [
    {
      icon: FaAward,
      title: "Certified Professionals",
      text: "Our interpreters are certified and experienced, providing reliable and accurate services.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Expertise",
      text: "With a deep understanding of cultural nuances, our interpreters ensure respectful and effective communication.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Service",
      text: "From small towns to major cities, we provide Arabic interpretation services across the U.S.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Arabic Interpretation Services US</title>
        <meta
          name="description"
          content="Trusted Interpreters delivers accurate Arabic interpretation nationwide."
        />
      </Helmet>
      <PageHeader
        title="Professional Arabic Interpretation Services Across the U.S."
        description="Trusted Interpreters delivers expert Arabic interpretation services, connecting businesses, organizations, and individuals with Arabic-speaking audiences. Whether it’s for a corporate event, medical consultation, or legal proceeding, our certified interpreters ensure accurate and culturally sensitive communication."
        imageUrl={ArabicImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Bridging Language Gaps with Arabic Interpretation"
          content="As one of the most widely spoken languages globally, Arabic has significant linguistic diversity with regional dialects and cultural nuances. Our interpreters are skilled in Modern Standard Arabic as well as regional dialects, providing the precision and cultural understanding needed for effective communication."
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Examples of How We Help"
          content="Our Arabic interpretation services are designed to meet the unique challenges of different scenarios, such as:"
          contentList={[
            {
              text: "Business Success: Build trust with Arabic-speaking clients and partners through accurate interpretation in negotiations and presentations.",
              links: [
                {
                  linkText: "Business Success",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Community Engagement: Support government programs, social services, and community outreach for Arabic-speaking populations.",
              links: [
                {
                  linkText: "Community Engagement",
                  link: "/expertise/immigration-interpretation",
                },
              ],
            },
            {
              text: "Educational Inclusion: Assist Arabic-speaking students and families in schools and academic events.",
              links: [
                {
                  linkText: "Educational Inclusion",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
            {
              text: "Healthcare Communication: Enable clear patient-provider discussions for Arabic-speaking individuals.",
              links: [
                {
                  linkText: "Healthcare Communication",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal Proceedings: Ensure fairness and understanding in courtrooms, depositions, and legal consultations.",
              links: [
                {
                  linkText: "Legal Proceedings",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
          ]}
          imageUrl={PeopleImage1}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks currentLanguage="Arabic"/>

        <ContentSection
          title="What Makes Our Arabic Interpretation Unique:"
          contentList={[
            {
              text: "Dialect Specialization: Expertise in a variety of dialects, including Levantine, Gulf, Egyptian, and North African Arabic.",
            },
            {
              text: "Industry Knowledge: Interpreters trained in specialized fields like healthcare, law, and business.",
            },
            {
              text: "Real-Time Support: On-site, over-the-phone, and video remote interpretation options tailored to your needs.",
            },
          ]}
          additionalContent="With interpreters located across the U.S., Trusted Interpreters ensures that Arabic interpretation services are available wherever you need them. Whether for a local meeting or a nationwide event, we provide reliable solutions to suit your needs."
          imageUrl={PeopleImage2}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={arabicBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Connect with Trusted Interpreters Today"
          description="Whether you're expanding your business, hosting an event, or providing essential services, Trusted Interpreters ensures clear communication with Arabic-speaking audiences. Contact us today to schedule a professional interpreter and make your interactions seamless."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default ArabicExpertise;