/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";

const SubFooter: React.FC = () => {
  
  useEffect(() => {
    const loadCallRail = () => {
      const script = document.createElement("script");
      script.src = "//cdn.callrail.com/companies/264620413/5a552952aa7fdafcc9fe/12/swap.js";
      script.async = true;
      script.onload = () => {
        // Esperamos un poco para asegurarnos que el DOM está listo
        setTimeout(() => {
          if (
            window &&
            (window as any).calltrk &&
            typeof (window as any).calltrk._replaceNumbers === "function"
          ) {
            (window as any).calltrk._replaceNumbers();
          }
        }, 300); // Ajusta si hace falta más tiempo
      };
      document.body.appendChild(script);
    };
    
    loadCallRail();
  }, []);
  
  return (
    <footer className="bg-gray-900 text-white">
      {/* sub-footer */}
      <div className="bg-gray-800 py-4 text-center text-sm text-gray-400">
        <span>
          &copy; 2025 Trusted Interpreters. All rights reserved.{" "}
          <a href="/sitemap.xml" className="hover:underline">
            Sitemap
          </a>{" "}
          |{" "}
          <a href="/terms-and-conditions" className="hover:underline">
            Terms and Conditions
          </a>{" "}
          |{" "}
          <a href="/privacy-policy" className="hover:underline">
            Privacy Policy
          </a>
        </span>
      </div>
    </footer>
  );
};

export default SubFooter;