import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import Helmet from "react-helmet";
// images
import PeopleImage from "../../../src/assets/images/adobe/adobe6.webp";
import jacksonville from "../../../src/assets/images/adobe/jacksonville.webp";
import jacksonville1 from "../../../src/assets/images/adobe/jacksonville1.webp";
import jacksonville2 from "../../../src/assets/images/adobe/jacksonville2.webp";

const Jacksonville: React.FC = () => {
  const jacksonvilleBenefits = [
    {
      icon: FaAward,
      title: "Experienced Professionals",
      text: "We ensure accurate communication while respecting cultural nuances.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Understanding",
      text: "Our interpreters are certified, trained, and fluent in over 200 languages.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in Jacksonville, delivering consistent support wherever needed.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services Jacksonville</title>
        <meta
          name="description"
          content="Trusted Interpreters offers accurate interpretation services in Jacksonville."
        />
      </Helmet>
      <PageHeader
        title="Professional Interpretation Services in Jacksonville for Every Need"
        description="Trusted Interpreters provides professional language solutions tailored to Jacksonville’s businesses, healthcare providers, legal professionals, and educators. With certified interpreters fluent in over 200 languages, we deliver precise and culturally aware communication for any industry or event."
        imageUrl={jacksonville}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Choose Trusted Interpreters in Jacksonville?"
          contentList={[
            {
              text: "Experienced Interpreters: Our certified professionals are fluent in over 200 languages and specialize in industry-specific terminology.",
            },
            {
              text: "Tailored Solutions: From one-time events to ongoing support, we customize our services to fit your unique requirements.",
              links: [
                {
                  linkText: "one-time events",
                  link: "/interpretation-types/phone-interpretation/one-time-service",
                },
                {
                  linkText: "ongoing support",
                  link: "/interpretation-types/phone-interpretation/monthly-recurrent-service",
                },
              ],
            },
            {
              text: "Local Expertise: We understand Jacksonville’s diverse industries and communities, ensuring accurate and culturally aware communication.",
            },
          ]}
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />

        
        <ContentSection
          title="Customized Interpretation Services"
          contentList={[
            {
              text: "In-Person Interpretation for Critical Needs: Our interpreters deliver on-site services for conferences, legal hearings, medical appointments, and corporate meetings, ensuring effective communication in real-time.",
              links: [
                {
                  linkText: "In-Person Interpretation",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "conferences",
                  link: "/interpretation-types/on-site/consecutive",
                },
                {
                  linkText: "corporate meetings",
                  link: "/expertise/business-interpretation",
                },
                {
                  linkText: "legal hearings",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "medical appointments",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "On-Demand Phone Interpretation: Connect with professional interpreters instantly for urgent or remote communication needs. Our phone interpretation services are available 24/7.",
              links: [
                {
                  linkText: "On-Demand Phone Interpretation",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Virtual Language Solutions: For virtual meetings, webinars, or hybrid events, our video remote interpretation (VRI) services provide seamless real-time multilingual communication.",
              links: [
                {
                  linkText: "Virtual Language Solutions",
                  link: "/interpretation-types/vri",
                },
                {
                  linkText: "virtual meetings",
                  link: "/interpretation-types/vri/skype-interpretation-services",
                },
              ],
            },
          ]}
          imageUrl={jacksonville1}
          direction="right"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks/>

        <ContentSection
          title="Industries We Support in Jacksonville"
          contentList={[
            {
              text: "Healthcare: Helping patients and providers communicate clearly in hospitals, clinics, and medical offices.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal Services: Offering court-certified interpreters for trials, depositions, and legal consultations.",
              links: [
                {
                  linkText: "Legal Services",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Business: Supporting international negotiations, corporate training, and conferences.",
              links: [
                {
                  linkText: "Business",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Education: Bridging language gaps in schools, universities, and training programs.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
          ]}
          additionalContent="From Downtown Jacksonville to neighborhoods like Riverside, San Marco, and Southside, Trusted Interpreters delivers professional language solutions throughout the city and beyond."
          imageUrl={jacksonville2}
          direction="left"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={jacksonvilleBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Us Today"
          description="Let us help you overcome language barriers and achieve clear communication. Contact Trusted Interpreters today to learn more about our interpretation services in Jacksonville and how we can assist you."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Jacksonville;
