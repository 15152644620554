import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import CallToAction from "../../../components/CallToAction";
import { FaSlidersH } from "react-icons/fa";
import MeetImage from "../../../../src/assets/images/vectors/google-meet.png";
import OnsiteImage1 from "../../../../src/assets/images/adobe/meeting.webp";
import OnsiteImage2 from "../../../../src/assets/images/adobe/jacksonville1.webp";
import OnsiteImage3 from "../../../../src/assets/images/adobe/adobe20.webp";
import { FaHeadset, FaUserTie } from "react-icons/fa6";
import Helmet from "react-helmet";


const GoogleMeet: React.FC = () => {
  const googleBenefits = [
    {
      icon: FaUserTie,
      title: "Highly Qualified Interpreters",
      text: "Our interpreters are fluent in over 200 languages and trained in various industries, from corporate to healthcare.",
    },
    {
      icon: FaHeadset,
      title: "Technical Expertise",
      text: "We assist with setting up and managing interpretation features, ensuring a smooth experience for your participants.",
    },
    {
      icon: FaSlidersH,
      title: "Customizable Services",
      text: "Tailored solutions for events of all sizes, from small meetings to large-scale conferences.",
    },
  ];
  

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Professional Zoom Interpretation US</title>
        <meta
          name="description"
          content="Expert Google Meet Interpretation US"
        />
      </Helmet>
      <PageHeader
        title="Expert Google Meet Interpretation for Virtual Conferences"
        description="Trusted Interpreters ensures effective communication with Google Meet interpretation."
        imageUrl={MeetImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="What is Google Meet Interpretation?"
          content="Google Meet interpretation involves real-time translation provided by professional interpreters during virtual sessions. It is an ideal solution for organizations and businesses hosting international participants or requiring accessibility for diverse audiences."
          imageUrl={OnsiteImage1}
          direction="right"
        />

        {/* Workflow */}
        <ProcessWorkflow process="virtual" />


        <ContentSection
          title="Industries We Serve with Google Meet Interpretation"
          content="Our interpretation services on Google Meet cater to various sectors, including:"
          contentList={[
            {
              text: "Corporate: Enhance global team collaboration and communication.",
              links: [
                {
                  linkText: "Corporate",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Education: Support multilingual classrooms and academic webinars.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
            {
              text: "Healthcare: Enable effective patient-provider communication in telehealth sessions.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal: Facilitate virtual legal consultations and hearings.",
              links: [
                {
                  linkText: "Legal",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>
        <ContentSection
          title="Benefits of Google Meet Interpretation:"
          contentList={[
            {
              text: "Real-Time Communication: Ensures participants can engage without language constraints.",
            },
            {
              text: "Global Accessibility: Supports multilingual collaboration across regions.",
            },
            {
              text: "User-Friendly Integration: Works seamlessly with the Google Meet platform.",
            },
          ]}
          additionalContent="Trusted Interpreters provides Google Meet interpretation services across the U.S. and internationally. Our network of interpreters ensures you have access to expert services whenever and wherever you need them."
          imageUrl={OnsiteImage3}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters for Google Meet?">
          <KeyBenefits benefits={googleBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Trusted Interpreters Today"
          description="Make your virtual meetings more inclusive with our professional Google Meet interpretation services. Contact us now to discuss your needs and schedule an interpreter for your next event."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default GoogleMeet;