import React from "react";
import Footer from "../../components/Footer";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import AboutUsExtras from "../../components/AboutUsExtras";
import AboutUsImage from '../../assets/images/vectors/about-us.png';
import WhatSetsUsApart from "../../components/AboutUsBenefits";
import CallToAction from "../../components/CallToAction";
import AboutUsSchemaMarkup from "../../components/AboutUsSchemaMarkup";
import Helmet from "react-helmet";

const AboutUs: React.FC = () => {
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>About Us | Trusted Interpreters</title>
        <meta
          name="description"
          content="Learn about Trusted Interpreters and our mission to provide expert services."
        />
      </Helmet>
      <div className="container mx-auto px-4 py-16">
        <div className="sm:flex items-center max-w-screen-xl mx-auto">
          <div className="md:w-1/2 flex justify-center">
            <img
              src={AboutUsImage}
              alt="About Us"
              className="w-full max-w-sm md:max-w-md animate-slide-in"
            />
          </div>
          <div className="sm:w-1/2 p-5">
            <div className="text animate-slide-in">
              <span className="text-gray-500 border-b-2 border-blue-600 uppercase">
                About us
              </span>
              <h2 className="my-4 font-bold text-3xl sm:text-4xl">
                About <span className="text-[#024772]">Trusted Interpreters</span>: <br/> Who We Are
              </h2>
              <p className="text-gray-700 mb-6">
                At Trusted Interpreters, we are dedicated to breaking language barriers and fostering clear communication across industries and communities.
                With years of experience, a vast network of certified interpreters, and a commitment to excellence, we are your trusted partner for professional interpretation services in the United States.
              </p>
              <p className="text-gray-700 mb-6">
              Our mission is simple: to provide high-quality interpretation services tailored to your unique needs.
              Whether it’s a one-time event or ongoing language support, we strive to deliver accuracy, professionalism, and cultural sensitivity in every interaction.
              </p>
              <button className="bg-[#025f99] text-white px-6 py-3 rounded-lg shadow-md hover:bg-[#014a73] transition">
                Request a Quote Now
              </button>
            </div>
          </div>
        </div>

        <AboutUsExtras />

        <WhatSetsUsApart />

        <div className="container mx-auto px-4 pb-20 pt-6">
          <InteractiveMap />
        </div>
        
        <CallToAction
          title="Join Our Journey"
          description="Have questions or need assistance? Contact us today to learn more about how Trusted Interpreters can support your multilingual communication needs."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        <FAQ />
      </div>
      <Footer />
      <AboutUsSchemaMarkup />
    </div>
  );
};

export default AboutUs;
