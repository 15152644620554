import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import { Helmet } from "react-helmet";
// images
import SanDiegoImage from "../../../src/assets/images/adobe/SanDiego.webp";
import SanDiegoImage1 from "../../../src/assets/images/adobe/SanDiego1.webp";
import SanDiegoImage2 from "../../../src/assets/images/adobe/people-img.webp";
import SanDiegoImage3 from "../../../src/assets/images/adobe/medical1.webp";

const SanDiego: React.FC = () => {
  const sanDiegoBenefits = [
    {
      icon: FaAward,
      title: "Experienced Professionals",
      text: "We ensure accurate communication while respecting cultural nuances.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Understanding",
      text: "Our interpreters are certified, trained, and fluent in over 200 languages.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in San Diego, delivering consistent support wherever needed.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services San Diego</title>
        <meta
          name="description"
          content="Trusted Interpreters delivers professional interpretation services in San Diego."
        />
      </Helmet>

      <PageHeader
        title="Professional Interpretation Services in San Diego for Every Occasion"
        description="Trusted Interpreters delivers expert interpretation services designed to meet the diverse linguistic needs of San Diego’s vibrant communities and industries. Whether for business meetings, legal proceedings, healthcare appointments, or educational events, our certified interpreters provide accurate and culturally sensitive communication in over 200 languages."
        imageUrl={SanDiegoImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Choose Our Services in San Diego?"
          contentList={[
            {
              text: "Local Expertise: Our interpreters understand the unique needs of San Diego’s diverse communities and industries.",
            },
            {
              text: "Versatile Solutions: From face-to-face meetings to virtual events, we provide flexible interpretation options.",
              links: [
                {
                  linkText: "face-to-face meetings",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "virtual events",
                  link: "/interpretation-types/vri",
                },
              ],
            },
            {
              text: "Certified Professionals: All interpreters are fluent in over 200 languages and experienced in various fields.",
            },
          ]}
          imageUrl={SanDiegoImage1}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Comprehensive Services for Every Setting"
          contentList={[
            {
              text: "On-Site Interpretation: For in-person events, legal hearings, or business meetings, our interpreters travel to your location to ensure accurate and culturally sensitive communication.",
              links: [
                {
                  linkText: "On-Site Interpretation",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "legal hearings",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "business meetings",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Over-the-Phone Interpretation: Ideal for urgent or remote situations, our phone interpretation service connects you with professional linguists instantly.",
              links: [
                {
                  linkText: "Over-the-Phone Interpretation",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Video Remote Interpretation (VRI): Seamless multilingual communication for virtual meetings, webinars, or hybrid events, with real-time interpretation support.",
              links: [
                {
                  linkText: "Video Remote Interpretation (VRI)",
                  link: "/interpretation-types/vri",
                },
              ],
            },
          ]}
          imageUrl={SanDiegoImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks />

        <ContentSection
          title="Supporting San Diego’s Key Industries"
          contentList={[
            {
              text: "Healthcare: Enhancing communication between patients and providers in hospitals, clinics, and private practices.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal Services: Offering certified court interpreters for depositions, trials, and consultations.",
              links: [
                {
                  linkText: "Legal Services",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Business: Assisting in international negotiations, corporate training sessions, and conferences.",
              links: [
                {
                  linkText: "Business",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Education: Bridging language gaps in schools, universities, and training programs.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
          ]}
          additionalContent="Whether you’re in Downtown San Diego, La Jolla, Chula Vista, or beyond, Trusted Interpreters provides reliable language solutions tailored to your location and needs."
          imageUrl={SanDiegoImage3}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={sanDiegoBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Take the Next Step"
          description="Don’t let language barriers slow you down. Contact Trusted Interpreters today to learn more about our professional interpretation services in San Diego and how we can support your success."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default SanDiego;
