import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import client from '../../services/contentfulClient';
import Footer from '../../components/Footer';

// Helper function to format dates
const formatDate = (dateString: string): string => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', { 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric' 
  }).format(date);
};

const PostPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [relatedPosts, setRelatedPosts] = useState<any[]>([]);
  
  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node: any, children: any) => (
        <h1 className="text-3xl font-bold my-6 text-gray-800">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node: any, children: any) => (
        <h2 className="text-2xl font-bold my-5 text-gray-800">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node: any, children: any) => (
        <h3 className="text-xl font-bold my-4 text-gray-800">{children}</h3>
      ),
      [BLOCKS.OL_LIST]: (node: any, children: any) => (
        <ol className="list-decimal pl-6 mb-6 space-y-2">{children}</ol>
      ),
      [BLOCKS.UL_LIST]: (node: any, children: any) => (
        <ul className="list-disc pl-6 mb-6 space-y-2">{children}</ul>
      ),
      [BLOCKS.LIST_ITEM]: (node: any, children: any) => {
        // Check if the content includes the check mark symbol
        const text = node.content[0].content?.[0]?.value || '';
        if (text.includes('✔️') || text.includes('✓')) {
          return (
            <li className="mb-2 flex items-start">
              <span className="text-green-600 font-bold mr-2 flex-shrink-0">✓</span>
              <span>{text.replace(/[✔️✓]\s?/, '')}</span>
            </li>
          );
        }
        return <li className="mb-2">{children}</li>;
      },
      [BLOCKS.PARAGRAPH]: (() => {
        let paragraphCount = 0;
  
        return (node: any, children: any) => {
          const currentIndex = paragraphCount;
          paragraphCount++;
          
          // Handle empty paragraphs (only whitespace/newlines)
          if (
            node.content.length === 1 && 
            node.content[0].value && 
            node.content[0].value.trim() === ''
          ) {
            return <div className="h-4"></div>; // spacer div instead of empty paragraph
          }
  
          return (
            <>
              <p className="mb-5 whitespace-pre-line text-gray-700 leading-relaxed">{children}</p>
              {currentIndex === 0 && post?.fields.media?.fields?.file?.url && (
                <div className="flex justify-center mb-10 mt-6">
                  <img
                    src={post.fields.media.fields.file.url}
                    alt={post.fields.title || "Blog post image"}
                    className="max-w-full max-h-[500px] rounded-lg shadow-lg object-cover"
                  />
                </div>
              )}
            </>
          );
        };
      })(),
      [BLOCKS.QUOTE]: (node: any, children: any) => (
        <blockquote className="border-l-4 border-blue-500 pl-4 py-2 my-6 italic text-gray-700 bg-blue-50 rounded-r-md">
          {children}
        </blockquote>
      ),
      [BLOCKS.HR]: () => <hr className="my-8 border-t-2 border-gray-200" />,
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        if (node.data?.target?.fields) {
          const { file, title, description } = node.data.target.fields;
          if (file?.url) {
            return (
              <div className="my-8 flex flex-col items-center">
                <img 
                  src={file.url} 
                  alt={title || description || 'Embedded image'} 
                  className="rounded-lg shadow-md max-w-full max-h-[400px] object-cover"
                />
                {title && <p className="text-sm text-gray-500 mt-2 italic">{title}</p>}
              </div>
            );
          }
        }
        return null;
      },
      [INLINES.HYPERLINK]: (node: any, children: any) => (
        <a
          href={node.data.uri}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 underline hover:text-blue-800 transition-colors duration-200"
        >
          {children}
        </a>
      ),
    },
    renderMark: {
      [MARKS.BOLD]: (text: React.ReactNode) => <strong className="font-bold">{text}</strong>,
      [MARKS.ITALIC]: (text: React.ReactNode) => <em className="italic">{text}</em>,
      [MARKS.UNDERLINE]: (text: React.ReactNode) => <u className="underline">{text}</u>,
      [MARKS.CODE]: (text: React.ReactNode) => (
        <code className="bg-gray-100 text-pink-600 px-2 py-1 rounded font-mono text-sm">{text}</code>
      ),
    },
  };
   
  useEffect(() => {
    setIsLoading(true);
    client
      .getEntries({
        content_type: 'blogPost',
        'fields.slug': slug,
      })
      .then((response) => {
        if (response.items.length > 0) {
          setPost(response.items[0]);
          
          // Fetch related posts (last 3 excluding current)
          client.getEntries({
            content_type: 'blogPost',
            limit: 4,
            order: '-sys.createdAt' as any,
            'fields.slug[ne]': slug,
          })
          .then(relatedResponse => {
            setRelatedPosts(relatedResponse.items.slice(0, 3));
          })
          .catch(console.error);
        }
        setIsLoading(false);
      })
      .catch(error => {
        console.error(error);
        setIsLoading(false);
      });
  }, [slug]);

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 py-20 min-h-screen flex justify-center items-center">
        <div className="animate-pulse flex flex-col items-center">
          <div className="h-8 w-64 bg-gray-200 rounded mb-6"></div>
          <div className="h-4 w-32 bg-gray-200 rounded mb-12"></div>
          <div className="space-y-3 w-full max-w-xl">
            <div className="h-4 bg-gray-200 rounded"></div>
            <div className="h-4 bg-gray-200 rounded"></div>
            <div className="h-4 bg-gray-200 rounded"></div>
            <div className="h-4 bg-gray-200 rounded w-2/3"></div>
          </div>
        </div>
      </div>
    );
  }

  if (!post) {
    return (
      <div className="container mx-auto px-4 py-16 min-h-screen flex flex-col items-center justify-center">
        <h2 className="text-2xl font-bold text-gray-700 mb-4">Post not found</h2>
        <p className="text-gray-600 mb-6">The article you are looking for doesn't exist or has been moved.</p>
        <Link to="/blog" className="bg-[#025f99] hover:bg-blue-700 text-white font-medium py-2 px-6 rounded-md transition-colors duration-200">
          Back to blog
        </Link>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero section with large image */}
      {post.fields.media?.fields?.file?.url && (
        <div className="w-full h-[40vh] relative mb-8">
          <div className="absolute inset-0 bg-gradient-to-b from-black/40 to-black/10 z-10"></div>
          <img
            src={post.fields.media.fields.file.url}
            alt={post.fields.title}
            className="w-full h-full object-cover"
          />
          <div className="absolute bottom-0 left-0 right-0 z-20 container mx-auto px-4 py-10">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-2 drop-shadow-md">
              {post.fields.title}
            </h1>
          </div>
        </div>
      )}

      <div className="container mx-auto px-4 py-8 max-w-4xl">
        {/* Author & Date info */}
        <div className="flex items-center mb-8 text-gray-600">
          {post.fields.author && (
            <div className="flex items-center mr-6">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>
              <span>{post.fields.author}</span>
            </div>
          )}
          <div className="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
            <span>{formatDate(post.sys.createdAt)}</span>
          </div>
        </div>

        {/* If we don't have a hero image, show the title here */}
        {!post.fields.media?.fields?.file?.url && (
          <h1 className="text-4xl font-bold mb-8 text-gray-800">{post.fields.title}</h1>
        )}

        {/* Main content */}
        <div className="bg-white rounded-xl shadow-sm p-6 md:p-10 mb-12">
          <div className="prose prose-lg max-w-none">
            {documentToReactComponents(post.fields.body, options)}
          </div>
          
          {/* Tags if available */}
          {post.fields.tags && post.fields.tags.length > 0 && (
            <div className="mt-10 pt-6 border-t border-gray-200">
              <div className="flex flex-wrap gap-2">
                {post.fields.tags.map((tag: string, index: number) => (
                  <span 
                    key={index} 
                    className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Related posts */}
        {relatedPosts.length > 0 && (
          <div className="mb-16">
            <h3 className="text-2xl font-bold mb-6 text-gray-800">Related Articles</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {relatedPosts.map((relatedPost: any) => (
                <Link 
                  to={`/blog/${relatedPost.fields.slug}`} 
                  key={relatedPost.sys.id}
                  className="bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-shadow duration-200"
                >
                  {relatedPost.fields.media?.fields?.file?.url && (
                    <img 
                      src={relatedPost.fields.media.fields.file.url} 
                      alt={relatedPost.fields.title}
                      className="w-full h-48 object-cover"
                    />
                  )}
                  <div className="p-4">
                    <h4 className="font-semibold text-lg mb-2 line-clamp-2">{relatedPost.fields.title}</h4>
                    <p className="text-sm text-gray-500">{formatDate(relatedPost.sys.createdAt)}</p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}

        {/* Back to blog button */}
        <div className="text-center mb-12">
          <Link 
            to="/blog" 
            className="inline-flex items-center bg-[#025f99] hover:bg-[#014a73] text-white font-medium py-2 px-6 rounded-md transition-colors duration-200"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16l-4-4m0 0l4-4m-4 4h18" />
            </svg>
            Back to blog
          </Link>
        </div>
      </div>
      
      <Footer />
    </div>
  );
};

export default PostPage;
