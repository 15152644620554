import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import Helmet from "react-helmet";
// images
import PeopleImage from "../../../src/assets/images/adobe/people-img.webp";
import NashvilleImg from "../../../src/assets/images/adobe/Nashville.webp";
import PeopleImage2 from "../../../src/assets/images/adobe/onsite-img.webp";
import PeopleImage3 from "../../../src/assets/images/adobe/Nashville 2.webp";

const Nashville: React.FC = () => {
  const nashvilleBenefits = [
    {
      icon: FaAward,
      title: "Experienced Professionals",
      text: "We ensure accurate communication while respecting cultural nuances.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Understanding",
      text: "Our interpreters are certified, trained, and fluent in over 200 languages.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in Nashville, delivering consistent support wherever needed.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services Nashville</title>
        <meta
          name="description"
          content="Trusted Interpreters provides accurate interpretation services across Nashville."
        />
      </Helmet>
      <PageHeader
        title="Reliable Interpretation Services in Nashville for Every Industry"
        description="Trusted Interpreters offers expert interpretation services tailored to the unique needs of Nashville’s diverse industries and communities. From healthcare to legal services, we help you communicate effectively and break down language barriers."
        imageUrl={NashvilleImg}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Trusted Interpreters is Nashville’s Choice"
          contentList={[
            {
              text: "Certified Professionals: Our interpreters are fluent in over 200 languages, specializing in industry-specific terminology and cultural nuances.",
            },
            {
              text: "Flexible Services: We offer on-site, over-the-phone, and video remote interpretation (VRI) to accommodate your unique requirements.",
              links: [
                {
                  linkText: "on-site",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "over-the-phone",
                  link: "/interpretation-types/phone-interpretation",
                },
                {
                  linkText: "video remote interpretation (VRI)",
                  link: "/interpretation-types/vri",
                },
              ],
            },
            {
              text: "Local Expertise: We understand the needs of Nashville’s industries and its multilingual population, ensuring accurate and effective communication.",
            },
          ]}
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />

        
        <ContentSection
          title="Language Solutions Designed for Nashville"
          contentList={[
            {
              text: "In-Person Interpretation for Key Events: Whether it’s a corporate meeting, legal proceeding, or healthcare consultation, our on-site interpreters deliver real-time, culturally aware communication.",
              links: [
                {
                  linkText: "In-Person Interpretation for Key Events",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "corporate meeting",
                  link: "/expertise/business-interpretation",
                },
                {
                  linkText: "legal proceeding",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "healthcare consultation",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Instant Over-the-Phone Support: For urgent language needs, our phone interpretation service connects you with professional linguists instantly, 24/7.",
              links: [
                {
                  linkText: "Instant Over-the-Phone Support",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Virtual Communication Made Easy: For webinars, virtual meetings, and hybrid events, our VRI services ensure seamless multilingual communication in real time.",
              links: [
                {
                  linkText: "Virtual Communication Made Easy",
                  link: "/interpretation-types/vri",
                },
                {
                  linkText: "VRI services",
                  link: "/interpretation-types/vri",
                },
                {
                  linkText: "virtual meetings",
                  link: "/interpretation-types/vri/microsoft-teams-interpretation-services",
                },
              ],
            },
          ]}
          imageUrl={PeopleImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks/>

        <ContentSection
          title="Industries We Support in Nashville"
          contentList={[
            {
              text: "Healthcare: Enhancing communication between patients and providers in hospitals, clinics, and private practices.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal Services: Offering court-certified interpreters for trials, depositions, and legal consultations.",
              links: [
                {
                  linkText: "Legal Services",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Business and Corporate: Facilitating international negotiations, training sessions, and conferences.",
              links: [
                {
                  linkText: "Business and Corporate",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Education: Bridging language gaps in schools, universities, and training programs.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
          ]}
          additionalContent="From Downtown Nashville to neighborhoods like East Nashville, The Gulch, and Green Hills, Trusted Interpreters provides comprehensive language solutions across the city and surrounding areas."
          imageUrl={PeopleImage3}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={nashvilleBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Trusted Interpreters Today"
          description="Don’t let language barriers slow you down. Contact Trusted Interpreters today to learn more about our professional interpretation services in Nashville and how we can support your success."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Nashville;
