import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import CallToAction from "../../../components/CallToAction";
import { FaCalendarCheck, FaLanguage, FaMoneyBillWave } from "react-icons/fa";
import LegalImage from "../../../../src/assets/images/vectors/legal.png";
import LegalImage1 from "../../../../src/assets/images/adobe/Legal.webp";
import LegalImage2 from "../../../../src/assets/images/adobe/adobe14.webp";
import OnsiteImage1 from "../../../../src/assets/images/adobe/onsite-img-1.webp";
import OnsiteImage2 from "../../../../src/assets/images/adobe/Legal1.webp";
import Helmet from "react-helmet";

const LegalInterpretation: React.FC = () => {
  const legalBenefits = [
    {
      icon: FaMoneyBillWave,
      title: "Cost-Effective",
      text: "Predictable monthly billing with no surprise fees.",
    },
    {
      icon: FaCalendarCheck,
      title: "Consistent Access",
      text: "Dedicated interpreters available at any time, ensuring reliability for ongoing needs.",
    },
    {
      icon: FaLanguage,
      title: "Wide Language Coverage",
      text: "Over 200 languages and dialects to meet diverse communication demands.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Reliable Legal Interpretation US</title>
        <meta
          name="description"
          content="Trusted Interpreters provides accurate legal interpretation across the U.S."
        />
      </Helmet>
      <PageHeader
        title="Expert Legal Interpretation Services for Every Case"
        description="At Trusted Interpreters, we specialize in providing professional legal interpretation services to ensure precise communication in complex legal settings. Our certified interpreters have extensive experience and are available to support you in any jurisdiction across the United States."
        imageUrl={LegalImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Certified and Reliable Legal Interpreters"
          content="Our interpreters are:"
          contentList={[
            {
              text: "Court-Certified and Licensed: Approved to work in courts and legal settings nationwide.",
            },
            {
              text: "Specialized in Legal Language: Proficient in legal terminology and practices across multiple fields.",
            },
            {
              text: "Ethical and Confidential: Bound by strict confidentiality agreements and professional ethical standards.",
            },
          ]}
          imageUrl={OnsiteImage1}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Legal Interpretation Services We Offer"
          content="We provide interpretation services for various legal scenarios, including:"
          contentList={[
            {
              text: "Courtroom Proceedings: Trials, arraignments, and depositions.",
            },
            {
              text: "Attorney-Client Meetings: Facilitating clear and confidential communication.",
            },
            {
              text: "Immigration Matters: Supporting interviews, hearings, and documentation processes.",
            },
            {
              text: "Corporate Legal Work: Contract negotiations, compliance reviews, and mergers.",
            },
            {
              text: "Alternative Dispute Resolutions: Mediation and arbitration sessions.",
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <ContentSection
          title="Tailored Solutions for Every Legal Need"
          contentList={[
            {
              text: "Nationwide Availability: Our interpreters can assist you anywhere in the U.S., ensuring seamless service.",
            },
            {
              text: "Industry-Specific Expertise: From family law to corporate litigation, our interpreters are matched to your case’s specific requirements.",
            },
            {
              text: "Flexible Interpretation Options: We offer on-site, over-the-phone, and virtual legal interpretation to suit your needs.",
              links: [
                { linkText: "on-site", link: "/interpretation-types/on-site" },
                {
                  linkText: "over-the-phone",
                  link: "/interpretation-types/phone-interpretation",
                },
                {
                  linkText: "virtual legal interpretation",
                  link: "/interpretation-types/vri",
                },
              ],
            },
          ]}
          imageUrl={LegalImage1}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Our Legal Interpretation Services?">
          <KeyBenefits benefits={legalBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Secure Accurate Communication Today"
          description="Don’t leave legal communication to chance. Contact Trusted Interpreters to learn more about our expert legal interpretation services and ensure success in your next legal proceeding."
          buttonText="Contact Us"
          buttonLink="/free-quote"
        />

        <ContentSection
          title="Trusted by Legal Professionals Nationwide"
          content="Trusted Interpreters is a trusted partner for attorneys, law firms, courts, and corporate legal teams. With over 10,000 interpreters fluent in 200+ languages and dialects, we’re equipped to handle the most complex legal cases."
          imageUrl={LegalImage2}
          direction="left"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default LegalInterpretation;
