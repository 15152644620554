import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import Helmet from "react-helmet";
// images
import PeopleImage from "../../../src/assets/images/adobe/adobe4.webp";
import charlotte from "../../../src/assets/images/adobe/charlotte.webp";
import charlotte1 from "../../../src/assets/images/adobe/charlotte1.webp";
import charlotte2 from "../../../src/assets/images/adobe/charlotte2.webp";


const Charlotte: React.FC = () => {
  const charlotteBenefits = [
    {
      icon: FaAward,
      title: "Experienced Professionals",
      text: "We ensure accurate communication while respecting cultural nuances.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Understanding",
      text: "Our interpreters are certified, trained, and fluent in over 200 languages.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in Charlotte, delivering consistent support wherever needed.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services Charlotte</title>
        <meta
          name="description"
          content="Trusted Interpreters provides expert interpretation services in Charlotte."
        />
      </Helmet>
      <PageHeader
        title="Professional Interpretation Services for Charlotte"
        description="Trusted Interpreters offers expert interpretation services designed to meet the diverse communication needs of Charlotte’s businesses, healthcare providers, legal professionals, and educational institutions. With certified interpreters fluent in over 200 languages, we deliver precise and culturally sensitive communication tailored to your requirements."
        imageUrl={charlotte}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="What Makes Trusted Interpreters Unique in Charlotte?"
          contentList={[
            {
              text: "Expert Linguists: Our team includes certified interpreters fluent in over 200 languages, with expertise in a variety of industries.",
            },
            {
              text: "Custom Solutions: Whether for a one-time event or ongoing support, our services are tailored to meet your needs.",
              links: [
                {
                  linkText: "one-time event",
                  link: "/interpretation-types/phone-interpretation/one-time-service",
                },
                {
                  linkText: "ongoing support",
                  link: "/interpretation-types/phone-interpretation/monthly-recurrent-service",
                },
              ],
            },
            {
              text: "Comprehensive Coverage: From Uptown Charlotte to surrounding neighborhoods, we’re here to serve the entire Charlotte metro area.",
            },
          ]}
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />

        
        <ContentSection
          title="How We Deliver Our Interpretation Services"
          contentList={[
            {
              text: "Face-to-Face Accuracy: For in-person events like business meetings, court hearings, or medical appointments, our interpreters ensure seamless communication with cultural sensitivity and precision.",
              links: [
                {
                  linkText: "Face-to-Face Accuracy",
                  link: "/interpretation-types/on-site",
                },
                {
                  linkText: "court hearings",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "business meetings",
                  link: "/expertise/business-interpretation",
                },
                {
                  linkText: "medical appointments",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Real-Time Phone Support: Instantly connect with professional interpreters via phone for urgent or remote situations. Available around the clock, our service guarantees quick and reliable language assistance.",
              links: [
                {
                  linkText: "Real-Time Phone Support",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Virtual Solutions for Modern Needs: For digital environments like webinars, hybrid meetings, or virtual conferences, our video remote interpretation (VRI) services ensure effortless multilingual communication in real time.",
              links: [
                {
                  linkText: "Virtual Solutions for Modern Needs",
                  link: "/interpretation-types/vri",
                },
                {
                  linkText: "virtual conferences",
                  link: "/interpretation-types/vri/zoom-interpretation-services",
                },
              ],
            },
          ]}
          imageUrl={charlotte2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks/>

        <ContentSection
          title="Industries We Serve in Charlotte"
          contentList={[
            {
              text: "Banking and Finance: Supporting Charlotte’s financial sector with expert language services.",
              links: [
                {
                  linkText: "Banking and Finance",
                  link: "/expertise/financial-interpretation",
                },
              ],
            },
            {
              text: "Healthcare: Facilitating clear communication between patients and providers in clinics and hospitals.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal: Offering court-certified interpreters for legal consultations, depositions, and trials.",
              links: [
                {
                  linkText: "Legal",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Education: Helping schools and universities bridge language gaps for students and staff.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
          ]}
          additionalContent="From the busy streets of Uptown to neighborhoods like South End, Ballantyne, and NoDa, Trusted Interpreters is your local partner for professional language solutions. We’re here to serve businesses and individuals throughout the Charlotte metro area."
          imageUrl={charlotte1}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={charlotteBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Let’s Connect"
          description="Take the next step in breaking language barriers. Contact Trusted Interpreters today to learn more about our interpretation services in Charlotte and how we can help you achieve clear, effective communication."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Charlotte;
