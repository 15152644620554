import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import { Helmet } from "react-helmet";
import { FaAward, FaGlobe } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
// images
import NYImage from "../../../src/assets/images/adobe/NYC.webp";
import NYImage1 from "../../../src/assets/images/adobe/NYC1.webp";
import NYImage2 from "../../../src/assets/images/adobe/ESL.webp";
import PeopleImage from "../../../src/assets/images/adobe/people-img.webp";

const NewYork: React.FC = () => {
  const NYCBenefits = [
    {
      icon: FaAward,
      title: "Certified Professionals",
      text: "Our interpreters are highly trained and certified, ensuring reliable and precise interpretation.",
    },
    {
      icon: FaGlobe,
      title: "Cultural Awareness",
      text: "We prioritize cultural nuances to ensure your message resonates effectively.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Coverage",
      text: "Trusted Interpreters offers interpretation services in New York City, delivering consistent support wherever needed.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services NYC</title>
        <meta
          name="description"
          content="Accurate interpretation services tailored for businesses and events in NYC."
        />
      </Helmet>

      <PageHeader
        title="Professional Interpretation Services in New York City"
        description="Trusted Interpreters provides expert interpretation services tailored to the dynamic and multicultural landscape of New York. Whether for legal proceedings, corporate meetings, healthcare, or educational events, our certified interpreters ensure seamless communication across over 200 languages."
        imageUrl={NYImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Choose Trusted Interpreters in NYC?"
          contentList={[
            {
              text: "Expert Linguists: Our interpreters are fluent in over 200 languages, with extensive experience in legal, medical, business, and educational interpretation.",
              links: [
                {
                  linkText: "legal",
                  link: "/expertise/legal-interpretation",
                },
                {
                  linkText: "medical",
                  link: "/expertise/medical-interpretation",
                },
                {
                  linkText: "business",
                  link: "/expertise/business-interpretation",
                },
                {
                  linkText: "educational",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
            {
              text: "Tailored Solutions: We adapt our services to meet the specific needs of New York's diverse industries, events, and audiences.",
            },
            {
              text: "On-Demand Service: With interpreters available across all five boroughs, we provide flexible scheduling to suit your requirements.",
              links: [
                {
                  linkText: "On-Demand Service",
                  link: "/interpretation-types/phone-interpretation/one-time-service",
                },
              ],
            },
          ]}
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Services We Provide"
          contentList={[
            {
              text: "On-Site Interpretation: Perfect for conferences, legal settings, and corporate meetings. Our interpreters are available to travel anywhere in the NYC area.",
              links: [
                {
                  linkText: "On-Site Interpretation",
                  link: "/interpretation-types/on-site",
                },
              ],
            },
            {
              text: "Phone Interpretation: Connect instantly with a professional interpreter for urgent language needs.",
              links: [
                {
                  linkText: "Phone Interpretation",
                  link: "/interpretation-types/phone-interpretation",
                },
              ],
            },
            {
              text: "Video Remote Interpretation (VRI): Seamless remote solutions for virtual meetings and events.",
              links: [
                {
                  linkText: "Video Remote Interpretation (VRI)",
                  link: "/interpretation-types/vri",
                },
              ],
            },
          ]}
          imageUrl={NYImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks />

        <ContentSection
          title="Industries We Serve in NYC"
          content="New York City’s diverse economy demands interpretation expertise across many industries. We specialize in:"
          contentList={[
            {
              text: "Legal Services: Court-certified interpreters for depositions, trials, and client meetings.",
              links: [
                {
                  linkText: "Legal Services",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Healthcare: Facilitating communication between patients and providers in hospitals and clinics.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Education: Enhancing understanding in schools, universities, and training programs.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
          ]}
          additionalContent="Whether you’re in Manhattan, Brooklyn, Queens, the Bronx, or Staten Island, Trusted Interpreters is your partner for all interpretation needs. Our network of interpreters is ready to assist, no matter your location or industry."
          imageUrl={NYImage1}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={NYCBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Get Started Today"
          description="Don’t let language barriers stand in your way. Contact Trusted Interpreters for professional interpretation services in New York City and experience the difference we can make for your communication needs."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default NewYork;
