import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import OnsiteImage2 from "../../../src/assets/images/adobe/SanAntonio.webp";
import { FaBriefcase, FaGlobe } from "react-icons/fa6";
import { FaCogs } from "react-icons/fa";
// images
import ExpertiseImage from "../../../src/assets/images/adobe/undraw1.png";
import PeopleImage from "../../../src/assets/images/adobe/people-img.webp";
import Helmet from "react-helmet";

const Expertise: React.FC = () => {
  const expertiseBenefits = [
    {
      icon: FaBriefcase,
      title: "Expertise Across Industries",
      text: "Our interpreters are proficient in a wide range of fields, including legal, medical, business, education, and technical sectors.",
    },
    {
      icon: FaGlobe,
      title: "Cultural and Linguistic Accuracy",
      text: "Beyond language fluency, our professionals understand regional dialects, idiomatic expressions, and cultural nuances.",
    },
    {
      icon: FaCogs,
      title: "Customized Solutions",
      text: "We tailor our services to fit your industry-specific requirements, providing interpreters with the knowledge and skills needed for your project.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Expertise US</title>
        <meta
          name="description"
          content="Trusted Interpreters specializes in diverse industry-specific interpretation services."
        />
      </Helmet>
      <PageHeader
        title="Comprehensive Interpretation Expertise for All Industries"
        description="At Trusted Interpreters, we pride ourselves on delivering specialized interpretation services tailored to the unique needs of various industries. Our interpreters combine linguistic precision with subject-matter expertise, ensuring seamless and accurate communication for every project."
        imageUrl={ExpertiseImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Industries We Serve"
          contentList={[
            {
              text: "Legal: Court-certified interpreters for depositions, hearings, and trials. Adherence to strict confidentiality and legal ethics.",
              links: [
                {
                  linkText: "Legal",
                  link: "/expertise/legal-interpretation",
                }
              ],
            },
            {
              text: "Medical: HIPAA-compliant interpretation for hospitals, clinics, and private practices. Specialized training in medical terminology and patient care communication.",
              links: [
                {
                  linkText: "Medical",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Business: Professional interpreters for meetings, negotiations, and conferences. Expertise in corporate communication and industry-specific jargon.",
              links: [
                {
                  linkText: "Business",
                  link: "/expertise/business-interpretation",
                }
              ],
            },
            {
              text: "Education: Language support for students, parents, and educators. Enhancing communication in classrooms, parent-teacher meetings, and academic events.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                }
              ],
            },
            {
              text: "Technical Fields: Skilled interpreters for technical and engineering projects. Proficiency in complex terminology and processes.",
              links: [
                {
                  linkText: "Technical Fields",
                  link: "/expertise/technology-interpretation",
                }
              ],
            },
          ]}
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks />
        
        <ContentSection
          title="A Nationwide Network of Professionals"
          content="With over 10,000 interpreters across the United States, Trusted Interpreters ensures you’ll have access to the best talent, wherever your project takes place."
          additionalContent="All our interpreters adhere to strict codes of ethics and confidentiality agreements, safeguarding your sensitive information."
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={expertiseBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Ready to Elevate Your Communication?"
          description="Whether it’s a high-stakes legal case, a critical medical appointment, or a complex business deal, Trusted Interpreters is here to provide unmatched expertise. Contact us today to learn more about our industry-specific interpretation services."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Expertise;