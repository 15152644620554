import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import CallToAction from "../../../components/CallToAction";
import OthersImage from "../../../../src/assets/images/vectors/others.png";
import OnsiteImage1 from "../../../../src/assets/images/adobe/Philadelphia1.webp";
import OnsiteImage2 from "../../../../src/assets/images/adobe/meeting.webp";
import { FaPuzzlePiece, FaUserTie } from "react-icons/fa6";
import { FaCogs } from "react-icons/fa";
import Helmet from "react-helmet";

const Others: React.FC = () => {    
    const otherBenefits = [
        {
          icon: FaUserTie,
          title: "Certified Interpreters",
          text: "Professionals skilled in industry-specific terminology and cultural nuances.",
        },
        {
          icon: FaCogs,
          title: "Flexible Support",
          text: "Seamless interpretation on platforms like GoToMeeting, BlueJeans, Webex, and more.",
        },
        {
          icon: FaPuzzlePiece,
          title: "Customized Solutions",
          text: "Tailored interpretation services for your specific needs, whether it’s a small meeting or a virtual event.",
        },
      ];
      
      return (
        <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
          <Helmet>
            <title>Professional VRI for Any Video Platform in US</title>
            <meta
              name="description"
              content="Trusted Interpreters offers certified VRI services on GoToMeeting, BlueJeans, Webex, and more."
            />
          </Helmet>

          <PageHeader
            title="Professional VRI for Any Video Platform"
            description="Trusted Interpreters offers certified Video Remote Interpretation (VRI) services tailored to your preferred platform. Whether you use GoToMeeting, BlueJeans, Cisco Webex, or other video conferencing tools, our interpreters ensure precise communication and seamless integration for your multilingual meetings and events."
            imageUrl={OthersImage}
          />

          <div className="container mx-auto px-4 py-16">
            <ContentSection
              title="How Our VRI Services Work"
              contentList={[
                {
                  text: "Customized Integration: We tailor our interpretation services to fit your preferred video conferencing platform, ensuring flawless communication without technical disruptions.",
                },
                {
                  text: "On-Demand Expertise: Access interpreters fluent in over 200 languages, ready to assist in real-time for virtual meetings, webinars, and other online events.",
                },
                {
                  text: "Global Reach: Our interpreters are available anywhere, supporting multilingual audiences across the globe.",
                },
              ]}
              imageUrl={OnsiteImage1}
              direction="right"
            />
    
            {/* Workflow */}
           <ProcessWorkflow process="virtual" />
    
    
            <ContentSection
              title="Applications for VRI on Any Platform"
              content="Our VRI services are perfect for a variety of scenarios, including:"
              contentList={[
                {
                  text: "Business Meetings: Facilitate effective communication with international partners and teams.",
                  links: [
                    {
                      linkText: "Business Meetings",
                      link: "/expertise/business-interpretation",
                    },
                  ],
                },
                {
                  text: "Webinars and Virtual Conferences: Ensure inclusivity for multilingual attendees.",
                  links: [
                    {
                      linkText: "Webinars and Virtual Conferences",
                      link: "/interpretation-types/vri",
                    },
                  ],
                },
                {
                  text: "Legal Proceedings: Support remote depositions, hearings, and consultations.",
                  links: [
                    {
                      linkText: "Legal Proceedings",
                      link: "/expertise/legal-interpretation",
                    },
                  ],
                },
                {
                  text: "Healthcare Telemedicine: Deliver accurate interpretation for telehealth appointments.",
                  links: [
                    {
                      linkText: "Healthcare Telemedicine",
                      link: "/expertise/medical-interpretation",
                    },
                  ],
                },
                {
                  text: "Educational Programs: Assist students, educators, and parents in virtual learning settings.",
                  links: [
                    {
                      linkText: "Educational Programs",
                      link: "/expertise/educational-interpretation",
                    },
                  ],
                },
              ]}
              imageUrl={OnsiteImage2}
              direction="left"
            />
    
            {/* <CitiesGrid serviceName="On-Site" /> */}
            <div className="container mx-auto px-4 pb-16 md:pt-4">
              <InteractiveMap />
            </div>
    
            {/* Key Benefits */}
            <HighlightedSection title="Trusted Interpreters: Your Partner for Any Video Platform">
              <KeyBenefits benefits={otherBenefits} />
            </HighlightedSection>
    
            <CallToAction
              title="Contact Trusted Interpreters Today"
              description="Trusted Interpreters provides professional VRI services on GoToMeeting, BlueJeans, Webex, and more. Contact us today to schedule certified interpreters and ensure smooth communication for your next virtual meeting or event."
              buttonText="Get a Quote"
              buttonLink="/free-quote"
            />
    
            {/* faq */}
            <FAQ />
    
            {/* testimonials */}
            <TestimonialsSection />
          </div>
          <Footer />
        </div>
      );
};
  
export default Others;