import React, { useState, useEffect, useRef } from "react";
import Cookies from 'js-cookie';

const QuoteForm: React.FC = () => {
  const baseURL =
    "https://orders.trustedinterpreters.com/iform-quote/KYLPv11681413693?service_type=interpretation";
  const websiteSale = "www.trustedinterpreters.com";

  const defaultServiceURL = `${baseURL}&website_sale_c=${websiteSale}`;
  const remoteServiceURL = `${baseURL}&text=remote&website_sale_c=${websiteSale}`;
  const onSiteURL = `${baseURL}&text=onsite&type_i=onsite_consecutive&website_sale_c=${websiteSale}`;
  const OPIURL = `${baseURL}&text=overphone&website_sale_c=${websiteSale}`;
  
  const [iframeURL, setIframeURL] = useState(defaultServiceURL);
  const hasValidated = useRef(false);

  useEffect(() => {
    if (hasValidated.current) return;
    /* TEST */
/*                 const params = new URLSearchParams(window.location.search);
                const utmSource = params.get("utm_source") || "other_online";
                const referrer = document.referrer;
                const gclid = params.get("gclid");
                const userAgent = navigator.userAgent;
                let leadSource = '';
                const msnId = params.get("msnid");
                
                if (referrer.includes("google")){
                  leadSource = 'google_organic';
                } else if (referrer.includes("yahoo")){
                  leadSource = 'yahoo_organic';
                } else if (referrer.includes("bing")){
                  leadSource = 'bing_organic';
                } else{
                  leadSource = 'other_online';
                }
                
                if (utmSource == 'google_adwords'){
                  leadSource = utmSource;
                }
                if (utmSource == 'meta'){
                  leadSource = 'meta_ads';
                }
                if (utmSource == 'gmb'){
                  leadSource = 'gmb';
                }
                if (utmSource == 'bingplaces'){
                  leadSource = 'bingplaces';
                }
                if (msnId !== null && msnId !== ''){
                  leadSource = 'msn_ads';
                }
                if (leadSource != '') {
                  Cookies.set('lead_source', leadSource, { expires: 30 });
                  if (gclid !== null && gclid !== ''){
                    Cookies.set('gclid', gclid, { expires: 30 });
                  }
                  if (msnId !== null && msnId !== ''){
                    Cookies.set('msnid', msnId, { expires: 30 });
                  }
                }
                console.log("User-Agent:", userAgent);
                if (userAgent) {
                  Cookies.set('seo_user_agent_c', userAgent, { expires: 30, sameSite: 'Lax' });
                } */
    /* FIN TEST */
    const urlParams = new URLSearchParams(window.location.search);
    let leadSource = '';
    const gclid = urlParams.get("gclid");
    const userAgent = navigator.userAgent;
    const msnId = urlParams.get("msnid");
    const utmSource = urlParams.get("utm_source") || "other_online"; // si no hay utm_source "other_online"
    const utmCampaign = urlParams.get("utm_campaign");
    const seoKeyword = urlParams.get("keyword") || ""; // keyword
    const landingPageC = window.location.href; // URL actual
    const isLanding = window.location.pathname.includes("/landing/") ? "3" : "2"; // es landing?

    const storedURL = localStorage.getItem("selectedServiceURL");
    const previousPage = localStorage.getItem("previousPage");
    
    let selectedURL = defaultServiceURL;

    if (storedURL) {
      selectedURL = storedURL;
    } else if (previousPage === "home") {
      selectedURL = defaultServiceURL;
    } else {
      const referrer = document.referrer;

    if (referrer.includes("google")){
      leadSource = 'google_organic';
    } else if (referrer.includes("yahoo")){
      leadSource = 'yahoo_organic';
    } else if (referrer.includes("bing")){
      leadSource = 'bing_organic';
    } else{
      leadSource = 'other_online';
    }

    if (utmSource == 'google_adwords'){
      leadSource = utmSource;
    }
    if (utmSource == 'meta'){
      leadSource = 'meta_ads';
    }
    if (utmSource == 'gmb'){
      leadSource = 'gmb';
    }
    if (utmSource == 'bingplaces'){
      leadSource = 'bingplaces';
    }
    if (msnId !== null && msnId !== ''){
      leadSource = 'msn_ads';
    }
    if (leadSource != '') {
      Cookies.set('lead_source', leadSource, { expires: 30 });
      if (gclid !== null && gclid !== ''){
        Cookies.set('gclid', gclid, { expires: 30 });
      }
      if (msnId !== null && msnId !== ''){
        Cookies.set('msnid', msnId, { expires: 30 });
      }
    }
    console.log("User-Agent:", userAgent);
    if (userAgent) {
      Cookies.set('seo_user_agent_c', userAgent, { expires: 30, sameSite: 'Lax' });
    }

      if (referrer.includes("/interpretation-types/vri") || window.location.pathname.includes("/landing/vri")) {
        selectedURL = remoteServiceURL;
      } else if (referrer.includes("/interpretation-types/on-site") || window.location.pathname.includes("/landing/on-site-interpretation")) {
        selectedURL = onSiteURL;
      } else if (referrer.includes("/interpretation-types/phone-interpretation")) {
        selectedURL = OPIURL;
      }
    }

    const rawParams = {
      lang_code: "en",
      lead_source: leadSource,
      landing_page_c: landingPageC,
      landing: isLanding,
      seo_keyword_c: seoKeyword,
      utm_campaign: utmCampaign || undefined,
    }

    const filteredParams = Object.fromEntries(
      Object.entries(rawParams).filter(([_, value]) => value !== undefined)
    ) as Record<string, string>
    
    const queryParams = new URLSearchParams(filteredParams)
    
    
    const finalURL = `${selectedURL}&${queryParams.toString()}`;
    
    setIframeURL(finalURL);
    hasValidated.current = true;

    if (storedURL) localStorage.removeItem("selectedServiceURL");
    localStorage.removeItem("previousPage");
  }, []);

  return (
    <div className="container mx-auto px-4 py-12">
      <div className="relative w-full h-[600px]">
        <iframe
          src={iframeURL}
          title="Quote Form"
          className="w-full h-full border-none"
        ></iframe>
      </div>
    </div>
  );
};

export default QuoteForm;
