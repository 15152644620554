import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import SubsectionNavigator from "../../../components/SubsectionNavigator";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import CallToAction from "../../../components/CallToAction";
import { FaGoogle, FaMicrosoft, FaSkype } from "react-icons/fa";
import OnsiteImage from "../../../../src/assets/images/vectors/vri.png";
import OnsiteImage1 from "../../../../src/assets/images/adobe/vri-img.webp";
import OnsiteImage2 from "../../../../src/assets/images/adobe/adobe5.webp";
import OnsiteImage3 from "../../../../src/assets/images/adobe/adobe12.webp";
import { FaRegClock, FaUserGraduate, FaVideo } from "react-icons/fa6";
import Helmet from "react-helmet";

const VRI: React.FC = () => {
  const VRIBenefits = [
    {
      icon: FaUserGraduate,
      title: "Highly Qualified Interpreters",
      text: "Our interpreters are experts in over 200 languages and specialized fields, including sign language.",
    },
    {
      icon: FaVideo,
      title: "User-Friendly Technology",
      text: "We integrate with popular platforms like Zoom, Microsoft Teams, Google Meet, and Skype for seamless VRI sessions.",
    },
    {
      icon: FaRegClock,
      title: "24/7 Availability",
      text: "Access our VRI services anytime, ensuring uninterrupted communication.",
    },
  ];

  const subsections = [
    {
      title: "Zoom",
      description: "Seamless interpretation services integrated directly with your Zoom meetings.",
      icon: <FaVideo size={24} />,
      link: "/interpretation-types/vri/zoom-interpretation-services",
    },
    {
      title: "Google Meet",
      description: "Reliable interpretation support for your Google Meet video calls.",
      icon: <FaGoogle size={24} />,
      link: "/interpretation-types/vri/google-meet-interpretation-services",
    },
    {
      title: "Microsoft Teams",
      description: "Professional interpretation for your Microsoft Teams conferences.",
      icon: <FaMicrosoft size={24} />,
      link: "/interpretation-types/vri/microsoft-teams-interpretation-services",
    },
    {
      title: "Skype",
      description: "Instant interpretation for your Skype calls and meetings.",
      icon: <FaSkype size={24} />,
      link: "/interpretation-types/vri/skype-interpretation-services",
    },
  ];
  

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Video Remote Interpretation US</title>
        <meta
          name="description"
          content="Ensure clear communication with Trusted Interpreters' expert VRI services nationwide."
        />
      </Helmet>
      <PageHeader
        title="Virtual Remote Interpretation (VRI) Services in the U.S."
        description="Trusted Interpreters offers cutting-edge Virtual Remote Interpretation (VRI) services, bridging communication gaps in real time through advanced video technology. Our VRI services provide instant access to professional interpreters, ensuring accurate and effective communication from anywhere in the United States."
        imageUrl={OnsiteImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="What is Virtual Remote Interpretation (VRI)?"
          content="VRI is a remote interpretation service that uses video conferencing platforms to connect interpreters with clients. This method combines the convenience of remote access with the benefits of visual communication, making it ideal for situations where face-to-face interaction is not feasible."
          imageUrl={OnsiteImage1}
          direction="right"
        />

        {/* Subsections */}
        <div className="container mx-auto px-6 py-6">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
            Explore Video Remote Interpretation (VRI) Services
          </h2>
          <SubsectionNavigator subsections={subsections} />
        </div>

        {/* Workflow */}
<ProcessWorkflow process="virtual" />


        <ContentSection
          title="Industries We Serve with VRI"
          content="Our VRI services cater to a wide range of industries, including:"
          contentList={[
            {
              text: "Healthcare: Facilitate clear communication between medical staff and patients.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal: Provide accurate interpretation for virtual hearings and consultations.",
              links: [
                {
                  linkText: "Legal",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
            {
              text: "Education: Support multilingual communication in online classrooms and conferences.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
            {
              text: "Corporate: Enable seamless collaboration in remote meetings and events.",
              links: [
                {
                  linkText: "Corporate",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <ContentSection
          title="Key Advantages of VRI:"
          contentList={[
            {
              text: "Instant Accessibility: Connect with certified interpreters on demand.",
            },
            {
              text: "Cost-Effective: Eliminates travel costs associated with on-site interpretation.",
            },
            {
              text: "Visual Communication: Enhances understanding through visual cues, especially in sign language interpretation.",
            },
          ]}
          additionalContent="With Trusted Interpreters, you can connect to professional interpreters across the U.S. from the comfort of your home, office, or remote location. Our VRI solutions are designed to meet the unique needs of businesses, organizations, and individuals."
          imageUrl={OnsiteImage3}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Our Video Remote Interpretation (VRI) Services?">
          <KeyBenefits benefits={VRIBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Contact Trusted Interpreters Today"
          description="Ready to break language barriers with VRI? Contact us today to learn more about our virtual remote interpretation services and schedule a session with one of our expert interpreters."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default VRI;