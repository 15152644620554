import React from "react";
import Footer from "../components/Footer";
import Helmet from "react-helmet";

const TC: React.FC = () => {
  const terms = [
    {
      title: "Service Agreement",
      description: `Trusted Interpreters (“TI”) agrees to provide interpretation services to the Client based on the details specified in the project agreement. Services include on-site, telephonic, and video remote interpretation. TI will strive to accommodate specific Client needs while maintaining the highest professional standards and adhering to industry guidelines.`,
    },
    {
      title: "Confidentiality",
      description: `All information shared with TI is considered confidential. TI agrees to protect this information and use it solely for the intended purpose of service delivery. Any disclosure outside the scope of this agreement will require prior Client approval. Confidential information includes, but is not limited to, business data, communication records, and any materials provided for interpretation purposes. TI’s team is trained to handle sensitive information with the utmost care and professionalism.`,
    },
    {
      title: "Service Delivery",
      description: `TI will deliver all services with reasonable speed, diligence, and professionalism. Deadlines will be adhered to unless unforeseen circumstances necessitate adjustments, in which case the Client will be notified immediately. The Client is responsible for providing accurate and complete information to enable TI to fulfill its obligations effectively. TI reserves the right to reschedule services in the event of unforeseen circumstances such as natural disasters, technical failures, or other emergencies.`,
    },
    {
      title: "Payment Terms",
      description: `Payment terms will be outlined in the project agreement. Failure to remit payment by the agreed date may result in interest charges and/or suspension of services. TI accepts multiple payment methods, including electronic transfers and credit cards, to facilitate convenience for the Client. Any additional charges or fees will be communicated to the Client in advance.`,
    },
    {
      title: "Indemnity",
      description: `The Client agrees to indemnify and hold TI harmless from any claims, damages, or expenses arising from the misuse of the services provided. This includes situations where the Client’s failure to provide accurate information affects the quality or outcome of the interpretation services.`,
    },
    {
      title: "Dispute Resolution",
      description: `Any disputes arising from this agreement shall be resolved through arbitration in accordance with the rules of the American Arbitration Association. Arbitration shall take place in the jurisdiction agreed upon by both parties. Each party shall bear its own legal fees and costs related to the arbitration process, unless otherwise agreed upon during the proceedings. The decision of the arbitrator shall be final and binding.`,
    },
    {
      title: "Intellectual Property Rights",
      description: `All materials, recordings, and transcripts generated as part of the interpretation services are the exclusive property of the Client upon full payment. TI retains the right to use generic templates or methodologies for other projects, provided that no confidential or proprietary information from the Client is disclosed or reused.`,
    },
    {
      title: "Entire Agreement",
      description: `This document constitutes the entire agreement between TI and the Client. Any amendments must be made in writing and signed by both parties. Digital signatures or email confirmations are considered valid and binding.`,
    },
    {
      title: "Severability",
      description: `If any term of this agreement is found to be unenforceable, the remaining terms will continue to be in full force and effect. The unenforceable provision will be replaced by a valid provision that reflects the intent of the original clause as closely as possible.`,
    },
    {
      title: "Force Majeure",
      description: `TI shall not be held liable for delays or failure to perform its obligations due to events beyond its reasonable control, including but not limited to acts of God, natural disasters, labor disputes, or government actions. TI will make reasonable efforts to resume services promptly after such events.`,
    },
    {
      title: "Governing Law",
      description: `This agreement shall be governed by and construed in accordance with the laws of the State of Miami. Both parties agree to submit to the jurisdiction of courts located within this state for matters not resolved through arbitration.`,
    },
    {
      title: "Client Responsibilities",
      description: `The Client is responsible for providing all necessary materials, access, and information required to perform the interpretation services. The Client must notify TI of any specific requirements or preferences before the commencement of the service. Failure to do so may result in delays or additional charges.`,
    },
    {
      title: "Quality Assurance",
      description: `TI is committed to delivering high-quality interpretation services. If the Client identifies any issues with the service, TI must be notified within 5 business days to address and resolve the matter promptly. TI reserves the right to reassign interpreters or adjust its processes to ensure client satisfaction.`,
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Terms and Conditions | Trusted Interpreters</title>
        <meta
          name="description"
          content="Trusted Interpreters terms and conditions."
        />
      </Helmet>
      <div className="container mx-auto px-4 py-4">
        <div className="sm:flex justify-center max-w-screen-xl mx-auto">
          <div className="p-5">
            <div className="text animate-slide-in">
              <h2 className="my-4 font-bold text-3xl sm:text-4xl text-center">
                Terms and Conditions for{" "}
                <span className="text-[#024772]">Trusted Interpreters</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="mt-4 space-y-10 xl:space-y-8 text-center xl:text-start">
          {terms.map((term, index) => (
            <div key={index}>
              <h3 className="text-xl font-bold">{term.title}</h3>
              <p className="mt-2 text-gray-700">{term.description}</p>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TC;
