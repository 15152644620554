// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testimonials-section .testimonials-grid {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
}

@media (min-width: 640px) {
    .testimonials-section .testimonials-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1024px) {
    .testimonials-section .testimonials-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

.testimonial-card {
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 1rem;
    padding: 1.5rem;
    text-align: center;
    transition: all 0.3s ease;
}

.testimonial-card:hover {
    border-color: #4F46E5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.testimonial-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 0 auto 1rem;
}

.testimonial-rating {
    color: #FFB400;
    font-size: 1.2rem;
    margin: 0.5rem 0;
}

.testimonial-text {
    color: #6B7280;
    font-size: 0.975rem;
    line-height: 1.5;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Testimonials.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,qCAAqC;AACzC;;AAEA;IACI;QACI,qCAAqC;IACzC;AACJ;;AAEA;IACI;QACI,qCAAqC;IACzC;AACJ;;AAEA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;IACrB,wCAAwC;AAC5C;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".testimonials-section .testimonials-grid {\n    display: grid;\n    gap: 2rem;\n    grid-template-columns: repeat(1, 1fr);\n}\n\n@media (min-width: 640px) {\n    .testimonials-section .testimonials-grid {\n        grid-template-columns: repeat(2, 1fr);\n    }\n}\n\n@media (min-width: 1024px) {\n    .testimonials-section .testimonials-grid {\n        grid-template-columns: repeat(4, 1fr);\n    }\n}\n\n.testimonial-card {\n    background: white;\n    border: 1px solid #e5e7eb;\n    border-radius: 1rem;\n    padding: 1.5rem;\n    text-align: center;\n    transition: all 0.3s ease;\n}\n\n.testimonial-card:hover {\n    border-color: #4F46E5;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.testimonial-image {\n    width: 80px;\n    height: 80px;\n    border-radius: 50%;\n    margin: 0 auto 1rem;\n}\n\n.testimonial-rating {\n    color: #FFB400;\n    font-size: 1.2rem;\n    margin: 0.5rem 0;\n}\n\n.testimonial-text {\n    color: #6B7280;\n    font-size: 0.975rem;\n    line-height: 1.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
