import React from "react";
import KeyBenefits from "../../../components/KeyBenefits";
import ProcessWorkflow from "../../../components/ProcessWorkflow";
import TestimonialsSection from "../../../components/TestimonialsSection";
import FAQ from "../../../components/FAQ";
import InteractiveMap from "../../../components/InteractiveMap";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import HighlightedSection from "../../../components/HighlightedSection";
import CallToAction from "../../../components/CallToAction";
import { FaCogs } from "react-icons/fa";
import ZoomImage from "../../../../src/assets/images/vectors/zoom.png";
import OnsiteImage1 from "../../../../src/assets/images/adobe/interpreter1.webp";
import OnsiteImage2 from "../../../../src/assets/images/adobe/medical2.webp";
import OnsiteImage3 from "../../../../src/assets/images/adobe/adobe14.webp";
import { FaLaptop, FaUserCheck } from "react-icons/fa6";
import Helmet from "react-helmet";


const Zoom: React.FC = () => {
  const zoomBenefits = [
    {
      icon: FaUserCheck,
      title: "Certified Experts",
      text: "Our interpreters are skilled professionals fluent in over 200 languages, ensuring precise translations.",
    },
    {
      icon: FaLaptop,
      title: "Technical Expertise",
      text: "We provide support for setting up and managing Zoom’s interpretation features, ensuring a hassle-free experience.",
    },
    {
      icon: FaCogs,
      title: "Tailored Solutions",
      text: "Whether it’s a business meeting, educational seminar, or large conference, we customize our services to meet your specific needs.",
    },
  ];
  

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Professional Zoom Interpretation US</title>
        <meta
          name="description"
          content="Trusted Interpreters provides expert interpretation services for Zoom meetings."
        />
      </Helmet>
      <PageHeader
        title="Professional Zoom Interpretation for Remote Events and Meetings"
        description="Trusted Interpreters provides expert Zoom interpretation services, ensuring effective communication for virtual events, meetings, and conferences. Our interpreters deliver accurate and seamless real-time interpretation to bridge language gaps and foster inclusivity for diverse audiences."
        imageUrl={ZoomImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="What is Zoom Interpretation?"
          content="Zoom’s interpretation feature allows interpreters to translate spoken content into multiple languages during live virtual meetings or webinars. Participants can select their preferred language channel, ensuring a smooth and inclusive experience for everyone."
          imageUrl={OnsiteImage1}
          direction="right"
        />

        {/* Workflow */}
<ProcessWorkflow process="virtual" />


        <ContentSection
          title="Applications for Zoom Interpretation"
          content="Trusted Interpreters has supported numerous industries through Zoom, including:"
          contentList={[
            {
              text: "Corporate: Enhance global collaboration in team meetings and webinars.",
              links: [
                {
                  linkText: "Corporate",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Education: Promote accessibility in virtual classrooms and academic conferences.",
              links: [
                {
                  linkText: "Education",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
            {
              text: "Healthcare: Facilitate multilingual communication in telemedicine sessions.",
              links: [
                {
                  linkText: "Healthcare",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal: Support remote hearings and consultations with precise interpretations.",
              links: [
                {
                  linkText: "Legal",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
          ]}
          imageUrl={OnsiteImage2}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>
        <ContentSection
          title="Benefits of Zoom Interpretation:"
          contentList={[
            {
              text: "Real-Time Accuracy: Enables simultaneous communication in different languages.",
            },
            {
              text: "Global Reach: Connect with international audiences in a single meeting.",
            },
            {
              text: "Seamless Integration: Works directly within the Zoom platform for a user-friendly experience.",
            },
          ]}
          additionalContent="Our Zoom interpretation services are available anywhere in the U.S. and beyond. No matter the size or complexity of your event, Trusted Interpreters ensures your message is delivered accurately."
          imageUrl={OnsiteImage3}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters for Zoom?">
          <KeyBenefits benefits={zoomBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Book Your Zoom Interpreter Today"
          description="Maximize the success of your virtual event with professional Zoom interpretation services from Trusted Interpreters. Contact us today to discuss your needs and schedule an expert interpreter."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default Zoom;