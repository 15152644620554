import React from "react";
import KeyBenefits from "../../components/KeyBenefits";
import ProcessWorkflow from "../../components/ProcessWorkflow";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import InteractiveMap from "../../components/InteractiveMap";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ContentSection from "../../components/ContentSection";
import HighlightedSection from "../../components/HighlightedSection";
import LanguageLinks from "../../components/LanguageLinks";
import CallToAction from "../../components/CallToAction";
import OnsiteImage2 from "../../../src/assets/images/adobe/onsite-img-2.webp";
import { FaBriefcase, FaCertificate } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import Helmet from "react-helmet";
// images
import JapaneseImage from "../../../src/assets/images/vectors/japanese.png";
import PeopleImage from "../../../src/assets/images/adobe/japanese1.webp";
import PeopleImage1 from "../../../src/assets/images/adobe/japanese2.webp";
import PeopleImage2 from "../../../src/assets/images/adobe/japanese3.webp";


const JapaneseExpertise: React.FC = () => {
  const japaneseBenefits = [
    {
      icon: FaCertificate,
      title: "Certified Experts",
      text: "Our interpreters are highly trained in both the Japanese language and the cultural nuances required for accurate communication.",
    },
    {
      icon: FaBriefcase,
      title: "Industry-Specific Knowledge",
      text: "We pair you with interpreters experienced in your field for tailored services.",
    },
    {
      icon: FaMapMarkedAlt,
      title: "Nationwide Accessibility",
      text: "With interpreters located across the U.S., we ensure coverage wherever you need us.",
    },
  ];
  
  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Japanese Interpretation Services US</title>
        <meta
          name="description"
          content="Professional Japanese interpretation services tailored to your needs."
        />
      </Helmet>
      <PageHeader
        title="Japanese Interpretation Services for Any Industry Nationwide"
        description="Trusted Interpreters offers professional Japanese interpretation services to support effective communication with Japanese-speaking clients, partners, and communities. Whether you need interpretation for business meetings, legal proceedings, or cultural events, our certified interpreters provide precise and culturally sensitive translations."
        imageUrl={JapaneseImage}
      />
      <div className="container mx-auto px-4 py-16">
        <ContentSection
          title="Why Japanese Interpretation is Critical"
          content="Japanese is a complex language deeply tied to its cultural context. Misinterpretation can lead to misunderstandings in professional and personal settings. Our skilled interpreters bridge the language and cultural gap, ensuring your message is delivered accurately."
          imageUrl={PeopleImage}
          direction="right"
        />

        {/* Workflow */}
          <ProcessWorkflow process="presencial" />


        <ContentSection
          title="Real-World Applications of Japanese Interpretation"
          content="Our services are designed to fit diverse scenarios, including:"
          contentList={[
            {
              text: "Business Growth: Build strong relationships with Japanese partners during negotiations, presentations, or conferences.",
              links: [
                {
                  linkText: "Business Growth",
                  link: "/expertise/business-interpretation",
                },
              ],
            },
            {
              text: "Cultural Exchange: Facilitate smooth communication during community events, cultural programs, or international summits.",
              links: [
                {
                  linkText: "Cultural Exchange",
                  link: "/expertise/immigration-interpretation",
                },
              ],
            },
            {
              text: "Educational Collaboration: Enhance interactions in academic institutions for Japanese-speaking students and educators.",
              links: [
                {
                  linkText: "Educational Collaboration",
                  link: "/expertise/educational-interpretation",
                },
              ],
            },
            {
              text: "Healthcare Access: Support Japanese-speaking patients in medical appointments, ensuring clear communication.",
              links: [
                {
                  linkText: "Healthcare Access",
                  link: "/expertise/medical-interpretation",
                },
              ],
            },
            {
              text: "Legal Accuracy: Provide reliable interpretation for depositions, court hearings, and legal consultations.",
              links: [
                {
                  linkText: "Legal Accuracy",
                  link: "/expertise/legal-interpretation",
                },
              ],
            },
          ]}
          imageUrl={PeopleImage1}
          direction="left"
        />

        {/* <CitiesGrid serviceName="On-Site" /> */}
        <div className="container mx-auto px-4 pb-16 md:pt-4">
          <InteractiveMap />
        </div>

        <LanguageLinks currentLanguage="Japanese"/>

        <ContentSection
          title="Unique Aspects of Our Japanese Interpretation Services:"
          contentList={[
            {
              text: "Cultural Expertise: Interpreters understand cultural subtleties, essential for business and social settings.",
            },
            {
              text: "Specialized Skills: Trained in industry-specific terminology for fields like healthcare, technology, and legal matters.",
            },
            {
              text: "Flexible Formats: Available for on-site, over-the-phone, and video remote interpretation.",
            },
          ]}
          additionalContent="With interpreters located across the U.S., Trusted Interpreters ensures that Japanese interpretation services are available wherever you need them. Whether for a local meeting or a nationwide event, we provide reliable solutions to suit your needs."
          imageUrl={PeopleImage2}
          direction="right"
        />

        {/* Key Benefits */}
        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={japaneseBenefits} />
        </HighlightedSection>

        <CallToAction
          title="Partner with Trusted Interpreters Today"
          description="Whether you’re expanding your business, hosting a cultural event, or providing essential services, Trusted Interpreters is your partner in breaking language barriers. Contact us today to schedule a professional Japanese interpreter and elevate your communication."
          buttonText="Get a Quote"
          buttonLink="/free-quote"
        />

        {/* faq */}
        <FAQ />

        {/* testimonials */}
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
};

export default JapaneseExpertise;